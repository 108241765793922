import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import TrackingService from "../../app/data/tracking/trackingService";
import { initialShipmentDetailsState } from "./ShipmentDetailsState";

const trackingService = TrackingService.getInstance();

export const shipmentDetailsSlice = createSlice({
  name: "shipmentDetails",
  initialState: initialShipmentDetailsState,
  reducers: {
    resetShipmentDetails: (state) => initialShipmentDetailsState,
    startLoading: (state) => {
      state.loading = true;
      state.loaded = false;
      state.needToReload = false;
      state.loadingFailed = false;
    },
    setShipmentDetails: (state, { payload }) => {
      state.shipment = payload;
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
    },
    loadingShipmentDetails_has_failed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.error = payload;
    },
    reloadShipmentDetails: (state) => {
      if (state.loaded) {
        state.needToReload = true;
      }
    },
    startDockLogLoading: (state) => {
      state.dockLogLoading = true;
      state.dockLogLoaded = false;
      state.dockLogLoadingFailed = false;
    },
    setDockLog: (state, { payload }) => {
      state.dockLogLoading = false;
      state.dockLogLoaded = true;
      state.dockLogLoadingFailed = false;
      state.dockLog = payload;
    },
    dockLogLoading_has_failed: (state, { payload }) => {
      state.dockLogLoading = false;
      state.dockLogLoaded = false;
      state.dockLogLoadingFailed = true;
      state.dockLogLoadingError = payload;
    },
    clearDockLog: (state) => {
      state.dockLog = null;
      state.dockLogLoaded = false;
    },
    startStatusHistoryLoading: (state) => {
      state.statusHistoryLoading = true;
      state.statusHistoryLoaded = false;
      state.statusHistoryLoadingFailed = false;
    },
    setStatusHistory: (state, { payload }) => {
      state.statusHistoryLoading = false;
      state.statusHistoryLoaded = true;
      state.statusHistoryLoadingFailed = false;
      state.statusHistory = payload;
    },
    statusHistoryLoading_has_failed: (state, { payload }) => {
      state.statusHistoryLoading = false;
      state.statusHistoryLoaded = false;
      state.statusHistoryLoadingFailed = true;
      state.statusHistoryLoadingError = payload;
    },
    clearStatusHistory: (state) => {
      state.statusHistory = null;
      state.statusHistoryLoaded = false;
    },
    setShipmentSubscribeStatus: (state, { payload }) => {
      if (state.shipment) {
        state.shipment.userSubscribed = payload;
      }
    },
    startAddingNote: (state) => {
      state.addingNote = true;
      state.addingNoteFailed = false;
      state.addingNoteFinished = false;
    },
    addingNoteFailed: (state, { payload }) => {
      state.addingNote = false;
      state.addingNoteFailed = true;
      state.addingNoteFinished = false;
      state.addingNoteError = payload;
    },
    addingNoteFinished: (state) => {
      state.addingNote = false;
      state.addingNoteFailed = false;
    }
  }
});

export const {
  resetShipmentDetails,
  startLoading,
  setShipmentDetails,
  loadingShipmentDetails_has_failed,
  reloadShipmentDetails,
  startDockLogLoading,
  setDockLog,
  dockLogLoading_has_failed,
  clearDockLog,
  startStatusHistoryLoading,
  setStatusHistory,
  statusHistoryLoading_has_failed,
  clearStatusHistory,
  setShipmentSubscribeStatus,
  startAddingNote,
  addingNoteFailed,
  addingNoteFinished
} = shipmentDetailsSlice.actions;

export const shipmentDetailsSelector = (state: IState) => state.shipmentDetails;

export const getShipmentDetails =
  (invoiceNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    const response = await trackingService.getShipmentDetails(invoiceNumber);
    if (response.ok()) {
      dispatch(setShipmentDetails(response.data));
    } else {
      dispatch(loadingShipmentDetails_has_failed(response.getError()));
    }
  };

export const getDockLog =
  (invoiceNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startDockLogLoading());
    const response = await trackingService.getDockLog(invoiceNumber);
    if (response.ok()) {
      dispatch(setDockLog(response.data));
    } else {
      dispatch(dockLogLoading_has_failed(response.getError()));
    }
  };

export const getStatusHistory =
  (invoiceNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startStatusHistoryLoading());
    const response = await trackingService.getStatusHistory(invoiceNumber);
    if (response.ok()) {
      dispatch(setStatusHistory(response.data));
    } else {
      dispatch(statusHistoryLoading_has_failed(response.getError()));
    }
  };

export const addShipmentNote =
  (invoiceNumber: number, note: string, onSuccess: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(startAddingNote());
    const response = await trackingService.addNote(invoiceNumber, note);
    if (response.ok()) {
      dispatch(addingNoteFinished());
      onSuccess();
    } else {
      dispatch(addingNoteFailed(response.getError()));
    }
  };
const shipmentDetailsReducer = shipmentDetailsSlice.reducer;
export default shipmentDetailsReducer;
