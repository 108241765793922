import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import AmountCell from "./amount-cell/amountCell";
import DateCell from "./date-cell/dateCell";
import LinkCell from "./link-cell/linkCell";
import CombinedCell from "./combined-cell/combinedCell";
import CheckboxCell from "./checkbox-cell/checkbox-cell";

export enum CustomCellTypes {
  date, amount, link, combined, checkbox
}
export interface CustomCellProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  data: any;
  selector?: string;
  selectors?: string[];
  cellType: CustomCellTypes;
  value?: any;
  values?: any[];
  options?: any;
  checked?: boolean;
}

const CustomCell: React.FC<CustomCellProps> = ({
  data,
  selector,
  selectors,
  cellType,
  value,
  values,
  options,
  ...divProps
}) => {
  const getValue = () => value || (selector ? data[selector] : undefined);
  const getValues = () => values || (selectors ? selectors.map((selector) => data[selector]) : undefined);

  return (
    <div {...divProps}>
      {!data.isTotalRow && (
        <>
          {false && (
            <>
              {console.log("CustomCell", cellType)}
              {console.log("value", value)}
              {console.log("values", values)}
              {console.log("data", data)}
              {console.log("selector", selector)}
              {console.log("selectors", selectors)}
            </>
          )}

          {cellType === CustomCellTypes.amount && (
            <AmountCell amount={getValue()} />
          )}
          {cellType === CustomCellTypes.date && <DateCell date={getValue()} />}
          {cellType === CustomCellTypes.link && (
            <LinkCell text={getValue()} {...options} />
          )}
          {cellType === CustomCellTypes.combined && (
            <CombinedCell values={getValues()} {...options} />
          )}
          {cellType === CustomCellTypes.checkbox && (
            <CheckboxCell values={getValues()} {...options} />
          )}
        </>
      )}

      {data.isTotalRow && <>{getValue()}</>}
    </div>
  );
};

export default CustomCell;
