import api, { ApiResponse } from "./../api";

class CommonService {
  private static instance: CommonService;
  private constructor() {}
  static getInstance(): CommonService {
    if (!CommonService.instance) {
      CommonService.instance = new CommonService();
    }
    return CommonService.instance;
  }

  public searchLocations = async (query: string): Promise<ApiResponse<any>> => {
    return await api.get(`/rate-quote/zip-codes?search=${encodeURIComponent(query)}`);
  };

  public getServiceCenterDays = async (zip: string): Promise<ApiResponse<any>> => {
    return await api.get(`/transit/routing-guide?zip=${zip}`);
  };

  public getProbillDetails = async (search: string, searchKey: string): Promise<ApiResponse<any>> => {
    return await api.get(`/probills/search?search=${encodeURIComponent(search)}&searchKey=${searchKey}`);
  };

  public getLoadManifestDetails = async (search: string): Promise<ApiResponse<number[]>> => {
    return await api.get(`/manifest-suggestions/${search}`);
  };

  public getMasterbillSuggestions = async (search: string): Promise<ApiResponse<number[]>> => {
    return await api.get(`/masterbill-suggestions/${search}`);
  };
}

export default CommonService;
