import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { UpdateLoadManifestModes } from "../../../features/inbound-freight/route";

export interface UpdateLoadManifestRequest {
  carrierName?: string;
  trailer?: number;
  manifestNumber?: number;
  arrivalDate?: string;
  arrivalTime?: string;
  seal?: string;
  checkedBy?: string;
  intact?: string;
  departureDate?: string;
  departureTime?: string;
  mode?: UpdateLoadManifestModes;
}

export const getIntactOptions = () => [
  { label: "Seal Was Intact and Undamaged.", value: "Y" },
  { label: "Driver Broke Seal", value: "B" },
  { label: "Seal Not On Trailer", value: "M" },
  { label: "Previous Stop Did Not Reseal", value: "A" },
  { label: "Seal Tampered With", value: "C" }
];

export const UpdateLoadManifestInboundSchema: Yup.ObjectSchema<UpdateLoadManifestRequest> = Yup.object({
  carrierName: Yup.string(),
  trailer: Yup.number(),
  manifestNumber: Yup.number(),
  arrivalDate: Yup.string().required(validationMessages.required),
  arrivalTime: Yup.string().required(validationMessages.required),
  seal: Yup.string().required(validationMessages.required),
  checkedBy: Yup.string().required(validationMessages.required),
  intact: Yup.string().required(validationMessages.required),
  departureDate: Yup.string(),
  departureTime: Yup.string(),
  mode: Yup.mixed<UpdateLoadManifestModes>().oneOf(Object.values(UpdateLoadManifestModes))
});

export const UpdateLoadManifestArrivedSchema: Yup.ObjectSchema<UpdateLoadManifestRequest> = Yup.object({
  carrierName: Yup.string(),
  trailer: Yup.number(),
  manifestNumber: Yup.number(),
  arrivalDate: Yup.string(),
  arrivalTime: Yup.string(),
  seal: Yup.string(),
  checkedBy: Yup.string(),
  intact: Yup.string(),
  departureDate: Yup.string().required(validationMessages.required),
  departureTime: Yup.string().required(validationMessages.required),
  mode: Yup.mixed<UpdateLoadManifestModes>().oneOf(Object.values(UpdateLoadManifestModes))
});
