import api, { ApiResponse } from "./../api";
import { BolRequestModel } from "./models";

class BolService {
  private static instance: BolService;
  private constructor() {}
  static getInstance(): BolService {
    if (!BolService.instance) {
      BolService.instance = new BolService();
    }
    return BolService.instance;
  };

  public getBols = async (
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    let params;
    if (lastIds) {
      params = {
        lastIds: lastIds
      }
    }
    return await api.get("/bols", {
      params: params
    });
  };

  public getShippers = async (): Promise<ApiResponse<any>> => {
    return await api.get("/teams/shippers");
  };

  public getConsignees = async (): Promise<ApiResponse<any>> => {
    return await api.get("/bols/consignees");
  };

  public createBol = async (
    request: BolRequestModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/bols", request);
  };

  public downloadDocument = async (
    probill: string,
    type: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/bols/${probill}/documents?type=${type}`, {
      responseType: "blob"
    });
  };

  public checkPickup = async (
    zip: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/service-centers/pickup?zipCode=${zip}`);
  };
};

export default BolService;
