import React from "react";
import { EnterPODInformationModel, getPodTimeOptions, lateCodesOptions } from "../../../app/data/pod/models";
import LabeledDateInput from "../../../components/form/date/xgsFormDate";
import { Field } from "formik";
import moment from "moment";
import LabeledInput, { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import XGSFormSelect from "../../../components/form/select/xgsFormSelect";
import XGSMaskInput from "../../../components/form/maskinput/xgsMaskInput";

interface EnterPodRowformikProps {
  idx: number;
  podRow: EnterPODInformationModel;
  setFieldValue: any;
  onAnyFieldChange: any;
  directEntry: boolean;
  onBlur: any;
  onChange: any;
  validate: any;
}

export const EnterPodRow: React.FC<EnterPodRowformikProps> = (({setFieldValue, podRow, idx, directEntry, ...props}) => {
  let onAnyFieldChange = () => {
    props.onAnyFieldChange();
    props.validate();
  }
  return (
    <div className={`xgs-enter-pod__form__row`} title={directEntry ? "" : `${idx+1}. ${podRow.probillNumber}`}>
        <span className="xgs-enter-pod__form__row__serial">{idx+1}.</span>
        <div className={`xgs-enter-pod__form__row__item`}>
          <XGSMaskInput
            label="Probill:"
            name={`pods.${idx}.probillNumber`}
            disabled={!directEntry}
            required={directEntry}
            labelMode={LabelModes.column}
            format="########"
            allowNegative={false}
            onValueChange={(val) => {
              setFieldValue(`pods.${idx}.probillNumber`, val);
              onAnyFieldChange();
            }}
            onBlur={props.onBlur}
            value={podRow.probillNumber}
          />
        </div>
        <div className="xgs-enter-pod__form__row__small-item xgs-form__checkbox">
          <LabeledInput label="Duplicate:" labelMode={LabelModes.column}>
            <Field 
              disabled={idx === 0}
              onChange={(e: any) => {
                setFieldValue(`pods.${idx}.duplicate`, e.target.checked);
                onAnyFieldChange()
              }} 
              type="checkbox"
              name={`pods.${idx}.duplicate`} 
              onBlur={props.onBlur}
            />
          </LabeledInput>
        </div>
        <div className="xgs-enter-pod__form__row__date">
          <LabeledDateInput
            name={`pods.${idx}.deliveryDate`}
            disabled={podRow.duplicate}
            label="Del. Date:"
            onDateChange={(v) => {
              setFieldValue(`pods.${idx}.deliveryDate`, v);
              onAnyFieldChange();
            }}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={true}
            labelMode={LabelModes.column}
            disableWeekends={true}
            maxDate={moment().toDate()}
          />
        </div>

        <div className="xgs-enter-pod__form__row__del-time">
          <XGSFormSelect
            isSearchable={true}
            name={`pods.${idx}.deliveryTime`}
            label={"Del. time(EST):"}
            options={getPodTimeOptions()}
            menuPlacement="auto"
            onValueChange={(option) => {
              setFieldValue(`pods.${idx}.deliveryTime`, option?.value);
              onAnyFieldChange();
            }}
            required={true}
            labelMode={LabelModes.column}
            value={getPodTimeOptions().find(({ value }) => podRow.deliveryTime === value) || null}
            onBlur={props.onBlur}
            disabled={podRow.duplicate}
          />
        </div>
        <div className="xgs-enter-pod__form__row__small-item xgs-form__checkbox">
          <LabeledInput label="Will Call:" labelMode={LabelModes.column}>
            <Field 
              onChange={(e: any) => {
                setFieldValue(`pods.${idx}.willCall`, e.target.checked);
                onAnyFieldChange();
              }}
              disabled={podRow.duplicate} 
              onBlur={props.onBlur}
              type="checkbox" 
              name={`pods.${idx}.willCall`} 
            />
          </LabeledInput>
        </div>
        <div className="xgs-enter-pod__form__row__late-code">
          <XGSFormSelect
            isSearchable={false}
            name={`pods.${idx}.lateCode`}
            label={"Late Code:"}
            options={lateCodesOptions}
            menuPlacement="auto"
            onBlur={props.onBlur}
            labelMode={LabelModes.column}
            onValueChange={(option) => {
              setFieldValue(`pods.${idx}.lateCode`, option?.value);
              onAnyFieldChange();
            }}
            disabled={podRow.duplicate}
            value={lateCodesOptions.find(option => option.value === podRow.lateCode) || null}
          />
        </div>
        <div className="xgs-enter-pod__form__row__item">
          <XGSFormInput
            name={`pods.${idx}.signature`}
            label={"Signature:"}
            maxLength={10}
            onChange={(e) => {
              setFieldValue(`pods.${idx}.signature`, e.currentTarget.value);
              onAnyFieldChange();
            }}
            required={true}
            onBlur={props.onBlur}
            labelMode={LabelModes.column}
            disabled={podRow.duplicate}
          />
        </div>
        <div className="xgs-enter-pod__form__row__comment">
          <XGSFormInput
            name={`pods.${idx}.comments`}
            label={"Comments:"}
            labelMode={LabelModes.column}
            maxLength={30}
            onBlur={props.onBlur}
            onChange={(e) => {
              setFieldValue(`pods.${idx}.comments`, e.currentTarget.value);
              onAnyFieldChange();
            }}
            disabled={podRow.duplicate}
          />
        </div>

      {/* <div className="xgs-enter-pod__form__row">
        <div className={`xgs-enter-pod__form__row__item`}>
        </div>
        <div className="xgs-enter-pod__form__row__upload-podRow">
          <LabeledInput label="Document:" labelMode={LabelModes.column}>
            {!shipment?.pod?.documentAddedDate && (
              <PodInfoFileField
                documents={documents.get(selectedInvoiceNumbers[idx]) || []}
                onChange={(files) => onChangeDocument(selectedInvoiceNumbers[idx], files)}
              />
            )}
            {shipment?.pod?.documentAddedDate && (
              <XGSIcon
                style={{ marginTop: 20, marginLeft: 20 }}
                icon={XGSIcons.faCheckCircle}
                color="green"
                size="2xl"
              />
            )}
          </LabeledInput>

        </div>
      </div> */}
    </div>
  )
})