import {
  BolConsigneeModel,
  BolPayorModel,
  BolItemModel
} from "../../app/data/bol/models"; 
// import { SubAccountMinimal } from "../../app/data/account/SubAccount";

export default interface BolState {
  bols: any[];
  bolsRequest: any;
  bolsFetchedAll: boolean;
  fetchStarted: boolean;
  fetchPortionStarted: boolean;
  fetchSucceed: boolean;
  fetchFailed: boolean;
  fetchError: string;
  fromRequestRates: boolean;
  // shippers: SubAccountMinimal[] | null;
  shippers: any[] | null;
  shipper: number | null;
  bolNumber: string;
  poNumber: string;
  payType: string;
  specialInstructions: string | undefined;
  pickupRequest: boolean;
  pickupRequestDate: string | undefined;
  pickupRequestTime: string | undefined;
  willCall: boolean;
  consignee: BolConsigneeModel;
  consignees: BolConsigneeModel[] | null;
  payor?: BolPayorModel;
  items: BolItemModel[];
  quoteNumber?: number;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  pickupAvailable: boolean;
}

export const initialBolState: BolState = {
  bols: [],
  bolsRequest: null,
  bolsFetchedAll: false,
  fetchStarted: false,
  fetchPortionStarted: false,
  fetchSucceed: false,
  fetchFailed: false,
  fetchError: "",
  fromRequestRates: false,
  shippers: null,
  shipper: null,
  bolNumber: "",
  poNumber: "",
  payType: "",
  specialInstructions: "",
  pickupRequest: false,
  pickupRequestDate: undefined,
  pickupRequestTime: undefined,
  willCall: false,
  consignee: {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    phone: ""
  },
  consignees: null,
  payor: undefined,
  items: [],
  quoteNumber: undefined,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  pickupAvailable: false
};
