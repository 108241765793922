import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

import "./labledValue.scss";

export enum LabledValueDirection {
  vertical = "vertical",
  horizontal = "horizontal",
}

export enum LabledValueValueSize {
  normal = 14,
  medium = 18,
  large = 36,
}

export interface LabledValueProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  value?: any;
  values?: String[];
  direction?: LabledValueDirection;
  centered?: boolean;
  valueSize?: LabledValueValueSize;
  labelFixWidth?: boolean;
  optional?: boolean;
  extraClassName?: string;
  labelWidth?: string|number;
  valueWidth?: string|number
}

const LabledValue: React.FC<LabledValueProps> = ({
  label,
  value, values,direction,centered,valueSize,labelFixWidth,optional,
  ...props
}) => {
  const defaultDirection = LabledValueDirection.horizontal;
  const getDirection = () => {
    return direction ? direction : defaultDirection;
  };

  if (optional && value === undefined) {
    return null;
  }

  return (
    <div
      className={`xgs-labled-value ${getDirection()} ${props.extraClassName && props.extraClassName}`}
      style={{ alignItems: `${centered ? "center" : "flex-start"}` }}
      {...props}
    >
      <label
        style={{
          width: props.labelWidth? props.labelWidth :labelFixWidth ? 146 : "auto",
        }}
      >
        {label}
      </label>

      <span
        style={{
          fontSize: valueSize ? valueSize : LabledValueValueSize.normal,
          width: props.valueWidth? props.valueWidth : labelFixWidth ? "122px" : "auto",
          wordWrap: "break-word"
        }}
      >

        {!!values && values.reduce((prev, curr) => prev + (curr ? curr + " " : ""), "")}
        <>{value}</>
      </span>
    </div>
  );
};

export default LabledValue;
