import React from "react";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import XGSIcons from "../../components/icon/xgsIcons";
import { MenuItem } from ".";
import MenuItems from "./menuItems";
import "./menu.scss";

const Menu: React.FC<{}> = (props) => {
  const menuItems: MenuItem[] = [
    {
      name: "Home",
      route: xgsRoutes.home,
      icon: XGSIcons.faHome
    },
    {
      name: "Shipments",
      // route: xgsRoutes.shipments.tracking,
      icon: XGSIcons.faTruckMoving
    },
    {
      name: "Track Shipments",
      route: xgsRoutes.shipments.tracking
      // icon: XGSIcons.faTruckMoving,
    },
    {
      name: "Delivery Receipts",
      route: xgsRoutes.shipments.deliveryReceipts
    },
    {
      name: "POD",
      route: `${xgsRoutes.shipments.podInformation}`
      // icon: XGSIcons.faFileInvoice
    },
    {
      name: "Freight",
      icon: XGSIcons.faTruckFast
    },
    {
      name: "Live Freight",
      route: xgsRoutes.exactFreight.listing
    },
    {
      name: "Exceptions",
      route: xgsRoutes.freightExceptions.addFreightException
    },
    {
      name: "Appointments",
      route: xgsRoutes.freightAppointments.summary
    },
    
    {
      name: "Inbound Freight",
      route: xgsRoutes.inboundFreight.listing
    }
  ];

  return (
    <>
      <div className="xgs-site__menu-wrapper">
        <div className="xgs-menu">
          <div className="xgs-menu__list">
            <MenuItems items={menuItems} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
