import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import moment from "moment";
import { XGSSelectOption } from "../../../components/xgs-select/xgsSelect";

export const lateCodesOptions: XGSSelectOption[] = [
  { label: "BOL Held at Origin", value: "BOL" },
  { label: "Cust. Closed For Holiday", value: "CCH" },
  { label: "Cust. Refused", value: "CDC" },
  { label: "Cust. Closed", value: "CLO" },
  { label: "Cust. Postponed Appointment", value: "CPD" },
  { label: "Dest. S/C Missed Appointment", value: "DAM" },
  { label: "Dest. Missed Delivery", value: "DMD" },
  { label: "Local Weather Delay", value: "DWD" },
  { label: "Lost Freight", value: "LST" },
  { label: "Returned", value: "RTN" },
  { label: "State of Emergency", value: "SOE" }
];

export interface EnterPODInformationModel {
  probillNumber: string;
  deliveryDate?: string;
  deliveryTime?: string;
  lateCode: string;
  duplicate: boolean;
  willCall: boolean;
  signature: string;
  comments: string;
  eta?: string;
}

export const getPodTimeOptions = () => {
  let options: XGSSelectOption[] = [];
  let startTime = moment().startOf("day").add(6, "hours");

  let endTime = moment().startOf("day").add(21, "hours");
  let numberOfIntervals = endTime.diff(startTime, 'minutes') / 15;

  for (let i = 0; i < numberOfIntervals; i++) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: startTime.format("HH:mm")
    });
    startTime = startTime.add(15, "minutes");
  }

  return options;
};

const requiredMessage = "Required"
 export const EnterPODInformationSchema = Yup.object().shape({
  pods: Yup.array()
    .of(
      Yup.object().shape({
        probillNumber: Yup.string().required(requiredMessage),
        deliveryDate: Yup.string().required(requiredMessage),
        deliveryTime: Yup.string().required(requiredMessage),
        lateCode: Yup.string().test("is-lateCode-required", "Required, Date>ETA", function (value) {
          const { deliveryDate, eta } = this.parent;
          if (!eta) return true;
          if (moment(deliveryDate).isAfter(moment(eta))) {
            return value ? value.length > 0 : false;
          }
          return true;
        }),
        willCall: Yup.boolean(),
        signature: Yup.string().required(requiredMessage).max(30, validationMessages.max30chars),
        comments: Yup.string().max(30, validationMessages.max30chars)
      })
    )
    .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
    // .min(3, 'Minimum of 3 friends'),
});