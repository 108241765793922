import React, { memo } from "react";
import NumberFormat from "react-number-format";
import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../labeledInput";
import XGSIcon from "../../../icon/xgsIcon";
import XGSRegularIcons from "../../../icon/xgsRegularIcons";
import { XGSInputBaseProps } from "../../../xgs-input/xgsInput";

export interface LabeledMaskInputProps
  extends XGSInputBaseProps, CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement> {
  allowNegative: boolean;
  onValueChange: (value: string) => void;
  format: string;
  allowEmptyFormatting?: boolean;
  // clearable?: boolean;
}

export interface NumberFormatValues {
  floatValue: number | undefined;
  formattedValue: string;
  value: string;
}

const LabeledMaskInput: React.FC<LabeledMaskInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  meta,
  error,
  className,
  clearable,
  onClear,
  ...props
}) => {
  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={className || ''}
    >
      <div className="xgs-input">
        <NumberFormat
          allowNegative={props.allowNegative}
          allowEmptyFormatting={props.allowEmptyFormatting !== undefined ? props.allowEmptyFormatting : true}
          placeholder={props.placeholder || ""}
          isNumericString={true}
          thousandSeparator={false}
          format={props.format}
          className={`xgs-input__field ${isFailed() ? "not-valid" : ""}`}
          value={`${props.value}`}
          onValueChange={(valuesObj: NumberFormatValues) => props.onValueChange(valuesObj.value)}
          onKeyDown={(e: any) => {
            props.onKeyDown && props.onKeyDown(e);
          }}
          disabled={props.disabled}
        />
        {clearable && props.value && (
          <XGSIcon icon={XGSRegularIcons.faTimesCircle} className="xgs-input__append-button" onClick={onClear} />
        )}
      </div>
    </LabeledInput>
  );
});

export default LabeledMaskInput;
