import { useSelector } from "react-redux";
import { addShipmentNote, reloadShipmentDetails, shipmentDetailsSelector } from "../../../slices/shipment-details/shipmentDetailsSlice";
import React, { useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../components/button/button";
import XGSFormTextarea from "../../../components/form/textarea/xgsFormTextarea";
import { Formik, FormikProps, Form } from "formik";
import { useAppDispatch } from "../../../hooks/storeHooks";
import XGSErrorMessage from "../../../components/error-message/errorMessage";
import { toast } from "react-toastify";
import SlideOutSidebar from "../../../components/slide-out-sidebar/slideOutSidebar";
import XGSIcons from "../../../components/icon/xgsIcons";
import XGSIcon from "../../../components/icon/xgsIcon";

interface AddShipmentCommentProps {
  invoiceNumber: number;
}
export const AddShipmentComment: React.FC<AddShipmentCommentProps> = ({ invoiceNumber }) => {
  const shipmentDetailsState = useSelector(shipmentDetailsSelector);
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<any>>(null);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <XGSIcon onClick={() => setShowModal(!showModal)} className="xgs-shipment-details__add-note__open-button" icon={XGSIcons.faPlusCircle}/>
      <SlideOutSidebar onClose={() => setShowModal(false)} show={showModal} header="New comment">
        <div className="xgs-shipment-details__add-note">
          {shipmentDetailsState.addingNoteFailed && <XGSErrorMessage>Something went wrong</XGSErrorMessage>}
          <Formik
            innerRef={formRef}
            onSubmit={({ note }) => {
              dispatch(
                addShipmentNote(invoiceNumber, note, () => {
                  toast.info("Comment added successfully.");
                  formRef.current?.resetForm();
                  dispatch(reloadShipmentDetails());
                })
              );
            }}
            initialValues={{ note: "" }}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <div className="xgs-shipment-details__add-note__text">
                  <XGSFormTextarea
                    required
                    autoFocus
                    label="Comment:"
                    name="note"
                    placeholder="Start typing..."
                    onChange={(e: any) => props.setFieldValue("note", e.target.value)}
                    maxLength={420}
                    counter={420}
                  />
                </div>
                <Button
                  disabled={!formRef.current?.dirty || !formRef.current?.values.note.trim().length}
                  className="xgs-shipment-details__add-note__button"
                  type="submit"
                  theme={ButtonThemes.blue}
                  spinner={shipmentDetailsState.addingNote}
                >
                  Add Comment
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </SlideOutSidebar>
    </>
  );
};
