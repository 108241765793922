import { createSlice } from "@reduxjs/toolkit";
import { initialLoadManifestState } from "./loadManifestState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { LoadManifestService } from "../../app/data/load-manifest/loadManifestService";
import { UpdateLoadManifestRequest } from "../../app/data/load-manifest/model";

const loadManifestService = LoadManifestService.getInstance();

export const loadManifestSlice = createSlice({
  name: "loadManifest",
  initialState: initialLoadManifestState,
  reducers: {
    resetLoadManifestState: (state) => initialLoadManifestState,
    startLoading: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    finishedLoading: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.details = payload;
    },
    loadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingError = payload;
      state.loadingFailed = true;
    },
    startUpdatingLoadManifest: (state) => {
      state.updating = true;
      state.updated = false;
      state.updateError = null;
    },
    loadManifestUpdateFailed: (state, { payload }) => {
      state.updating = false;
      state.updateFailed = true;
      state.updateError = payload;
    },
    finishedUpdatingLoadManifest: (state) => {
      state.updating = false;
      state.updated = true;
    }
  }
});

export const {
  resetLoadManifestState,
  startUpdatingLoadManifest,
  loadManifestUpdateFailed,
  finishedUpdatingLoadManifest,
  startLoading,
  loadingFailed,
  finishedLoading
} = loadManifestSlice.actions;

export const loadManifestSelector = (state: IState) => state.loadManifest;

export const getLoadManifestDetails =
  (manifestNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    const response = await loadManifestService.getLoadManifestDetails(manifestNumber);
    if (response.ok()) {
      dispatch(finishedLoading(response.data));
    } else {
      dispatch(loadingFailed(response.getError()));
    }
  };

export const updateLoadManifestDetails = (
    data: UpdateLoadManifestRequest,
    onSuccess: () => void,
    onFailed: (message?: any) => void,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdatingLoadManifest());
    const response = await loadManifestService.updateLoadManifestDetails(data);
    if (response.ok()) {
      dispatch(finishedUpdatingLoadManifest());
      onSuccess();
    } else {
      response.getError && dispatch(loadManifestUpdateFailed(response.getError()))
      console.log("error", response.getError())
      onFailed(response.getError() || "");
    }
  };

const loadManifestReducer = loadManifestSlice.reducer;

export default loadManifestReducer;
