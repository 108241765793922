import { BaseState } from "..";
import { FreightStatusInformation } from "../../app/data/exact-freight/models";

export default interface FreightStatusState extends BaseState {
  details?: FreightStatusInformation | null;
  needsToReload?: boolean;
}

export const initialFreightStatusState: FreightStatusState = {
  details: null,
  loading: false,
  loaded: false,
  loadingFailed: false,
  loadingError: "",
  needsToReload: false
};
