import api, { ApiResponse } from "../api";
import { RollDetailsResponseItem } from "./model";

export class LinehaulManifestService {
  searchManifests = async (request?: any): Promise<ApiResponse<any>> => {
    return await api.post(`/linehaul-manifests`);
  };

  getRollDetails = async (manifestNumber: number): Promise<ApiResponse<RollDetailsResponseItem[]>> => {
    return await api.get(`/roll-details/${manifestNumber}`);
  };

  downloadCSV = async (manifestNumber: number): Promise<ApiResponse<any>> => {
    return await api.get(`/roll-details/${manifestNumber}/export`);
  }
}
