import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  lineHaulManifestSelector,
  resetLinehaulManifestState,
  searchLinehaulManifests
} from "../../slices/linehaul-manifest/linehaulManifestSlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import ContentContainer from "../../templates/content-container/contentContainer";
import Table from "../../components/table/table";
import getInboundFreightColumns from "./inboundFreightColumns";
import "./inboundFreight.scss";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import SlideOutSidebar from "../../components/slide-out-sidebar/slideOutSidebar";
import { InboundFreightToolbar } from "./inboundFreightToolbar";
import TrackingFilter, { FilterConfig, FilterTypes } from "../../components/filter/filter";

interface FilterParams {
  manifestNumber?: string;
  status?: string;
  trailerNumber?: string;
}
const initialFilterState: FilterParams = {
  manifestNumber: "",
  status: ""
};
const filterConfig: FilterConfig[] = [
  {
    key: "manifestNumber",
    label: "Manifest Number",
    type: FilterTypes.number,
    triggerSearch: true,
    placeholderText: "Manifest Number"
  },
  {
    key: "trailerNumber",
    label: "Trailer Number",
    type: FilterTypes.number,
    placeholderText: "Trailer Number",
    triggerSearch: true
  },
  {
    key: "status",
    label: "Status",
    type: FilterTypes.multi_select,
    triggerSearch: true,
    placeholderText: "Freight Status",
    options: [
      {
        label: "ARRIVED",
        value: "ARRIVED"
      },
      {
        label: "INBOUND",
        value: "INBOUND"
      }
    ]
  }
];

export const InboundFreight = () => {
  const linehaulManifestState = useSelector(lineHaulManifestSelector);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState(initialFilterState);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);

  useEffect(() => {
    dispatch(searchLinehaulManifests({}));
    return () => {
      dispatch(resetLinehaulManifestState());
    };
  }, [dispatch]);

  const filteredFreights = useMemo(() => {
    return linehaulManifestState.linehaulManifests.filter((row) => {
      const matchesManifestNumber = filter.manifestNumber
        ? row.manifestNumber.toString().startsWith(filter.manifestNumber)
        : true;
      const matchesStatus = filter.status ? filter.status.includes(row.status) : true;
      const matchesTrailer = filter.trailerNumber
        ? row.trailerNumber.toString().startsWith(filter.trailerNumber)
        : true;
      return matchesManifestNumber && matchesStatus && matchesTrailer;
    });
  }, [filter, linehaulManifestState.linehaulManifests]);

  const onSearch = useCallback((filters: any) => {
    setSelectedRow(null);
    setFilter({
      manifestNumber: filters.manifestNumber,
      status: filters.status,
      trailerNumber: filters.trailerNumber
    });
  }, []);

  const onClear = useCallback(() => {
    setFilter(initialFilterState);
  }, []);

  return (
    <ContentContainer
      header={<TrackingFilter onClear={onClear} onSearch={onSearch} filtersConfig={filterConfig} />}
      isLoading={linehaulManifestState.loading}
      title="Inbound Trailers"
    >
      {linehaulManifestState.loaded && (
        <SlideOutSidebar
          className="inbound-freight__slidein"
          onClickOutside={() => setSelectedRow(null)}
          onClose={() => setSelectedRow(null)}
          header=""
          show={selectedRow !== null}
        >
          {selectedRow != null && (
            <InboundFreightToolbar
              manifestNumber={filteredFreights[selectedRow]?.manifestNumber}
              status={filteredFreights[selectedRow].status}
            />
          )}
        </SlideOutSidebar>
      )}
      <div className="inbound-freight-list">
        {!linehaulManifestState.loadingFailed && (
          <Table
            isLoading={linehaulManifestState.loading}
            columns={getInboundFreightColumns()}
            highlightRow={selectedRow}
            onRowClicked={(row: any, i: number) => setSelectedRow(i)}
            data={filteredFreights}
            infiniteScroll={true}
            rowHeight={82}
            cursorPointer={true}
            minTableHeight={420}
          />
        )}
        {linehaulManifestState.loadingFailed && (
          <XGSErrorMessage>{linehaulManifestState.loadingError}</XGSErrorMessage>
        )}
      </div>
    </ContentContainer>
  );
};
