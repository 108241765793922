import React from "react";
import { useField, FieldInputProps } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CommonLabeledInputProps } from "../../molecules/labeled-inputs/labeledInput";
import LabeledMaskInput, { LabeledMaskInputProps } from "../../molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";

export interface XGSMaskInputProps
  extends CommonLabeledInputProps,
    LabeledMaskInputProps,
    React.HTMLProps<HTMLInputElement> {
  inputmode?: string;
  appendIcon?: IconProp;
  appendIconExtraClassName?: string;
  appendAction?: () => void;  
}

const XGSMaskInput: React.FC<XGSMaskInputProps> = ({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  ...props
}) => {
  const [field, meta] = useField(props as FieldInputProps<string>);
  return (
    <LabeledMaskInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      fields={field}
      meta={meta}
      {...props}
    />
  );
};

export default XGSMaskInput;
