import UserProfile from "./UserProfile";
import { UserStatuses } from "./enums";

export class UserUtils {
  // customers
  static isUser = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.USER;
    }
  };

  static isAdministrator = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.ADMINISTRATOR;
    }
  };

  static isCustomer = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.USER || user.role === UserStatuses.ADMINISTRATOR;
    }
  };

  // employees
  static isXGSUser = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.XGS_USER;
    }
  };

  static isXGSAdministrator = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.XGS_ADMINISTRATOR;
    }
  };

  static isEmployee = (user?: UserProfile | null) => {
    if (user && user.role) {
      return user.role === UserStatuses.XGS_USER || user.role === UserStatuses.XGS_ADMINISTRATOR;
    }
  };

  static hasPermission = (user: UserProfile | null, accountId: string | null, permission: string): boolean => {
    return false;
    // if (user && user.role) {
    //   if ((user.role === UserStatuses.ADMINISTRATOR && accountId) || user.role === UserStatuses.XGS_USER || user.role === UserStatuses.XGS_ADMINISTRATOR) return true;
    //   if (!accountId) return false;
    //   let currentAccount = user.subAccounts.find(account => account.id === accountId);
    //   if (!currentAccount) return false;
    //   return !!currentAccount.permissions.find(permissionsItem => permissionsItem === permission);
    // } else {
    //   return false;
    // }
  };
}
