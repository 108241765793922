import { createSlice } from "@reduxjs/toolkit";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { initialDeliveryReceiptsState } from "./DeliveryRecieptsState";
import CommonService from "../../app/data/common/commonService";
import { DeliveryReceiptsSearchRequest } from "../../app/data/delivery-receipts/models";
import DeliveryReceiptsService from "../../app/data/delivery-receipts/deliveryRecieptsService";
import ExportService from "../../app/data/export/exportService";

const exportService = ExportService.getInstance();

export const deliveryReceiptsSlice = createSlice({
  name: "deliveryReceipts",
  initialState: initialDeliveryReceiptsState,
  reducers: {
    resetDeliveryReceiptsState: (state) => initialDeliveryReceiptsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    setProbillSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item.probill,
          value: item
        });
      }
      state.probillSuggestions = suggestions;
    },
    setBolSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item,
          value: item
        });
      }
      state.bolSuggestions = suggestions;
    },
    setManifestSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item,
          value: item
        });
      }
      state.manifestSuggestions = suggestions;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    storeInvoiceDocuments: (state, { payload }) => {
      state.documents = payload;
    },
    storeDownloadedFileLink: (state, { payload }) => {
      state.link = payload;
    }
  }
});

export const {
  resetDeliveryReceiptsState,
  setManifestSuggestions,
  setProbillSuggestions,
  setBolSuggestions,
  requestStarted,
  requestFailed,
  requestSucceed,
  storeDownloadedFileLink,
  storeInvoiceDocuments
} = deliveryReceiptsSlice.actions;

export const deliveryReceiptsSelector = (state: IState) => state.deliveryReceipts;

export const getProbillDetails =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("PROBILL"));
    const response = await CommonService.getInstance().getProbillDetails(search, "PROBILL");
    if (response.ok()) {
      dispatch(requestSucceed());
      dispatch(setProbillSuggestions(response.data));
    } else {
      dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    }
  };

export const getManifestDetails =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("MANIFEST"));
    const response = await CommonService.getInstance().getLoadManifestDetails(search);
    if (response.ok()) {
      dispatch(requestSucceed());
      dispatch(setManifestSuggestions(response.data));
    } else {
      dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    }
  };


export const getMasterbillDetails =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("BILL"));
    const response = await CommonService.getInstance().getMasterbillSuggestions(search);
    if (response.ok()) {
      dispatch(requestSucceed());
      dispatch(setBolSuggestions(response.data));
    } else {
      dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    }
  };

export const getDeliveryReceipts =
  (request: DeliveryReceiptsSearchRequest, onSuccess: (fileUrl: string) => void, onFailed: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("SEARCH_RECEIPTS"));
    const response = await DeliveryReceiptsService.getInstance().getDeliveryReceipts(request);
    let exportId;
    if (response.ok()) {
      exportId = response.data.exportId;
    } else {
      dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    }
    if (exportId) {
      // get link of exported file
      let attempt = 0;
      while (attempt < 20) {
        const response = await exportService.getExportLink(exportId);
        if (response.ok()) {
          if (response.data.status === "DONE") {
            dispatch(requestSucceed());
            dispatch(storeDownloadedFileLink(response.data.fileUrl));          
            onSuccess && onSuccess(response.data.fileUrl);
            break;
          }
          if (response.data.status === "FAILED") {
            dispatch(requestFailed(response.getError ? response.getError() : "Error"));
            onFailed();
            break;
          }
        } else {
          dispatch(requestFailed(response.getError ? response.getError() : "Error"));
          onFailed();
          break;
        }
        await new Promise((d) => setTimeout(d, 1000));
        attempt++;
      }
    } else {
      dispatch(requestFailed("Error"));
      onFailed();
    }
  };

const deliveryReceiptsReducer = deliveryReceiptsSlice.reducer;
export default deliveryReceiptsReducer;
