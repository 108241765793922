export const ShipmentStatuses = {
  delivered: "DELIVERED",
  outForDelivery: "OUT FOR DELIVERY",
  warehoused: "WAREHOUSED AT DEST. SRV CNTR",
  podRequired: "POD_REQUIRED"
};

export const shipmentDropdownStatuses = [
  {
    label: "Arrived at Destination Hub",
    value: "DESTINATION_HUB"
  },
  {
    label: "Available at Warehouse",
    value: "AT_WAREHOUSE"
  },
  {
    label: "At Origin Hub",
    value: "ORIGIN_HUB"
  },
  {
    label: "Cancelled",
    value: "CANCELLED"
  },
  {
    label: "Contact Customer Service",
    value: "CONTACT_CUSTOMER_SERVICE"
  },
  {
    label: "Delivered",
    value: "DELIVERED"
  },
  {
    label: "In Transit to Destination Hub",
    value: "IN_TRANSIT"
  },
  {
    label: "Out For Delivery",
    value: "OUT_FOR_DELIVERY"
  },
  {
    label: "Picked Up",
    value: "PICKED_UP"
  },
  {
    label: "Provided to Agent for Delivery",
    value: "PROVIDED_TO_AGENT"
  }
];
