import React, { useMemo, useRef } from "react";
import { EnterPODInformationModel, EnterPODInformationSchema } from "../../../app/data/pod/models";
import moment from "moment";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import { addPods, podSelector } from "../../../slices/pod-information/podSlice";
import { toast } from "react-toastify";
import { xgsRoutes } from "../../../app/route/RoutesConfig";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { useSelector } from "react-redux";
import Button, { ButtonSizes, ButtonThemes } from "../../../components/button/button";
import { cloneDeep, debounce } from "lodash";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import { MaxPodRowsSelectable } from "../getPodInformationColumns";
import { EnterPodRow } from "./enterPodRow";
import { getPodFormValidateDelay, getPodFromFillDuplicatesDelay } from "./helpers";

const initialFormValues: EnterPODInformationModel[] = new Array(3).fill(0).map((_, idx) => ({
  probillNumber: "",
  signature: "",
  deliveryDate: moment().format("MM/DD/YYYY"),
  deliveryTime: "",
  lateCode: "",
  willCall: false,
  comments: "",
  duplicate: false
}))

export const EnterPodInformationDirect = () => {
  const formRef = useRef<FormikProps<{pods: EnterPODInformationModel[]}>>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const podState = useSelector(podSelector);

  const onSubmit = (values: EnterPODInformationModel[]) => {
    const files: File[] = [];
    const parameters: Array<{ probillNumber: number }> = [];

    const preparedData = {
      parameters: parameters,
      podList: values
    };
    const fd = new FormData();
    fd.append("data", JSON.stringify(preparedData));
    for (let file of files) {
      fd.append("files", file);
    }
    dispatch(
      addPods(fd, () => {
        toast.info("Added POD(s)");
        navigate(xgsRoutes.shipments.podInformation);
      },
      (message?: any) => {
        toast.error(message ? message : "Something went wrong")
      })
    );
  };

  const fillOutDuplicates = useMemo(() => {
    const __fillOutDuplicates = () => {
      if (!formRef.current) return;
      var values = cloneDeep(formRef.current.values.pods);
      for (let i=0; i<values.length; i++) {
        const pod = values[i]
        if (pod.duplicate) {
          values[i] = { ...values[i-1], duplicate: true, probillNumber: pod.probillNumber, eta: pod.eta}
        }
      }
      formRef.current.setValues({pods: values});
    }
    return debounce(__fillOutDuplicates, getPodFromFillDuplicatesDelay(formRef.current?.values.pods.length || 1));
  }, [])

  return (
    <ContentContainer title="Enter POD Information">
      <Formik validateOnChange={false} validationSchema={EnterPODInformationSchema} innerRef={formRef}  onSubmit={({pods}) => onSubmit(pods)} initialValues={{ pods: initialFormValues  }}> 
        {(props) => {
          let validateForm = debounce(props.validateForm, getPodFormValidateDelay(props.values.pods.length));
          return (
            <Form>
                <FieldArray name="pods">
                  {(arrayProps) => (
                    <>
                      <div className="xgs-enter-pod__form__controls">
                        <Button
                          className="xgs-enter-pod__form__controls__submit"
                          spinner={podState.requestStarted}
                          style={{ marginBottom: 16 }}
                          theme={ButtonThemes.blue}
                          type="submit"
                        >
                          Submit
                        </Button>
                        <div className="xgs-enter-pod__form__controls__list">
                          <Button 
                            disabled={props.values.pods.length >= MaxPodRowsSelectable} 
                            size={ButtonSizes.small}
                            onClick={() => arrayProps.push({...initialFormValues[0]})}
                            theme={ButtonThemes.blue}
                            type="button"
                          >
                            <XGSIcon 
                              color="white"
                              icon={XGSIcons.faPlus}
                            />
                            <span className="xgs-mobile-only">Row</span>
                            <span className="xgs-desktop-only">Add row</span>
                          </Button>
                          <Button 
                            disabled={props.values.pods.length <= 1} 
                            theme={ButtonThemes.gray} 
                            onClick={() => props.values.pods.length > 1 ? arrayProps.pop() : ""}
                            size={ButtonSizes.small}
                            type="button"
                          >
                            <XGSIcon
                              icon={XGSIcons.faMinus}
                            />
                            <span className="xgs-mobile-only">Row</span>
                            <span className="xgs-desktop-only">Remove</span>
                          </Button>
                        </div>
                      </div>
                      <div className="xgs-enter-pod__form">
                        {props.values.pods.map((item, idx) => {
                          return (
                            <EnterPodRow
                              validate={validateForm}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                              directEntry
                              setFieldValue={props.setFieldValue}  
                              onAnyFieldChange={fillOutDuplicates} 
                              podRow={item} 
                              idx={idx}
                              key={"pod-row+" + idx}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </ContentContainer>
  )
}