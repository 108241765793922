import { BaseState } from "..";
import SearchLinehaulManifestModel from "../../app/data/linehaul-manifests/SearchLinehaulManifestModel";
import LinehaulManifestModel from "../../app/data/linehaul-manifests/model";

export default interface LinehaulManifestState extends BaseState {
  linehaulManifests: LinehaulManifestModel[];
  request: SearchLinehaulManifestModel;
}

export const initialLinehaulManifestState: LinehaulManifestState = {
  linehaulManifests: [],
  request: {},
  loading: false,
  loadingFailed: false,
  loaded: false
};
