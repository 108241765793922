import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuItem } from ".";
import XGSIcon from "../../components/icon/xgsIcon";
import { closeMobileSideMenu } from "../../slices/user/userCreateSlice";

export interface MenuItemsProps {
  items: MenuItem[];
}

const MenuItems: React.FC<MenuItemsProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      {props.items.map((item) => (
        <React.Fragment key={`menu-item-${item.name}`}>
          {item.route && (
            <>
              {item.route ? (
                <NavLink
                  to={item.route}
                  className={({ isActive }) => {
                    const linkClasses = ["xgs-menu__item", "xgs-menu__item--link"];
                    if (isActive) linkClasses.push("xgs-menu__item__selected");
                    return linkClasses.join(" ");
                  }}
                  onClick={() => dispatch(closeMobileSideMenu())}
                >
                  <div className="xgs-menu__icon-container">
                    {item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}
                  </div>
                  <div className={!item.icon ? "xgs-menu__item__sub" : ""}>{item.name}</div>
                </NavLink>
              ) : (
                <span className="xgs-menu__item xgs-menu__item--link">
                  <div className="xgs-menu__icon-container">
                    {item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}
                  </div>
                  <div className={!item.icon ? "xgs-menu__item__sub" : ""}>{item.name}</div>
                </span>
              )}
            </>
          )}
          {!item.route && (
            <div className="xgs-menu__item">
              <div className="xgs-menu__icon-container">
                {item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}
              </div>
              <div>{item.name}</div>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default MenuItems;
