import { createSlice } from "@reduxjs/toolkit";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { FreightExceptionService } from "../../app/data/freight-exception/freightExceptionService";
import { initialSearchFreightExceptionsState } from "./searchFreightExceptionsState";
import { FreightExceptionSearchRequest } from "../../app/data/freight-exception/models";

const freightExceptionService = FreightExceptionService.getInstance();

export const searchFreightExceptionsSlice = createSlice({
  name: "searchFreightExceptions",
  initialState: initialSearchFreightExceptionsState,
  reducers: {
    resetSearchFreightExceptionsState: (state) => initialSearchFreightExceptionsState,
    startSearching: (state) => {
      state.needsToReload = false;
      state.loaded = false;
      state.loading = true;
      state.loadingFailed = false;
    },
    searchFailed: (state, { payload }) => {
      state.loadingFailed = true;
      state.loaded = false;
      state.loading = false;
      state.loadingError = payload;
    },
    setFreightExceptions: (state, { payload }) => {
      state.exceptions = payload.content;
      state.loaded = true;
      state.loading = false;
    },
    reloadFreightExceptions: (state) => {
      if (state.loaded) state.needsToReload = true;
    }
  }
});

export const {
  resetSearchFreightExceptionsState,
  startSearching,
  searchFailed,
  setFreightExceptions,
  reloadFreightExceptions
} = searchFreightExceptionsSlice.actions;

export const searchFreightExceptionsSelector = (state: IState) => state.searchFreightExceptions;

export const searchFreightExceptions =
  (request: FreightExceptionSearchRequest): AppThunk =>
  async (dispatch) => {
    dispatch(startSearching());
    const response = await freightExceptionService.search(request);
    if (response.ok()) {
      dispatch(setFreightExceptions(response.data));
    } else {
      dispatch(searchFailed(response.getError()));
    }
  };

const searchFreightExceptionsReducer = searchFreightExceptionsSlice.reducer;
export default searchFreightExceptionsReducer;
