import axios from "axios";
import api, { ApiResponse } from "../api";
import { ExactFreightModel, FreightSearchRequest, UpdateFreightAppointmentRequest } from "./models";
import { InfiniteScrollCollectionResponse } from "../common/models";

export class ExactFreightService {
  private static instance: ExactFreightService;
  private source: any;
  private constructor() {}
  static getInstance(): ExactFreightService {
    if (!ExactFreightService.instance) {
      ExactFreightService.instance = new ExactFreightService();
    }
    return ExactFreightService.instance;
  }

  public getFreightList = async (
    searchRequest: FreightSearchRequest,
    lastIds?: string
  ): Promise<ApiResponse<InfiniteScrollCollectionResponse<ExactFreightModel>>> => {
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.post(`/freight-listing${lastIds ? "?lastIds=" + lastIds : ""}`, searchRequest, {
      cancelToken: this.source.token
    });
  };

  public getFreightDetails = async (probillNumber: number): Promise<ApiResponse<any>> => {
    return await api.get(`/freight-status/${probillNumber}`);
  };

  public updateFreightAppointment = async (
    request: UpdateFreightAppointmentRequest
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/freight-appointment/${request.probillNumber}`, request);
  };
}
