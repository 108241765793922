import React from "react";
import { useField, FieldInputProps } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import LabeledTextInput from "../../molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { CommonLabeledInputProps } from "../../molecules/labeled-inputs/labeledInput";

export interface XGSFormInputProps
  extends CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement> {
  inputmode?: string;
  appendIcon?: IconProp;
  appendIconExtraClassName?: string;
  appendAction?: () => void;  
}

const XGSFormInput: React.FC<XGSFormInputProps> = ({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  ...props
}) => {
  const [field, meta] = useField(props as FieldInputProps<string>);

  return (
    <LabeledTextInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      fields={field}
      meta={meta}
      {...props}
    />
  );
};

export default XGSFormInput;
