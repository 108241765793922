import api, { ApiResponse } from "../api";
import { UpdateLoadManifestRequest } from "./model";

export class LoadManifestService {
  private static instance: LoadManifestService;
  private source: any;
  private constructor() {}
  static getInstance(): LoadManifestService {
    if (!LoadManifestService.instance) {
      LoadManifestService.instance = new LoadManifestService();
    }
    return LoadManifestService.instance;
  }

  async getLoadManifestDetails(manifestNumber: number): Promise<ApiResponse<any>> {
    return await api.get(`load-manifest/${manifestNumber}`);
  }

  async updateLoadManifestDetails(data: UpdateLoadManifestRequest): Promise<ApiResponse<any>> {
    return await api.post(`load-manifest`, data);
  }
}
