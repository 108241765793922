import api, { ApiResponse } from "../api";
import Invoice, {
  DocumentRequestModel,
  GetAgingInvoiceRequestModel,
  GetInvoiceDetailsRequestModel,
  InvoiceDetails,
  InvoiceDocument
} from "./models";
import { CollectionResponse } from "../common/models";

class InvoiceService {
  private static instance: InvoiceService;

  private constructor() {}

  static getInstance(): InvoiceService {
    if (!InvoiceService.instance) {
      InvoiceService.instance = new InvoiceService();
    }

    return InvoiceService.instance;
  }

  public getAgingInvoices = async (
    requestModel: GetAgingInvoiceRequestModel
  ): Promise<ApiResponse<CollectionResponse<Invoice>>> => {
    return await api.get(
      `/accounts/${requestModel.subAccountId}/invoices?${
        requestModel.agingPeriod ? "agingPeriod=" + requestModel.agingPeriod : ""
      }${requestModel.lastIds ? "&lastIds=" + requestModel.lastIds : ""}`
    );
  };

  public getAgingInvoicesForCSV = async (
    requestModel: GetAgingInvoiceRequestModel
  ): Promise<ApiResponse<CollectionResponse<Invoice>>> => {
    return await api.get(
      `/accounts/${requestModel.subAccountId}/invoices?all=true${
        requestModel.agingPeriod ? "&agingPeriod=" + requestModel.agingPeriod : ""
      }`
    );
  };

  public getInvoiceDetails = async (
    requestModel: GetInvoiceDetailsRequestModel
  ): Promise<ApiResponse<InvoiceDetails>> => {
    return await api.get(`/accounts/${requestModel.subAccountId}/invoices/${requestModel.invoiceId}`);
  };

  public getInvoiceDocuments = async (probill: number): Promise<ApiResponse<InvoiceDocument[]>> => {
    return await api.get(`/documents/probill/${probill}`);
  };

  public downloadInvoiceDocument = async (documentId: number, type: string): Promise<ApiResponse<any>> => {
    return await api.get(`/documents/${documentId}?type=${type}`);
  };

  public getInvoiceRecords = async (probill: number): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${probill}/records`);
  };

  public downloadAllInvoiceDocuments = async (probill: number): Promise<ApiResponse<any>> => {
    return await api.get(`/documents/probill/${probill}/download`, {
      responseType: "blob"
    });
  };

  public downloadGroupDocuments = async (request: DocumentRequestModel[]): Promise<ApiResponse<any>> => {
    return await api.post("/documents/download", request);
  };
}

export default InvoiceService;
