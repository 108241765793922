import React from "react";
import Loading from "../../loading/loading";
import "./contentContainerToolbar.scss";

export interface ContentContainerToolbarProps {
  title?: string;
  isLoading?: boolean;
  className?: string;
  children?: any
}

const ContentContainerToolbar: React.FC<ContentContainerToolbarProps> = (
  props
) => {
  return (
    <div className="xgs-site__content-container__toolbar">
      <div>{props.title} <Loading isLoading={props.isLoading} /></div>
      <div className="xgs-site__content-container__toolbar__buttons">
        {props.children}
      </div>
    </div>
  );
};

export default ContentContainerToolbar;
