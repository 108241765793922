import React, { useEffect } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import Table from "../../components/table/table";
import ContentContainerToolbar from "../../components/molecules/content-container-toolbar/contentContainerToolbar";
import FiltersToggle from "../../components/filters-toggle/filtersToggle";
import {
  resetTrackShipment,
  searchShipments,
  trackShipmentSelector
} from "../../slices/track-shipment/trackShipmentSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import getTrackShipmentColumns from "./trackingColumns";
import TrackingFilter from "./tracking-filter/trackingFilter";
import XGSErrorMessage from "../../components/error-message/errorMessage";

export const Tracking: React.FC<{}> = () => {
  const trackShipmentState = useSelector(trackShipmentSelector);
  const dispatch = useAppDispatch();

  const onInfiniteScroll = () => {
    dispatch(searchShipments(trackShipmentState.request, true));
  };
  useEffect(() => {
    // onInfiniteScroll();
    return () => {
      dispatch(resetTrackShipment());
    };
    // eslint-disable-next-line
  }, [dispatch]);
  return (
    <>
      <ContentContainer
        titleComponent={
          <ContentContainerToolbar title="Track Shipments">
            <FiltersToggle />
          </ContentContainerToolbar>
        }
        header={<TrackingFilter />}
      >
        <div className="xgs-tracking">
          {trackShipmentState.loadingError && <XGSErrorMessage>{trackShipmentState.loadingError}</XGSErrorMessage>}
          {!trackShipmentState.loadingFailed && (
            <Table
              isLoading={trackShipmentState.loading}
              data={trackShipmentState.shipments}
              columns={getTrackShipmentColumns(null, false, false)}
              cursorPointer={false}
              infiniteScroll={true}
              infiniteScrollLoading={trackShipmentState.loadingPortion}
              infiniteScrollHasNext={!trackShipmentState.loadedAll}
              onInfiniteScroll={onInfiniteScroll}
              rowHeight={82}
              minTableHeight={420}
              // responsive
            />
          )}
        </div>
      </ContentContainer>
    </>
  );
};
