import React, { useEffect, useRef, useState } from "react";
import {
  reloadShipmentDetails,
} from "../../slices/shipment-details/shipmentDetailsSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import InvoiceDetailsTable from "../invoices/invoice-details/table/invoiceDetailsTable";
import { FreightExceptionIncidentOptions } from "../../app/data/freight-exception/IncidentTypes";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import XGSFormTextarea from "../../components/form/textarea/xgsFormTextarea";
import { Form, Formik, FormikProps } from "formik";
import XGSFormSelect from "../../components/form/select/xgsFormSelect";
import Button, { ButtonThemes } from "../../components/button/button";
import FreightExceptionState from "../../slices/freight-exception/freightExceptionState";
import {
  addNewFreightException,
  freightExceptionSelector,
  resetFreightExceptionState
} from "../../slices/freight-exception/freightExceptionSlice";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "./addFreightException.scss";
import { AddFreightExceptionRequest } from "../../app/data/freight-exception/models";
import { freightStatusSelector, reloadFreightStatus } from "../../slices/exact-freight/freightStatusSlice";
import { ACCEPTED_FORMATS } from "../delivery-records/constants";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import styles from "../../styles/variables.scss";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";
import { reloadFreightExceptions } from "../../slices/freight-exception/searchFreightExceptionsSlice";
import SlideOutSidebar from "../../components/slide-out-sidebar/slideOutSidebar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Table from "../../components/table/table";
import { getFreightExceptionHistoryColumns } from "./freight-exception-history/freightExceptionHistoryColumns";
import XGSErrorMessage from "../../components/error-message/errorMessage";

interface AddExceptionFormProps {
  incidentType: string;
  description: string;
}
const addExceptionFormValues: AddExceptionFormProps = { description: "", incidentType: "" };
const TextError: React.FC<any> = (props) => {
  return <div className="xgs-labeled-input__validation-error">{props.children}</div>;
};
const initialFormErrorState = {
  incidentType: "",
  description: "",
  selectedItems: ""
};
interface Props {
  invoiceNumber: number;
  show: boolean;
  onClose: () => void;
}

export const AddFreightExceptions: React.FC<Props> = ({invoiceNumber, ...props}) => {
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set<string>());
  const freightExceptionState: FreightExceptionState = useSelector(freightExceptionSelector);
  const freightStatusState = useSelector(freightStatusSelector);
  const [formErrors, setFormErrors] = useState(initialFormErrorState);
  const [incidentType, setIncidentType] = useState<XGSSelectOption | null>();
  const formRef = useRef<FormikProps<AddExceptionFormProps>>(null);

  useEffect(() => {
    if (invoiceNumber) {
      return function cleanup() {
        dispatch(resetFreightExceptionState());
      };
    }
  }, [dispatch, invoiceNumber]);

  useEffect(() => {
    if (!props.show) resetForm();
  }, [props.show])

  const onClickSelect = (serialNumber: string) => {
    setSelectedItems((currentSet) => {
      const newSet = new Set(currentSet);
      if (newSet.has(serialNumber)) {
        newSet.delete(serialNumber);
      } else {
        newSet.add(serialNumber);
      }
      return newSet;
    });
  };

  const onSubmit = (exceptionFormObject: AddExceptionFormProps) => {
    if (!Array.from(selectedItems?.values()).length) {
      setFormErrors((state) => ({ ...state, selectedItems: "Please pick at least one item." }));
      return;
    }

    if (!exceptionFormObject.incidentType) {
      setFormErrors((state) => ({
        ...state,
        incidentType: "Please select one of the reasons from the dropdown."
      }));
      return;
    }
    setFormErrors(initialFormErrorState);
    const fd = new FormData();
    for (let photo of photos) {
      fd.append("files", photo);
    }
    const preparedData: AddFreightExceptionRequest = {
      probillNumber: invoiceNumber,
      description: exceptionFormObject.description,
      incidentType: exceptionFormObject.incidentType,
      itemSerialNumbers: Array.from(selectedItems.values())
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(
      addNewFreightException(
        fd,
        () => {
          resetForm();
          dispatch(reloadFreightStatus());
          dispatch(reloadShipmentDetails());
          dispatch(reloadFreightExceptions());
          toast.info("Exception(s) added succesfully!");
          props.onClose && props.onClose();
        },
        (errorMessage: string | undefined) => {
          toast.error(errorMessage || "Something went wrong");
        }
      )
    );
  };

  const resetForm = () => {
    setPhotos([]);
    setSelectedItems(new Set());
    formRef.current?.resetForm();
    setIncidentType(null);
  };

  const MAX_SIZE = 4;
  const MAX_PHOTOS = 3;
  const [fileError, setFileError] = useState<string>("");
  const [photos, setPhotos] = useState<any[]>([]);
  const { getInputProps, getRootProps } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            deleted: false,
            comment: "",
            internalOnly: false
          })
        )
      );
    }
  });
  return (
    <SlideOutSidebar header={"Add Exception: " + invoiceNumber} spinner={freightStatusState.loading} show={props.show} onClose={props.onClose}>
      {freightStatusState.loaded && (
        <>
          {!freightStatusState.details?.probillItems?.length && <XGSErrorMessage>No valid probill items found</XGSErrorMessage>}
          {!!freightStatusState.details?.probillItems.length && <div className="add-freight-exception">
            <Tabs onSelect={() => resetForm()}>
              <TabList>
                <Tab>Add Exception</Tab>
                <Tab>Existing ({freightStatusState.details?.freightExceptions.length ? freightStatusState.details?.freightExceptions.length : "0"})</Tab>
              </TabList>
              <TabPanel>
                <InvoiceDetailsTable
                  select
                  title={"Select Item(s): *"}
                  selectedItems={selectedItems}
                  items={freightStatusState.details?.probillItems}
                  onClickSelect={onClickSelect}
                />
                <TextError>{formErrors.selectedItems}</TextError>
                <Formik innerRef={formRef} onSubmit={onSubmit} initialValues={addExceptionFormValues}>
                  {(props: FormikProps<AddExceptionFormProps>) => (
                    <Form  className="add-freight-exception__form">
                      <XGSFormSelect
                        error={formErrors.incidentType}
                        className="add-freight-exception__form__item"
                        name="incidentType"
                        label="Incident Type:"
                        labelMode={LabelModes.column}
                        onValueChange={(option) => {
                          props.setFieldValue(`incidentType`, option?.value);
                          setIncidentType(option);
                        }}
                        value={incidentType}
                        required
                        options={FreightExceptionIncidentOptions}
                      />
                      <div className="add-freight-exception__form__item">
                        <XGSFormTextarea
                          required
                          maxLength={420}
                          counter={420}
                          name="description"
                          label="Description:"
                          onChange={(value: any) => {
                            props.setFieldValue(`description`, value.target.value);
                          }}
                        />
                      </div>
                      <div className="add-freight-exception__form__upload">
                        <div className="add-freight-exception__form__upload__header">Photos:</div>
                        <div {...getRootProps({ className: "add-freight-exception__form__upload__area" })}>
                          <input {...getInputProps()} />
                          <span className="blue-link">tap, click or drag &amp; drop photos here</span>
                          <div className="xgs-upload__notes">
                            (<strong>JPEG</strong>, <strong>PNG</strong>, <strong>WebP</strong>, <strong>GIF</strong> and{" "}
                            <strong>BMP</strong> formats only)
                          </div>
                          {photos.length > 0 && (
                            <div className="add-freight-exception__form__upload__message">
                              <span
                                style={{ color: "green" }}
                                className="common-text"
                              >{`${photos.length} photo(s) selected`}</span>
                              <XGSIcon
                                icon={XGSIcons.faXmarkCircle}
                                color={styles.red}
                                cursor={"pointer"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPhotos([]);
                                }}
                                size="xl"
                              />
                            </div>
                          )}
                          {fileError && <TextError>{fileError}</TextError>}
                        </div>
                      </div>
                      <div className="add-freight-exception__form__item">
                        <Button
                          type="submit"
                          disabled={Array.from(selectedItems).length === 0 || !formRef.current?.values.description.trim() || !formRef.current?.values.incidentType}
                          theme={ButtonThemes.blue}
                          spinner={freightExceptionState.requestStarted}
                        >
                          Add
                        </Button>

                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel>
                <div className="add-freight-exception__history">
                  <div className="add-freight-exception__history__table">
                    <Table
                      columns={getFreightExceptionHistoryColumns("small")}
                      data={freightStatusState.details?.freightExceptions}
                      infiniteScroll={true}
                      strictMinTableHeight
                      minTableHeight={280}
                      rowHeight={60}
                    />

                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>}
        </>
        )}
    </SlideOutSidebar>
  );
};
