import React from "react";
import XGSDate from "../xgs-date/xgsDate";
import { XGSInputBaseProps } from "../../xgs-input/xgsInput";
import "./xgsDateRange.scss";

export interface XGSDateRangeBaseProps {
  minDate?: Date;
  start?: string;
  end?: string;
  onStartChange: (date: string) => void;
  onEndChange: (date: string) => void;
  className?: string;
}

export interface XGSDateRangeProps
  extends XGSDateRangeBaseProps,XGSInputBaseProps {
}

const XGSDateRange: React.FC<XGSDateRangeProps> = ({start, onStartChange, end, onEndChange, ...props}) => {
  return (
    <div className="xgs-date-range">
      <XGSDate
        {...props}
        value={start}
        onDateChange={onStartChange}
        onChange={() => null}
        selectsStart
        startDate={start ? new Date(start) : undefined}
        endDate={end ? new Date(end) : undefined}
        maxDate={end ? new Date(end) : undefined}
        minDate={props.minDate ? props.minDate : undefined}
        placeholderText="From"
      />
      <div className="xgs-date-range__divider">-</div>
      <XGSDate
        {...props}
        value={end}
        onDateChange={onEndChange}
        onChange={() => null}
        selectsEnd
        startDate={start ? new Date(start) : undefined}
        endDate={end ? new Date(end) : undefined}
        minDate={start ? new Date(start) : (props.minDate ? props.minDate : undefined)}
        placeholderText="To"
        popperPlacement="bottom-end"
      />
    </div>
  );
}

export default XGSDateRange;