
export default interface FreightAppointmentState {
  updating: boolean;
  updated: boolean;
  updateError?: string;
  updateFailed: boolean;
  probillSuggestions: any[];
  searchingProbills: boolean;
  searchProbillsFailed: boolean;
  searchProbillsError: string;
}

export const initialFreightAppointmentState: FreightAppointmentState = {
  updating: false,
  updated: false,
  updateFailed: false,
  probillSuggestions: [],
  searchingProbills: false,
  searchProbillsFailed: false,
  searchProbillsError: ""
};
