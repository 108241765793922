import { createSlice } from "@reduxjs/toolkit";
import { initialFreightStatusState } from "./freightStatusState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { ExactFreightService } from "../../app/data/exact-freight/exactFreightService";

const exactFreightService = ExactFreightService.getInstance();

export const freightStatusSlice = createSlice({
  name: "freightStatus",
  initialState: initialFreightStatusState,
  reducers: {
    resetFreightStatusState: (state) => initialFreightStatusState,
    setFreightStatusDetails: (state, { payload }) => {
      state.details = payload;
      state.loaded = true;
      state.loading = false;
    },
    startLoadingFreightStatusDetails: (state) => {
      state.needsToReload = false;
      state.loaded = false;
      state.loading = true;
    },
    freightStatusLoadingFailed: (state, { payload }) => {
      state.loadingFailed = true;
      state.loading = false;
      state.loadingError = payload;
    },
    reloadFreightStatus: (state) => {
      if (state.loaded) state.needsToReload = true;
    }
  }
});

export const {
  setFreightStatusDetails,
  resetFreightStatusState,
  startLoadingFreightStatusDetails,
  freightStatusLoadingFailed,
  reloadFreightStatus
} = freightStatusSlice.actions;

export const freightStatusSelector = (state: IState) => state.freightStatus;

export const getFreightStatusDetails =
  (probillNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingFreightStatusDetails());
    const response = await exactFreightService.getFreightDetails(probillNumber);
    if (response.ok()) {
      dispatch(setFreightStatusDetails(response.data));
    } else {
      dispatch(freightStatusLoadingFailed(response.getError()));
    }
  };

const freightStatusReducer = freightStatusSlice.reducer;
export default freightStatusReducer;
