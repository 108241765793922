import DateCell from "../../../components/table/custom-cells/date-cell/dateCell";

export const getFreightExceptionHistoryColumns = (size?: "small" | "large") => {
  return [
    {
      Header: "Incident",
      accessor: "incidentNumber",
      width: 70
    },
    {
      Header: "Type",
      accessor: "type",
      width: 120
    },
    {
      Header: "Started",
      accessor: "startedDate",
      width: 90,
      Cell: DateCell
    },
    {
      Header: "Resolved",
      accessor: "resolvedDate",
      width: 90,
      Cell: DateCell
    },
    {
      Header: "Roll",
      accessor: "itemSerial",
      width: 130
    },
    {
      Header: "Description (limited to 60 characters)",
      accessor: "description",
      width: size === "small" ? 240 : 350
    }
  ];
};
