import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";

import { LinehaulManifestService } from "../../app/data/linehaul-manifests/LinehaulManifestService";
import { initialRollDetailsState } from "./rollDetailsState";
import ExportService from "../../app/data/export/exportService";

const linehaulManifestService = new LinehaulManifestService();
const exportService = ExportService.getInstance();

export const rollDetailsSlice = createSlice({
  name: "rollDetails",
  initialState: initialRollDetailsState,
  reducers: {
    resetRollDetails: (state) => initialRollDetailsState,
    startSearching: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadingFailed = false;
    },
    setManifests: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
      state.details = payload;
    },
    searchFailed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    loadingCSVStarted: (state) => {
      state.loadingCSVStarted = true;
      state.loadingCSVSuccess = false;
      state.loadingCSVFailed = false;
    },
    loadingCSVSucceed: (state) => {
      state.loadingCSVStarted = false;
      state.loadingCSVSuccess = true;
    },
    loadingCSVFailed: (state, { payload }) => {
      state.loadingCSVStarted = false;
      state.loadingCSVSuccess = false;
      state.loadingCSVFailed = true;
      state.loadingCSVError = payload;
    },
    resetCSVErrors: (state) => {
      state.loadingCSVFailed = false;
      state.loadingCSVError = null;
    },
    storeCSVLink: (state, { payload }) => {
      state.CSVLink = payload;
    }
  }
});

export const { 
  resetRollDetails, 
  startSearching, 
  setManifests, 
  searchFailed,
  loadingCSVFailed,
  loadingCSVStarted,
  loadingCSVSucceed,
  storeCSVLink,
  resetCSVErrors } = rollDetailsSlice.actions;

export const rollDetailsSelector = (state: IState) => state.rollDetails;

export const getRollDetails =
  (manifestNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(startSearching());
    const response = await linehaulManifestService.getRollDetails(manifestNumber);
    if (response.ok()) {
      dispatch(setManifests(response.data));
    } else {
      dispatch(searchFailed(response.getError()));
    }
  };

export const getRollDetailsCSV =
  (linehaulManifest: number, onSuccess: (response: any) => void): AppThunk =>
  async (dispatch) => {
    dispatch(loadingCSVStarted());
    const response = await linehaulManifestService.downloadCSV(linehaulManifest);
    let exportId;
    if (response.ok()) {
      exportId = response.data.exportId;
    } else {
      dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
    }
    if (exportId) {
      // get link of exported file
      let attempt = 0;
      while (attempt < 200) {
        const response = await exportService.getExportLink(exportId);
        if (response.ok()) {
          if (response.data.status === "DONE") {
            dispatch(loadingCSVSucceed());
            dispatch(storeCSVLink(response.data.fileUrl));
            onSuccess && onSuccess(response.data.fileUrl);
            break;
          }
          if (response.data.status === "FAILED") {
            dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
            break;
          }
        } else {
          dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
          break;
        }
        await new Promise((d) => setTimeout(d, 2000));
        attempt++;
      }
    }
  };

const rollDetailsReducer = rollDetailsSlice.reducer;
export default rollDetailsReducer;
