import { createSlice } from "@reduxjs/toolkit";
import { ExactFreightService } from "../../app/data/exact-freight/exactFreightService";
import { initialExactFreightState } from "./exactFreightState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { FreightSearchRequest } from "../../app/data/exact-freight/models";

const exactFreightService = ExactFreightService.getInstance();

export const exactFreightSlice = createSlice({
  name: "exactFreight",
  initialState: initialExactFreightState,
  reducers: {
    resetExactFreight: (state) => initialExactFreightState,
    exactFreightLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    setRequest: (state, { payload }) => {
      state.lastIds = undefined;
      state.request = payload;
    },
    exactFreightInfiniteLoadingStarted: (state) => {
      if (state.count === 0) {
        state.loading = true;
      }
      state.loadingPortion = true;
      state.loaded = false;
      state.loadingFailed = false;
      state.loadedAll = false;
    },
    exactFreightLoadingFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingFailed = true;
      state.freights = [];
      state.loadingError = payload;
    },
    setExactFreight: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.loadingPortion = false;
      state.freights = payload.content;
      state.count += payload.content.length;
      state.loadedAll = !!(payload.content.length < 50);
      state.lastIds = payload.scroll.lastIds;
    },
    addExactFreight: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.loadingPortion = false;
      state.lastIds = payload.scroll.lastIds;
      state.loadedAll = !!(payload.content.length < 50);
      state.freights = [...state.freights, ...payload.content];
      state.count += payload.content.length;
    }
  }
});

export const {
  resetExactFreight,
  exactFreightLoadingFailed,
  exactFreightLoadingStarted,
  exactFreightInfiniteLoadingStarted,
  addExactFreight,
  setExactFreight,
  setRequest
} = exactFreightSlice.actions;

export const exactFreightSelector = (state: IState) => state.exactFreight;

export const getExactFreight =
  (searchRequest: FreightSearchRequest, lastIds?: string, infiniteScroll?: boolean): AppThunk =>
  async (dispatch) => {
    if (!infiniteScroll) dispatch(setRequest(searchRequest));
    dispatch(infiniteScroll ? exactFreightInfiniteLoadingStarted() : exactFreightLoadingStarted());
    const response = await exactFreightService.getFreightList(searchRequest, lastIds);
    if (response.ok()) {
      dispatch(infiniteScroll ? addExactFreight(response.data) : setExactFreight(response.data));
    } else {
      response.getError && dispatch(exactFreightLoadingFailed(response.getError()));
    }
  };
export const exactFreightReducer = exactFreightSlice.reducer;
