export interface PodState {
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestError?: string | null;
  requestCreator?: string;
}

export const initialPodState: PodState = {
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false
};
