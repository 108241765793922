import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./../../styles/variables.scss";
import CombinedCell from "../../components/table/custom-cells/combined-cell/combinedCell";

const ButtonCell = (cellProps: any) => {
  // const [hover, setHover] = useState(false);
  const [hoverHotRush, setHoverHotRush] = useState(false);
  return (
    <>
      <div>
        {cellProps.value}
        {cellProps.row.original.hotRush && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            onMouseEnter={() => setHoverHotRush(true)}
            onMouseLeave={() => setHoverHotRush(false)}
            size="lg"
            color={styles.red}
            className="inbound-freight-list__hot"
          />
        )}
        {hoverHotRush && <div className="inbound-freight-list__hot-rush-hover">Hot Rush</div>}
      </div>
    </>
  );
};
export const getInboundFreightColumns = () => [
  {
    Header: "Manifest#",
    accessor: "manifestNumber",
    Cell: ButtonCell,
    width: 94
  },
  {
    Header: "Status",
    accessor: "status",
    width: 84
  },
  {
    Header: "Carrier#",
    accessor: "carrierNumber",
    width: 94
  },
  {
    Header: "Trailer#",
    accessor: "trailerNumber",
    width: 94
  },
  {
    Header: "Dispatched From",
    accessor: "originCity",
    Cell: (cellProps: any) => {
      return <CombinedCell
          values={[cellProps.row.original.originDate.toUiDateFormat(), cellProps.row.original.originCity]}
        />
    }
  },
  {
    Header: "Expected/Arrived",
    width: 130,
    accessor: "arrivalDate",
    Cell: (cellProps: any) => {
      return <CombinedCell
          values={[cellProps.row.original.arrivalDate, cellProps.row.original.arrivalTime.toUiTimeFormat()]}
        />
    }
  },
  {
    Header: "Probill Count",
    accessor: "probillCount",
    width: 94
  },
  {
    Header: "Pieces Count",
    accessor: "piecesCount",
    width: 94
  },
  {
    Header: "Yards",
    accessor: "yards",
    width: 94
  },
  {
    Header: "Weight",
    accessor: "lbs",
    width: 94
  }
];

export default getInboundFreightColumns;
