import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialLinehaulManifestState } from "./LinehaulManifestState";
import { LinehaulManifestService } from "../../app/data/linehaul-manifests/LinehaulManifestService";
import SearchLinehaulManifestModel from "../../app/data/linehaul-manifests/SearchLinehaulManifestModel";

const linehaulManifestService = new LinehaulManifestService();

export const linehaulManifestSlice = createSlice({
  name: "linehaulManifest",
  initialState: initialLinehaulManifestState,
  reducers: {
    resetLinehaulManifestState: (state) => initialLinehaulManifestState,
    startSearching: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadingFailed = false;
    },
    startInfiniteSearching: (state) => {
      state.loaded = false;
      state.loadingFailed = false;
    },
    setManifests: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
      state.linehaulManifests = payload;
    },
    searchFailed: (state, { payload }) => {
      state.loading = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    setRequestFilters: (state, { payload }) => {
      state.request = {};
    }
  }
});

export const {
  resetLinehaulManifestState,
  startSearching,
  startInfiniteSearching,
  setManifests,
  searchFailed,
  setRequestFilters
} = linehaulManifestSlice.actions;

export const lineHaulManifestSelector = (state: IState) => state.linehaulManifest;

export const searchLinehaulManifests =
  (requestModel: SearchLinehaulManifestModel, infiniteScroll?: boolean): AppThunk =>
  async (dispatch) => {
    if (!infiniteScroll) dispatch(setRequestFilters(requestModel));
    dispatch(startSearching());
    const response = await linehaulManifestService.searchManifests(requestModel);
    if (response.ok()) {
      dispatch(setManifests(response.data));
    } else {
      dispatch(searchFailed(response.getError()));
    }
  };

const linehaulManifestReducer = linehaulManifestSlice.reducer;
export default linehaulManifestReducer;
