import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { xgsRoutes } from "../../../app/route/RoutesConfig";
import {
  resetTrackShipment,
  searchShipments,
  trackShipmentSelector
} from "../../../slices/track-shipment/trackShipmentSlice";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { EnterPODInformationModel} from "../../../app/data/pod/models";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import Button, { ButtonThemes } from "../../../components/button/button";
import "./enterPodInformation.scss";
import { EnterPODInformationSchema } from "../../../app/data/pod/models";
import { addPods, podSelector, resetPodState } from "../../../slices/pod-information/podSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import XGSErrorMessage from "../../../components/error-message/errorMessage";
import { debounce, cloneDeep } from "lodash";
import { PodSearchParams } from "../route";
import { EnterPodRow } from "./enterPodRow";
import { getPodFormValidateDelay, getPodFromFillDuplicatesDelay } from "./helpers";

export const EnterPODInformation: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const shipmentsState = useSelector(trackShipmentSelector);
  const dispatch = useAppDispatch();
  const [documents] = useState<Map<number, File[]>>(new Map());
  const formRef = useRef<FormikProps<{pods: EnterPODInformationModel[]}>>(null);
  const podState = useSelector(podSelector);

  const selectedInvoiceNumbers: number[] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const selectedInvoices = params.get("selected-invoices");
    if (!selectedInvoices) navigate(xgsRoutes.shipments.podInformation);
    const invoices = params.get(PodSearchParams.selectedInvoices)?.split(",").map(Number);
    if (!invoices || invoices.length === 0) navigate(xgsRoutes.shipments.podInformation);
    return invoices || [];
  }, [location, navigate]);
  
  const allDuplicates = !!(new URLSearchParams(location.search).get(PodSearchParams.allDuplicates));
  
  const initialPodFormState: EnterPODInformationModel[] | null = useMemo(() => {
    let filteredShipments = shipmentsState.shipments
      .filter((shipment) => !shipment.pod.date)
      .map((shipment, idx) => ({
        probillNumber: shipment.invoice.invoiceNumber.toString(),
        deliveryDate: moment().format("MM/DD/YYYY"),
        eta: shipment.estimatedDeliveryDate, // Not required in api, but required for late coded
        deliveryTime: "",
        lateCode: "",
        willCall: false,
        signature: "",
        comments: "",
        duplicate: (idx > 0) &&  allDuplicates
      }));
    if (!filteredShipments.length || !shipmentsState.loaded) {
      return null;
    }
    return filteredShipments;
  }, [shipmentsState, allDuplicates]);

  useEffect(() => {
    dispatch(searchShipments({ trackingNumber: selectedInvoiceNumbers.map(String) }, false));
    return () => {
      dispatch(resetTrackShipment());
      dispatch(resetPodState());
    };
  }, [selectedInvoiceNumbers, dispatch]);


  const fillOutDuplicates = useMemo(() => {
    const __fillOutDuplicates = () => {
      if (!formRef.current) return;
      var values = cloneDeep(formRef.current.values.pods);
      for (let i=0; i<values.length; i++) {
        const pod = values[i]
        if (pod.duplicate) {
          values[i] = { ...values[i-1], duplicate: true, probillNumber: pod.probillNumber, eta: pod.eta}
        }
      }
      formRef.current.setValues({pods: values});
    }
    return debounce(__fillOutDuplicates, getPodFromFillDuplicatesDelay(formRef.current?.values.pods.length || 1));
  }, [])

  const onSubmit = (values: EnterPODInformationModel[]) => {
    const files: File[] = [];
    const parameters: Array<{ probillNumber: number }> = [];
    Array.from(documents.keys()).forEach((probillNumber) => {
      const acceptedFiles = documents.get(probillNumber);
      if (!acceptedFiles || !acceptedFiles.length) return;
      parameters.push({ probillNumber });
      files.push(acceptedFiles[0]);
    });

    const preparedData = {
      parameters: parameters,
      podList: values
    };
    const fd = new FormData();
    fd.append("data", JSON.stringify(preparedData));
    for (let file of files) {
      fd.append("files", file);
    }
    dispatch(
      addPods(fd, () => {
        toast.info("Added " + (selectedInvoiceNumbers.length > 1 ? "PODs" : "POD"));
        navigate(xgsRoutes.shipments.podInformation);
      },
      (message?: any) => {
        toast.error(message ? message : "Something went wrong")
      })
    );
  };

  const directEntry = useMemo(() => {
    return !(new URLSearchParams(location.search).get(PodSearchParams.selectedInvoices))
  }, [location.search])

  return (
    <ContentContainer title="Enter POD Information" isLoading={shipmentsState.loading}>
      {(selectedInvoiceNumbers.length && shipmentsState.loaded && !initialPodFormState?.length) && <XGSErrorMessage>Selected probills have PODs</XGSErrorMessage>}
      {shipmentsState.loaded && initialPodFormState && (
        <div>
          <Formik enableReinitialize validateOnChange={false} innerRef={formRef} validationSchema={EnterPODInformationSchema as any} onSubmit={({pods}) => onSubmit(pods)} initialValues={{ pods: initialPodFormState  }}> 
            {(props) => {
              let validateForm = debounce(props.validateForm, getPodFormValidateDelay(props.values.pods.length));
              return (
                <Form>
                  <Button
                    spinner={podState.requestStarted}
                    style={{ marginBottom: 16 }}
                    theme={ButtonThemes.blue}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <div className="xgs-enter-pod__form">
                    <FieldArray name="pods">
                      {() => (
                        props.values.pods.map((item, idx) => {
                          return (
                            <EnterPodRow validate={validateForm} onBlur={props.handleBlur} onChange={props.handleChange} key={idx} directEntry={directEntry} setFieldValue={props.setFieldValue}  onAnyFieldChange={fillOutDuplicates} podRow={item} idx={idx}/>
                          );
                        })
                      )}
                    </FieldArray>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </ContentContainer>
  );
};
