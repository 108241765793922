import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GoogleMap from "google-map-react";
import XGSPopup from "../../components/xgs-popup/xgsPopup";
import "../../components/map/map.scss";
import Marker from "../../components/map/marker/marker";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import Button, { ButtonThemes, ButtonSizes } from "../../components/button/button";
import XGSIcons from "../../components/icon/xgsIcons";
import XGSIcon from "../../components/icon/xgsIcon";
import TrailerLocationState from "../../slices/trailer-location/TrailerLocationState";
import {
  getTrailerLocation,
  resetTrailerLocation,
  trailerLocationSelector
} from "../../slices/trailer-location/trailerLocationSlice";
import Loading from "../../components/loading/loading";
import useInterval from "../../hooks/useInterval";
import "./trailerLocation.scss";
import { useAppDispatch } from "../../hooks/storeHooks";

export interface TrailerLocationProps {
  invoiceNumber: number;
  textMode?: boolean;
}

const TrailerLocation: React.FC<TrailerLocationProps> = (props) => {
  const dispatch = useAppDispatch();
  const trailerLocationState: TrailerLocationState = useSelector(trailerLocationSelector);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClose = () => {
    setShowModal(false);
  };

  const trailerLocation = trailerLocationState.result
    ? {
        lat: trailerLocationState.result.latitude,
        lng: trailerLocationState.result.longitude
      }
    : undefined;

  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || "";

  const handleApiLoaded = (map: any, maps: any) => {
    const trafficLayer = new maps.TrafficLayer();
    trafficLayer.setMap(map);
  };

  useEffect(() => {
    if (!props.invoiceNumber) return;
    dispatch(getTrailerLocation(props.invoiceNumber, false));

    return () => {
      dispatch(resetTrailerLocation());
    };
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    if (!props.invoiceNumber || !showModal) return;
    dispatch(getTrailerLocation(props.invoiceNumber, true));
  }, 30000);

  return (
    <>
      <div
        className={`xgs-trailer-locations__open__container ${
          props.textMode ? " xgs-trailer-locations__open__container--text-mode" : ""
        }`}
      >
        {props.textMode ? (
          <div className="xgs-trailer-locations__open__text-link blue-link" onClick={() => setShowModal(true)}>
            <XGSIcon
              icon={XGSIcons.faMapMarkedAlt}
              size="sm"
              className="xgs-trailer-locations__open__text-link__icon"
            />
            Show on map
          </div>
        ) : (
          <Button
            theme={ButtonThemes.green}
            size={ButtonSizes.medium}
            type="button"
            className="xgs-trailer-locations__open__button"
            onClick={() => setShowModal(true)}
          >
            Show on map
          </Button>
        )}
      </div>
      <div>
        <XGSPopup
          title="Trailer Location"
          opened={showModal}
          onClose={onClose}
          className="xgs-trailer-locations__popup"
        >
          <div className="xgs-trailer-locations__popup__content">
            <Loading isLoading={trailerLocationState.requestStarted} />
            {trailerLocationState.requestFailed && (
              <XGSErrorMessage className="xgs-trailer-locations__popup__content__error">
                {trailerLocationState.requestError}
              </XGSErrorMessage>
            )}
            {trailerLocationState.requestSucceed && trailerLocationState.result && (
              <div className="xgs-map__container">
                <GoogleMap
                  bootstrapURLKeys={{ key: apiKey }}
                  center={trailerLocation}
                  defaultCenter={trailerLocation}
                  defaultZoom={16}
                  options={(map) => ({
                    mapTypeControlOptions: {
                      style: map.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: map.ControlPosition.TOP_RIGHT,
                      mapTypeIds: [map.MapTypeId.ROADMAP, map.MapTypeId.HYBRID]
                    },
                    mapTypeControl: true
                  })}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                  <Marker lat={trailerLocationState.result.latitude} lng={trailerLocationState.result.longitude} />
                </GoogleMap>
              </div>
            )}
            {trailerLocationState.requestSucceed && !trailerLocationState.result && <span>No location data</span>}
          </div>
        </XGSPopup>
      </div>
    </>
  );
};

export default TrailerLocation;
