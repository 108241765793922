import React, { useState, useEffect, useMemo, memo } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PathParams, TrackShipmentsPath } from "../route";
import { xgsRoutes } from "../../../app/route/RoutesConfig";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import LabeledTextInput from "../../../components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import LabeledMaskInput from "../../../components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import LabeledDateRangeInput from "../../../components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledSelectInput from "../../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../components/button/button";
import Loading from "../../../components/loading/loading";
import ServiceCentersState from "../../../slices/service-centers/ServiceCentersState";
import {
  getTrackShipmentsCSV,
  resetCSVErrors,
  searchShipments,
  searchTeam,
  trackShipmentSelector
} from "../../../slices/track-shipment/trackShipmentSlice";
import TrackShipmentState from "../../../slices/track-shipment/TrackShipmentState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserState } from "../../../slices";
import SearchShipmentRequestModel from "../../../app/data/tracking/SearchShipmentRequestModel";
import { serviceCentersSelector, getServiceCenters } from "../../../slices/service-centers/serviceCentersSlice";
import FiltersToggleState from "../../../slices/filters-toggle/FiltersToggleState";
import { filtersToggleSelector } from "../../../slices/filters-toggle/filtersToggleSlice";
import { shipmentDropdownStatuses } from "../../../app/data/common/shipmentStatuses";
import { UserUtils } from "../../../app/data/user/userUtils";
import { groups } from "./constants";
import "./trackingFilter.scss";
import { useAppDispatch } from "../../../hooks/storeHooks";

const TrackingFilter: React.FC<{ noDownload?: boolean }> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const trackShipmentState: TrackShipmentState = useSelector(trackShipmentSelector);
  const serviceCentersState: ServiceCentersState = useSelector(serviceCentersSelector);
  const filtersToggleState: FiltersToggleState = useSelector(filtersToggleSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams() as unknown as PathParams;
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [probills, setProbills] = useState<string>("");
  const [origin, setOrigin] = useState<XGSSelectOption | null | undefined>();
  const [destination, setDestination] = useState<XGSSelectOption | null | undefined>();
  const [status, setStatus] = useState<XGSSelectOption[] | null | undefined>();
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [team, setTeam] = useState<XGSSelectOption | null | undefined>();
  const [consignee, setConsignee] = useState<string>("");
  const [linehaulManifest, setlinehaulManifest] = useState<string>("");
  const [masterbill, setMasterbill] = useState<string>("");

  const currentAccountNumber: any = useMemo(() => {
    return null;
  }, []);

  const storeSearchInUrl = (request: SearchShipmentRequestModel, requestMixin?: any) => {
    // remove empty properties
    for (const key in request) {
      if (
        !request[key as keyof SearchShipmentRequestModel] ||
        request[key as keyof SearchShipmentRequestModel] === null ||
        request[key as keyof SearchShipmentRequestModel] === undefined
      )
        delete request[key as keyof SearchShipmentRequestModel];
    }
    if (
      request.trackingNumber &&
      (request.trackingNumber.length === 0 || (request.trackingNumber.length === 1 && !request.trackingNumber[0]))
    )
      delete request.trackingNumber;
    if (request.status?.length === 0) delete request.status;
    // prepare query parameters
    const queryObj: any = {
      ...(request.accountNumber && { a: request.accountNumber.toString() }),
      ...(request.consignee && { c: request.consignee }),
      ...(request.destinationId && { d: request.destinationId.toString() }),
      ...(request.from && { f: request.from }),
      ...(request.to && { t: request.to }),
      ...(request.originId && { o: request.originId.toString() }),
      ...(request.status && { s: request.status.join(",") }),
      ...(request.teamId && team?.label && { tm: `${request.teamId},${team.label}` }),
      ...(request.trackingNumber && { tn: request.trackingNumber.join(",") }),
      ...(request.linehaulManifest && { mn: request.linehaulManifest }),
      ...(request.masterbill && { mb : request.masterbill}),
      ...requestMixin
    };
    const queryString = new URLSearchParams(queryObj).toString();
    navigate(window.location.pathname + "?" + queryString);
  };

  const getQueryParam = (name: string) => {
    // not using URLSearchParams because IE11 doesn't support it
    let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    return results ? decodeURI(results[1]) || 0 : null;
  };

  const shipmentGroup = useMemo(() => {
    return params[TrackShipmentsPath.shipmentGroup];
  }, [params]);

  const getSearchRequestModel = () => {
    let statusRequestValue = null;
    let teamRequestValue = null;
    let fromQueryValue = (getQueryParam("f") || "") + "";
    let toQueryValue = (getQueryParam("t") || "") + "";

    const statusStr = getQueryParam("s");
    if (statusStr) {
      const rawStatusesArr = decodeURIComponent(statusStr).split(",");
      let statusesArr: XGSSelectOption[] = [];
      for (let status of rawStatusesArr) {
        const statusObj = shipmentDropdownStatuses.find((o) => o.value === status);
        statusObj && statusesArr.push(statusObj);
      }
      if (statusesArr.length > 0) {
        // setStatus(statusesArr);
        statusRequestValue = statusesArr.map((obj: XGSSelectOption) => obj.value);
      }
    } else {
      statusRequestValue = status && status.length > 0 ? status.map((obj: XGSSelectOption) => obj.value) : null;
    }

    const teamStr = getQueryParam("tm");
    if (teamStr) {
      const rawTeamArr = decodeURIComponent(teamStr).replace(/\+/g, " ").split(",");
      rawTeamArr &&
        setTeam({
          value: rawTeamArr[0],
          label: rawTeamArr[1]
        });
      teamRequestValue = rawTeamArr[0];
    } else {
      teamRequestValue = team && team.value ? team.value : null;
    }

    let probillsArr = getQueryParam("tn")
      ? decodeURIComponent(getQueryParam("tn") || "")
          .split(/(?:,| )+/)
          .map((value) => encodeURIComponent(value))
      : probills.split(/(?:,| )+/).map((value) => encodeURIComponent(value));
    let linehaulManifest = decodeURIComponent(getQueryParam("mn") || "");
    let masterbill = decodeURIComponent(getQueryParam("mb") || "");
    const requestModel: SearchShipmentRequestModel = {
      accountNumber: getQueryParam("a")
        ? Number(getQueryParam("a"))
        : accountNumber
        ? Number(accountNumber)
        : currentAccountNumber
        ? currentAccountNumber
        : undefined,
      ...((consignee || getQueryParam("c")) && {
        consignee: getQueryParam("c")
          ? decodeURIComponent(getQueryParam("c") || "").replace(/\+/g, " ") || ""
          : consignee
      }),
      destinationId: destination
        ? Number(destination.value)
        : getQueryParam("d")
        ? Number(getQueryParam("d"))
        : null,
      ...((fromQueryValue || startDate) && {
        from: fromQueryValue
          ? fromQueryValue.toApiDateFormat()
          : startDate
          ? startDate.toApiDateFormat()
          : undefined
      }),
      lastIds: null,
      originId: origin ? Number(origin.value) : getQueryParam("o") ? Number(getQueryParam("o")) : null,
      shipmentGroup,
      status: statusRequestValue,
      ...(teamRequestValue && { teamId: teamRequestValue }),
      ...((toQueryValue || endDate) && {
        to: toQueryValue ? toQueryValue.toApiDateFormat() : endDate ? endDate.toApiDateFormat() : undefined
      }),
      trackingNumber: probillsArr,
      linehaulManifest: linehaulManifest ? Number(linehaulManifest) : null,
      masterbill: masterbill ? Number(masterbill) : null
    };
    return requestModel;
  };

  const onProbillChanged = (value: string) => {
    setProbills(value);
    const requestModel = getSearchRequestModel();
    requestModel.trackingNumber = value.split(/(?:,| )+/).map((value) => encodeURIComponent(value));
    storeSearchInUrl(requestModel);
  };
  const onManifestNumberChanged = (value: string) => {
    setlinehaulManifest(value);
    const requestModel = getSearchRequestModel();
    requestModel.linehaulManifest = Number(value);
    storeSearchInUrl(requestModel);
  };
  const onMasterbillChanged = (value: string) => {
    setMasterbill(value);
    const requestModel = getSearchRequestModel();
    requestModel.masterbill = Number(value);
    storeSearchInUrl(requestModel);
  };
  const onStartDateChanged = (value: string) => {
    setStartDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.from = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent();
  };

  const onEndDateChanged = (value: string) => {
    setEndDate(value);
    const requestModel = getSearchRequestModel();
    requestModel.to = value?.toApiDateFormat();
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent();
  };

  const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
    setOrigin(_origin);
    const requestModel = getSearchRequestModel();
    requestModel.originId = _origin ? parseInt(_origin.value, 10) : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent();
  };

  const onPayorChanged = (value: string) => {
    setAccountNumber(value);
    const requestModel = getSearchRequestModel();
    requestModel.accountNumber = Number(value);
    storeSearchInUrl(requestModel);
  };

  const onConsigneeChanged = (value: string) => {
    setConsignee(value);
    const requestModel = getSearchRequestModel();
    requestModel.consignee = value;
    storeSearchInUrl(requestModel);
  };

  const onStatusChanged = (status: XGSSelectOption[] | null | undefined) => {
    setStatus(status);
    const requestModel = getSearchRequestModel();
    requestModel.status = status && status.length > 0 ? status.map((obj: XGSSelectOption) => obj.value) : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel);
    trackSearchEvent();
  };

  const onTeamChanged = (_team: XGSSelectOption | null | undefined) => {
    setTimeout(() => setTeam(_team), 50);
    const requestModel = getSearchRequestModel();
    requestModel.teamId = _team ? _team.value : null;
    dispatch(searchShipments(requestModel));
    storeSearchInUrl(requestModel, {
      ...(_team && { tm: `${_team.value},${_team.label}` })
    });
    trackSearchEvent();
  };

  const getOriginsOptions = () => {
    return (
      serviceCentersState.origins?.map(
        (origin): XGSSelectOption => ({
          label: origin.name,
          value: origin.id.toString()
        })
      ) || []
    );
  };

  const getDestinationsOptions = () => {
    return (
      serviceCentersState.destinations?.map(
        (destination): XGSSelectOption => ({
          label: destination.name,
          value: destination.id.toString()
        })
      ) || []
    );
  };

  const getStatusesOptions = () => {
    return (
      shipmentDropdownStatuses.map(
        (status): XGSSelectOption => ({
          label: status.label,
          value: status.value
        })
      ) || []
    );
  };

  const search = () => {
    dispatch(searchShipments(getSearchRequestModel()));
    storeSearchInUrl(getSearchRequestModel());
    trackSearchEvent();
  };

  const clear = () => {
    setAccountNumber("");
    setConsignee("");
    setDestination(null);
    setEndDate(undefined);
    setOrigin(null);
    setProbills("");
    setStartDate(undefined);
    setStatus(null);
    setTeam(null);
    setlinehaulManifest("");
    setMasterbill("");
    dispatch(
      searchShipments({
        ...(currentAccountNumber && { accountNumber: currentAccountNumber }),
        shipmentGroup
      })
    );
    storeSearchInUrl({
      ...(currentAccountNumber && { accountNumber: currentAccountNumber })
    });
  };

  const trackSearchEvent = () => {
    if (UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) return;
    // mixpanel.track("Searched for probills");
  };

  let teamSearch = (value: string) => {
    value?.length > 1 && dispatch(searchTeam(value));
  };
  teamSearch = debounce(teamSearch, 300);

  const additionalFilterName = (group: string) => {
    const groupObj = groups.find((groupObj) => group === groupObj.value);
    return groupObj ? `${groupObj.parentLabel} - ${groupObj.label}` : "";
  };

  const fetchCSV = () => {
    if (trackShipmentState.CSVLink) {
      onExportSuccess(trackShipmentState.CSVLink);
    } else {
      dispatch(getTrackShipmentsCSV(getSearchRequestModel(), onExportSuccess));
    }
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return;
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (window.location.search) {
      dispatch(searchShipments(getSearchRequestModel()));
      trackSearchEvent();
      getQueryParam("tn") && setProbills(decodeURIComponent(getQueryParam("tn") || ""));
      getQueryParam("mn") && setlinehaulManifest(getQueryParam("mn") || "");
      getQueryParam("mb") && setMasterbill(getQueryParam("mb") || "");
      if (getQueryParam("f")) {
        let from = getQueryParam("f")?.toString();
        from && setStartDate(from.toUiDateFormat());
      }
      if (getQueryParam("t")) {
        let to = getQueryParam("t")?.toString();
        to && setEndDate(to.toUiDateFormat());
      }
      if (getQueryParam("s")) {
        const rawStatusesArr = decodeURIComponent(getQueryParam("s") || "").split(",");
        let statusesArr: XGSSelectOption[] = [];
        for (let status of rawStatusesArr) {
          const statusObj = shipmentDropdownStatuses.find((o) => o.value === status);
          statusObj && statusesArr.push(statusObj);
        }
        if (statusesArr.length > 0) {
          setStatus(statusesArr);
        }
      }
      getQueryParam("c") && setConsignee(decodeURIComponent(getQueryParam("c") || "").replace(/\+/g, " "));
      getQueryParam("a") && setAccountNumber(decodeURIComponent(getQueryParam("a") || ""));
    } else {
      dispatch(
        searchShipments({
          ...(currentAccountNumber && { accountNumber: currentAccountNumber }),
          ...((accountNumber || params[TrackShipmentsPath.accountNumber]) && {
            accountNumber: Number(accountNumber) || Number(params[TrackShipmentsPath.accountNumber])
          }),
          shipmentGroup
        })
      );
      storeSearchInUrl({
        ...(currentAccountNumber && { accountNumber: currentAccountNumber })
      });
    }
    // eslint-disable-next-line
  }, [dispatch, currentAccountNumber, shipmentGroup]);

  useEffect(() => {
    if (!serviceCentersState.loaded && !serviceCentersState.loading && !serviceCentersState.loadingFailed)
      dispatch(getServiceCenters());
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    // handle origin and destination in URL after loading service centers list
    if (!serviceCentersState.loaded || !window.location.search) return;
    let originObj = null;
    let destinationObj = null;
    if (getQueryParam("o")) {
      originObj = getOriginsOptions().find((o) => o.value === getQueryParam("o"));
      if (originObj) setOrigin(originObj);
    }
    if (getQueryParam("d")) {
      destinationObj = getDestinationsOptions().find((o) => o.value === getQueryParam("d"));
      if (destinationObj) setDestination(destinationObj);
    }
    if (getQueryParam("o") || getQueryParam("d")) {
      dispatch(
        searchShipments({
          ...getSearchRequestModel(),
          ...(destinationObj && {
            destinationId: parseInt(destinationObj.value, 10)
          }),
          ...(originObj && { originId: parseInt(originObj.value, 10) })
        })
      );
      trackSearchEvent();
    }
    // eslint-disable-next-line
  }, [dispatch, serviceCentersState]);

  useEffect(() => {
    params[TrackShipmentsPath.accountNumber] && setAccountNumber(params[TrackShipmentsPath.accountNumber]);
  }, [params]);

  useEffect(() => {
    if (!trackShipmentState.loadingCSVFailed) return;
    toast.error(trackShipmentState.loadingCSVError || "Error! Please try again later.");
    dispatch(resetCSVErrors());
  }, [trackShipmentState.loadingCSVFailed, trackShipmentState.loadingCSVError, dispatch]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div
        className={`xgs-tracking-filter ${
          filtersToggleState.showFilters
            ? "xgs-filters-toggle__filters--show"
            : "xgs-filters-toggle__filters--hide"
        }`}
      >
        <div>
          <div className="xgs-tracking-filter__controls">
            <div className="xgs-tracking-filter__controls__item">
              <LabeledTextInput
                label="Tracking number:"
                labelMode={LabelModes.column}
                className="xgs-tracking-filter__input"
                value={probills}
                onChange={(e) => onProbillChanged(e.currentTarget.value)}
                onKeyDown={(e) => e.key === "Enter" && search()}
                type="text"
                placeholder="Probill, PO or BOL number(s)"
              />
            </div>
            <div className="xgs-tracking-filter__controls__item">
              <LabeledMaskInput
                allowNegative={false}
                label="Linehaul manifest number:"
                labelMode={LabelModes.column}
                className="xgs-tracking-filter__input"
                value={linehaulManifest}
                onValueChange={(e) => onManifestNumberChanged(e)}
                onKeyDown={(e) => e.key === "Enter" && search()}
                type="number"
                format="######"
                placeholder="6 digits"
                allowEmptyFormatting={false}
              />
            </div>
            <div className="xgs-tracking-filter__controls__item">
              <LabeledMaskInput
                allowNegative={false}
                label="Masterbill number:"
                labelMode={LabelModes.column}
                className="xgs-tracking-filter__input"
                placeholder="Masterbill number"
                value={masterbill}
                onValueChange={(e) => onMasterbillChanged(e)}
                onKeyDown={(e) => e.key === "Enter" && search()}
                type="number"
                format="#########"
                allowEmptyFormatting={false}
              />
            </div>
            <div className="xgs-tracking-filter__controls__item">
              <LabeledDateRangeInput
                label="Date range:"
                labelMode={LabelModes.column}
                className="xgs-tracking-filter__input"
                start={startDate}
                end={endDate}
                onStartChange={onStartDateChanged}
                onEndChange={onEndDateChanged}
              />
            </div>
            <div className="xgs-tracking-filter__controls__double-item">
              <div className="xgs-tracking-filter__controls__double-item__unit">
                <LabeledSelectInput
                  onValueChange={onOriginChanged}
                  value={origin}
                  options={getOriginsOptions()}
                  label="Origin:"
                  labelMode={LabelModes.column}
                  className="xgs-tracking-filter__input"
                  isClearable
                />
              </div>
              {/* <div className="xgs-tracking-filter__controls__double-item__unit">
                <LabeledSelectInput
                  onValueChange={onDestinationChanged}
                  value={destination}
                  options={getDestinationsOptions()}
                  label="Destination:"
                  labelMode={LabelModes.column}
                  className="xgs-tracking-filter__input"
                  isClearable
                />
              </div> */}
              <LabeledSelectInput
                onMultiValuesChange={onStatusChanged}
                value={status}
                options={getStatusesOptions()}
                label="Status:"
                labelMode={LabelModes.column}
                className="xgs-tracking-filter__input xgs-tracking-filter__input--multi"
                isMulti
                isClearable
                disabled={trackShipmentState.loading}
              />
            </div>
            <div className="xgs-tracking-filter__controls__item"></div>
            {(UserUtils.isXGSUser(userState.profile) || UserUtils.isXGSAdministrator(userState.profile)) && (
              <>
                <div className="xgs-tracking-filter__controls__item">
                  <LabeledMaskInput
                    allowNegative={false}
                    label="Payor's account number:"
                    labelMode={LabelModes.column}
                    className="xgs-tracking-filter__input"
                    onValueChange={(value) => onPayorChanged(value)}
                    onKeyDown={(e) => e.key === "Enter" && search()}
                    value={accountNumber}
                    format="########"
                  />
                </div>
                <div className="xgs-tracking-filter__controls__item">
                  <LabeledTextInput
                    type="text"
                    label="Consignee:"
                    labelMode={LabelModes.column}
                    className="xgs-tracking-filter__input"
                    onChange={(e) => onConsigneeChanged(e.currentTarget.value)}
                    onKeyDown={(e) => e.key === "Enter" && search()}
                    placeholder="Consignee name or address"
                    value={consignee}
                  />
                </div>
                <div className="xgs-tracking-filter__controls__item">
                  <LabeledSelectInput
                    className="xgs-tracking-filter__input"
                    isDisabled={false}
                    isLoading={trackShipmentState.searchTeamStarted}
                    label="Team:"
                    labelMode={LabelModes.column}
                    onInputChange={teamSearch}
                    onValueChange={onTeamChanged}
                    openMenuOnClick={trackShipmentState.teams?.length > 0}
                    options={trackShipmentState.teams}
                    placeholder="Enter team name..."
                    value={team}
                    isClearable
                  />
                </div>
              </>
            )}
          </div>
          <div className="xgs-tracking-filter__bottom-row">
            <div className="xgs-tracking-filter__buttons">
              <Button theme={ButtonThemes.blue} onClick={search}>
                Search
              </Button>
              <Button theme={ButtonThemes.gray} onClick={clear}>
                Clear Filters
              </Button>
            </div>
            {(startDate ||
              endDate ||
              probills ||
              origin ||
              destination ||
              status ||
              (accountNumber &&
                (currentAccountNumber || !shipmentGroup) &&
                Number(accountNumber) !== currentAccountNumber) ||
              team ||
              consignee) && (
              <div className="xgs-tracking-filter__base-filters">
                <XGSIcon
                  icon={XGSIcons.faExclamationCircle}
                  size="sm"
                  className="xgs-tracking-filter__base-filters__icon"
                />
                <div className="xgs-tracking-filter__base-filters__text">Filters applied to results below.</div>
              </div>
            )}
          </div>
          {shipmentGroup && (
            <div className="xgs-tracking-filter__additional-group">
              Additional filter applied:{" "}
              <span className="xgs-tracking-filter__additional-group__name">
                {additionalFilterName(shipmentGroup)}
              </span>
              &nbsp; (
              <NavLink to={xgsRoutes.shipments.tracking} className="blue-link">
                disable&nbsp;filters&nbsp;and&nbsp;view&nbsp;all&nbsp;shipments
                <span className="xgs-tracking-filter__additional-group__link-desktop-part">&nbsp;instead</span>
              </NavLink>
              )
            </div>
          )}
        </div>
      </div>
      {!props.noDownload && (
        <div
          className="xgs-tracking-filter__download-csv xgs-site__content-container__toolbar__buttons__item"
          onClick={() => {
            !trackShipmentState.loadingCSVStarted && fetchCSV();
          }}
        >
          {!trackShipmentState.loadingCSVStarted && (
            <XGSIcon
              icon={XGSIcons.faFileDownload}
              size="lg"
              className="xgs-site__content-container__toolbar__buttons__item__icon"
            />
          )}
          {trackShipmentState.loadingCSVStarted && (
            <Loading
              isLoading={trackShipmentState.loadingCSVStarted}
              className="xgs-site__content-container__toolbar__buttons__item__icon xgs-tracking-filter__download-csv__spinner"
            />
          )}
          <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">
            Download CSV
          </span>
        </div>
      )}
    </>
  );
});

export default TrackingFilter;
