import React, { useEffect, useState } from "react";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import ContentContainer from "../../templates/content-container/contentContainer";
import "./addFreightException.scss";
import { useAppDispatch } from "../../hooks/storeHooks";
import { freightExceptionSelector, getProbillDetails } from "../../slices/freight-exception/freightExceptionSlice";
import { debounce } from "lodash";
import LabeledSelectInput from "../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useSelector } from "react-redux";
import { RequestCreators } from "../../slices/freight-exception/freightExceptionState";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";
import { AddFreightExceptions } from "./addFreightExceptions";
import Table from "../../components/table/table";
import { getRecentExceptionsColumns } from "./recentExceptionsColumn";
import {
  getFreightStatusDetails,
  resetFreightStatusState
} from "../../slices/exact-freight/freightStatusSlice";
import {
  resetSearchFreightExceptionsState,
  searchFreightExceptions,
  searchFreightExceptionsSelector
} from "../../slices/freight-exception/searchFreightExceptionsSlice";
import { getShipmentDetails, resetShipmentDetails } from "../../slices/shipment-details/shipmentDetailsSlice";
import { FreightException } from "../../app/data/freight-exception/models";

interface XGSSelectOptionExt extends Omit<XGSSelectOption, "value"> {
  value: any;
}

export const SelectFreightException = () => {
  const [probillNumber, setProbillNumber] = useState<XGSSelectOptionExt | null>();
  const freightExceptionState = useSelector(freightExceptionSelector);
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const searchExceptionsState = useSelector(searchFreightExceptionsSelector);

  let probillDataFetch = (type: string, query: string) => {
    if (query?.length < 3) return;
    if (type === "PROBILL" && !/^\d{1,48}$/.test(query)) return;
    dispatch(getProbillDetails(query));
  };
  probillDataFetch = debounce(probillDataFetch, 700);

  const onSelectProbillFromSearch = (v?: XGSSelectOptionExt | null) => {
    setHighlightedRow(null);
    setProbillNumber(v);
  };

  useEffect(() => {
    dispatch(searchFreightExceptions(searchExceptionsState.request));
    return () => {
      dispatch(resetSearchFreightExceptionsState());
    };
  }, [dispatch, searchExceptionsState.needsToReload, searchExceptionsState.request]);

  useEffect(() => {
    if (probillNumber?.value) {
      dispatch(getFreightStatusDetails(probillNumber?.value.probill));
      dispatch(getShipmentDetails(probillNumber?.value.probill))
    return () => {
        dispatch(resetFreightStatusState());
        dispatch(resetShipmentDetails())
      };
    }
  }, [dispatch, probillNumber, searchExceptionsState.request]);

  const onSelectProbillFromTable = (probillNumber: number, idx: number) => {
    setHighlightedRow(idx);
    var selectOption: XGSSelectOptionExt = {
      label: probillNumber.toString(),
      value: {
        probill: probillNumber 
      }
    }
    setProbillNumber(selectOption);
  }

  return (
    <>
      <ContentContainer title="Add Freight Exceptions">
        <div className="select-freight-exception__select-form">
          <LabeledSelectInput
            isDisabled={false}
            isLoading={
              freightExceptionState.requestStarted &&
              freightExceptionState.requestCreator === RequestCreators.probillSuggestions
            }
            label="Probill #:"
            labelMode={LabelModes.column}
            autoFocus
            name="probillNumber"
            onInputChange={(query) => probillDataFetch("PROBILL", query)}
            onValueChange={(v) => onSelectProbillFromSearch(v)}
            openMenuOnClick={freightExceptionState.probillSuggestions?.length > 0}
            options={freightExceptionState.probillSuggestions}
            placeholder="Start typing..."
            required={true}
            requiredAsteriskDisabled={false}
            value={probillNumber}
          />
        </div>
      </ContentContainer>
      <div className="select-freight-exception__recent">
        <div className="select-freight-exception__recent__heading">Recent Exceptions</div>
        <Table
          isLoading={searchExceptionsState.loading}
          data={searchExceptionsState.exceptions}
          columns={getRecentExceptionsColumns()}
          infiniteScroll={true}
          infiniteScrollHasNext={false}
          rowHeight={82}
          minTableHeight={420}
          onRowClicked={(exception: FreightException, idx: number) => onSelectProbillFromTable(exception.probillNumber, idx)}
          highlightRow={highlightedRow}
        />
      </div>
      <AddFreightExceptions invoiceNumber={Number(probillNumber?.label)} onClose={() => setProbillNumber(null)} show={!!probillNumber}/>
    </>
  );
};
