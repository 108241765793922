import React, { HTMLProps, memo } from "react";
import { FieldInputProps } from "formik";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import XGSIcon from "../icon/xgsIcon";
import XGSRegularIcons from "../icon/xgsRegularIcons";
import "./xgsInput.scss";

export interface XGSInputBaseProps {
  fields?: FieldInputProps<any>;
  clearable?: boolean;
  onClear?: () => void;
  isFailed?: () => boolean;
  appendIcon?: IconProp;
  appendIconExtraClassName?: string;
  appendAction?: () => void;
}

export interface XGSInputProps extends XGSInputBaseProps, HTMLProps<HTMLInputElement> {}

const XGSInput: React.FC<XGSInputProps> = memo(
  ({ fields, isFailed, clearable, onClear, appendIcon, appendIconExtraClassName, appendAction, ...props }) => {
    return (
      <div className="xgs-input">
        <input
          {...fields}
          {...props}
          className={`xgs-input__field ${isFailed && isFailed() ? "not-valid" : ""} ${
            !clearable && !appendIcon ? "" : "xgs-input__field--w-icon"
          }`}
        />
        {clearable && props.value && (
          <XGSIcon icon={XGSRegularIcons.faTimesCircle} className="xgs-input__append-button" onClick={onClear} />
        )}
        {!clearable && appendIcon && (
          <XGSIcon
            icon={appendIcon}
            className={`xgs-input__append-button ${appendIconExtraClassName ? appendIconExtraClassName : ""}`}
            onClick={() => {
              appendAction && appendAction();
            }}
          />
        )}
      </div>
    );
  }
);

export default XGSInput;
