import React, { useEffect, useCallback, memo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LabeledDateRangeInput from "../molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledSelectInput from "../molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../button/button";
import { useNavigate } from "react-router";
import "./filter.scss";
import { LabelModes } from "../molecules/labeled-inputs/labeledInput";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";
import XGSDate from "../xgs-date/xgs-date/xgsDate";
import LabeledMaskInput from "../molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";

export type FilterConfig = {
  key: string;
  label: string;
  type: FilterTypes;
  triggerSearch?: boolean;
  options?: Array<{ value: string; label: string }>;
  placeholderText?: string;
};

export enum FilterTypes {
  text = "text",
  number = "number",
  dateRange = "date_range",
  select = "select",
  multi_select = "multi_select",
  date = "date",
  mask = "mask"
}

type TrackingFilterProps = {
  filtersConfig: FilterConfig[];
  sameRowButtons?: boolean;
  onSearch: (filters: any) => void;
  onClear?: () => void;
};

const XGSFilter: React.FC<TrackingFilterProps> = memo(
  ({ filtersConfig, onSearch, onClear, ...props }) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const getFiltersFromURL = useCallback(() => {
      const queryParams = queryString.parse(location.search);

      return filtersConfig.reduce((acc: { [key: string]: string }, filter) => {
        if (queryParams[filter.key]) {
          acc[filter.key] = queryParams[filter.key] as string;
        }
        return acc;
      }, {});
    }, [location.search, filtersConfig]);

    useEffect(() => {
      const filters = getFiltersFromURL();
      onSearch(filters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch]);

    const handleFilterChange = (key: string, value: any) => {
      const filters = getFiltersFromURL();
      filters[key] = value;
      const config = filtersConfig.find((config) => config.key === key);
      Object.keys(filters).forEach(key => {
        if (!filters[key]) delete filters[key]
        if (filters[key] === ":") delete filters[key]
       })
      navigate({ search: queryString.stringify(filters) });
      if (config?.triggerSearch) {
        onSearch(filters);
      }
    };

    const handleSearch = () => {
      const filters = getFiltersFromURL();
      onSearch(filters);
    };

    const handleClear = () => {
      navigate({ search: "" });
      onClear && onClear();
    };

    return (
      <div className="xgs-filter">
        <div className="xgs-filter__controls">
          {filtersConfig.map((filter, index) => {
            const value = getFiltersFromURL()[filter.key] || "";
            switch (filter.type) {
              case "text":
              case "number":
                return (
                  <div key={index} className="xgs-filter__controls__item">
                    <LabeledMaskInput
                      allowNegative={false}
                      format="########"
                      allowEmptyFormatting={false}
                      onValueChange={(value) => handleFilterChange(filter.key, value)}
                      labelMode={LabelModes.column}
                      placeholder={filter.placeholderText}
                      key={index}
                      label={filter.label}
                      type={filter.type}
                      value={value}
                      onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                    />
                  </div>
                );
              case "date":
                return (
                  <div key={index} className="xgs-filter__controls__item xgs-filter__controls__date">
                    <label>{filter.label}</label>
                    <XGSDate
                      placeholderText={filter.placeholderText}
                      key={index}
                      value={value}
                      onDateChange={(v) => handleFilterChange(filter.key, v)}
                      onChange={() => null}
                    />
                  </div>
                )
              case "date_range":
                // assuming date range value will be in format 'startDate:endDate'
                const [start, end] = value.split(":");
                return (
                  <div key={index} className="xgs-filter__controls__item">
                    <LabeledDateRangeInput
                      labelMode={LabelModes.column}
                      key={index}
                      label={filter.label}
                      start={start}
                      end={end}
                      onStartChange={(startDate) => handleFilterChange(filter.key, `${startDate || ""}:${end || ""}`)}
                      onEndChange={(endDate) => handleFilterChange(filter.key, `${start || ""}:${endDate || ""}`)}
                    />
                  </div>
                );
              case "select":
                return (
                  <div key={index} className="xgs-filter__controls__item">
                    <LabeledSelectInput
                      labelMode={LabelModes.column}
                      key={index}
                      label={filter.label}
                      value={filter.options?.find((option) => option.value === value)}
                      options={filter.options || []}
                      onValueChange={(val) => handleFilterChange(filter.key, val?.value)}
                      isClearable
                    />
                  </div>
                );
              case "multi_select":
                const values = value.split(",");
                return (
                  <div key={index} className="xgs-filter__controls__item">
                    <LabeledSelectInput
                      labelMode={LabelModes.column}
                      key={index}
                      label={filter.label}
                      value={filter.options?.filter((option) => values.includes(option.value))}
                      options={filter.options || []}
                      isMulti
                      onMultiValuesChange={(options) =>
                        handleFilterChange(filter.key, options?.map(({ value }) => value).join(","))
                      }
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
          {props.sameRowButtons && <div className="xgs-filter__buttons xgs-filter__buttons--small xgs-filter__controls__item">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.blue} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>}
        </div>
        {!props.sameRowButtons && <div className="xgs-filter__bottom-row">
          <div className="xgs-filter__buttons">
            <Button theme={ButtonThemes.blue} onClick={handleSearch}>
              Search
            </Button>
            <Button theme={ButtonThemes.blue} onClick={handleClear}>
              Clear Filters
            </Button>
          </div>
          {Object.keys(getFiltersFromURL()).length > 0 && (
            <div className="xgs-filter__base-filters">
              <XGSIcon
                icon={XGSIcons.faExclamationCircle}
                size="sm"
                className="xgs-tracking-filter__base-filters__icon"
              />
              <div className="xgs-tracking-filter__base-filters__text">Filters applied to results below.</div>
            </div>
          )}
        </div>}
      </div>
    );
  }
);

export default XGSFilter;
