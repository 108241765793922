/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import DropdownPopup from "../dropdown-popup/dropdownPopup";
import XGSIcon from "../icon/xgsIcon";
import "./dropdownMenu.scss";

export interface DropdownMenuItem {
  text: string;
  to?: string;
  onClick?: (params?: any | undefined) => void;
  icon?: IconProp;
}

export interface DropdownMenuProps {
  menuItems: DropdownMenuItem[];
  opened: boolean;
  onClose: () => void;
}

const MenuItemWrapper: React.FC<any> = ({ to, children }) => (
  to
  ? <Link to={to}>{children}</Link>
  : children
);

const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  return (
    <DropdownPopup
      opened={props.opened}
      onClose={props.onClose}
      className="xgs-dropdown-menu"
      position="bottom right"
    >
      {props.menuItems.map((menuItem) => (
        <React.Fragment key={`xgs-dropdown-menu-${menuItem.text}`}>
          <MenuItemWrapper to={menuItem.to}>
            <div                
              className="xgs-dropdown-menu__item"
              onClick={menuItem.onClick}
            >
              <div className="xgs-dropdown-menu__icon">
                {menuItem.icon && <XGSIcon icon={menuItem.icon} />} 
              </div>
              {menuItem.text}
            </div>          
          </MenuItemWrapper>
        </React.Fragment>
      ))}
    </DropdownPopup>
  );
};

export default DropdownMenu;
