import api, { ApiResponse } from "../api";
import axios from "axios";
import ShipmentModel from "./ShipmentModel";
import { InfiniteScrollCollectionResponse } from "../common/models";
import ShipmentDetailsModel from "./ShipmentDetailsModel";
import ShipmentTripHistoryModel from "./ShipmentTripHistoryModel";
import ServiceCentersModel from "./ServiceCentersModel";
import SearchShipmentRequestModel from "./SearchShipmentRequestModel";

class TrackingService {
  private source: any;
  private static instance: TrackingService;

  private constructor() {}

  static getInstance(): TrackingService {
    if (!TrackingService.instance) {
      TrackingService.instance = new TrackingService();
    }

    return TrackingService.instance;
  }

  public getServiceCenters = async (): Promise<ApiResponse<ServiceCentersModel>> => {
    return await api.get("/service-centers");
  };

  public searchShipments = async (
    rawRequestModel: SearchShipmentRequestModel
  ): Promise<ApiResponse<InfiniteScrollCollectionResponse<ShipmentModel>>> => {
    let requestModel = (({ lastIds, ...rest }) => rest)(rawRequestModel);
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.post(
      `/invoice-search${rawRequestModel.lastIds ? "?lastIds=" + rawRequestModel.lastIds : ""}`,
      requestModel,
      {
        cancelToken: this.source.token
      }
    );
  };

  public searchTeams = async (value: string): Promise<ApiResponse<any>> => {
    return await api.get(`/teams/search?name=${encodeURIComponent(value)}`);
  };

  public getShipmentDetails = async (invoiceNumber: number): Promise<ApiResponse<ShipmentDetailsModel>> => {
    return await api.get(`/invoices/${invoiceNumber}`);
  };

  public getDockLog = async (invoiceNumber: number): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/dock-log`);
  };

  public getStatusHistory = async (invoiceNumber: number): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/status-history`);
  };

  public getShipmentTripHistory = async (
    invoiceNumber: number
  ): Promise<ApiResponse<ShipmentTripHistoryModel>> => {
    return await api.get(`/invoices/${invoiceNumber}/tripHistory`);
  };

  public getTrailerLocation = async (invoiceNumber: number): Promise<ApiResponse<any>> => {
    return await api.get(`/invoices/${invoiceNumber}/location`);
  };

  public downloadCSV = async (rawRequestModel: SearchShipmentRequestModel): Promise<ApiResponse<any>> => {
    let requestModel = (({ lastIds, ...rest }) => rest)(rawRequestModel);
    return await api.post("/invoice-search/download", requestModel);
  };

  public subscribeProbill = async (probill: number, timezone: string): Promise<ApiResponse<any>> => {
    return await api.post("/notifications/probill", { timezone, probill });
  };

  public unsubscribeProbill = async (probill: number): Promise<ApiResponse<any>> => {
    return await api.delete("/notifications/probill", { data: { probill } });
  };

  public addNote = async (probill: number, note: string): Promise<ApiResponse<any>> => {
    return await api.post(`/invoices/${probill}/notes`, { note: note });
  };
}

export default TrackingService;
