import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "../../../components/table/table";
import { getStatusHistory, shipmentDetailsSelector } from "../../../slices/shipment-details/shipmentDetailsSlice";
import ShipmentDetailsState from "../../../slices/shipment-details/ShipmentDetailsState";
import Loading from "../../../components/loading/loading";
import { useAppDispatch } from "../../../hooks/storeHooks";
export interface ShipmentStatusHistoryProps {
  invoiceNumber: number;
}

const ShipmentStatusHistory: React.FC<ShipmentStatusHistoryProps> = (props) => {
  const dispatch = useAppDispatch();
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);

  const columns = [
    {
      Header: <div className="text-center">Date</div>,
      id: "date",
      accessor: "date",
      width: 95,
      Cell: (cellProps: any) => (
        <div className="text-center">{cellProps.value ? cellProps.value.toUiDateFormat() : ""}</div>
      )
    },
    {
      Header: <div className="text-center">Time</div>,
      id: "time",
      accessor: "date",
      width: 75,
      Cell: (cellProps: any) => (
        <div className="text-center">{cellProps.value ? cellProps.value.split("T")[1] : ""}</div>
      )
    },
    {
      Header: "Status",
      accessor: "title",
      width: 110
    },
    {
      Header: "Description",
      accessor: "translate",
      width: 140
    },
    {
      Header: "Manifest Number",
      accessor: "manifestNumber",
      width: 140
    },
    {
      Header: "Manifest Type",
      accessor: "manifestType",
      width: 120,
      Cell: (cellProps: any) => (
        <>{cellProps.value === "D" ? "Delivery" : cellProps.value === "L" ? "Linehaul" : cellProps.value}</>
      )
    },
    {
      Header: "User",
      accessor: "user",
      width: 120
    }
  ];

  const calcMinTableHeight = () => {
    if (!shipmentDetailsState.statusHistory) return;
    if (shipmentDetailsState.statusHistory.length === 2) return 90;
    if (shipmentDetailsState.statusHistory.length === 3) return 135;
    if (shipmentDetailsState.statusHistory.length === 4) return 180;
    if (shipmentDetailsState.statusHistory.length === 5) return 225;
    return shipmentDetailsState.statusHistory.length > 5 ? 250 : 80;
  };

  useEffect(() => {
    if (!props.invoiceNumber || shipmentDetailsState.statusHistory) return;
    dispatch(getStatusHistory(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber, shipmentDetailsState.statusHistory]);

  return (
    <>
      {shipmentDetailsState.statusHistoryLoading && (
        <div className="xgs-invoice-documents__loading">
          <Loading isLoading={true} />
        </div>
      )}
      {shipmentDetailsState.statusHistory &&
        shipmentDetailsState.statusHistory.length > 0 &&
        !shipmentDetailsState.statusHistoryLoading && (
          <div className="xgs-shipment-details__status-history">
            <Table
              columns={columns}
              data={shipmentDetailsState.statusHistory}
              infiniteScroll={true}
              infiniteScrollLoading={false}
              infiniteScrollHasNext={false}
              onInfiniteScroll={() => {}}
              rowHeight={45}
              minTableHeight={calcMinTableHeight()}
              strictMinTableHeight={true}
            />
          </div>
        )}
      {(!shipmentDetailsState.statusHistory || shipmentDetailsState.statusHistory.length === 0) &&
        shipmentDetailsState.statusHistoryLoaded && (
          <div className="xgs-shipment-details__tabs__no-data">There is no status history.</div>
        )}
      {shipmentDetailsState.statusHistoryLoadingFailed && (
        <div className="xgs-shipment-details__tabs__error">
          {shipmentDetailsState.statusHistoryLoadingError || "Error while loading status history."}
        </div>
      )}
    </>
  );
};

export default ShipmentStatusHistory;
