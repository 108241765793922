import { BaseState } from "..";
import { FreightException, FreightExceptionSearchRequest } from "../../app/data/freight-exception/models";

export interface SearchFreightExceptionsState extends BaseState {
  scroll?: string;
  exceptions: FreightException[];
  request: FreightExceptionSearchRequest;
  needsToReload: boolean;
}

export const initialSearchFreightExceptionsState: SearchFreightExceptionsState = {
  exceptions: [],
  loading: false,
  loadingFailed: false,
  loadingError: "",
  loaded: false,
  needsToReload: false,
  request: {}
};
