import React from "react";
import { useField, FieldInputProps } from "formik";
import { Options } from "react-select";
import { GroupedOption, XGSSelectOption, XGSSelectProps } from "../../xgs-select/xgsSelect";
import LabeledSelectInput from "../../molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps
} from "../../molecules/labeled-inputs/labeledInput";
import "./xgsFormInput.scss";

export interface XGSFormSelectProps
  extends CommonLabeledInputElementProps,
    CommonLabeledInputProps,
    XGSSelectProps {
  options: ReadonlyArray<any> | Options<XGSSelectOption> | GroupedOption[];
}

const XGSFormSelect: React.FC<XGSFormSelectProps> = ({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  options,
  ...props
}) => {
  const [field, meta] = useField(props as unknown as FieldInputProps<string>);

  return (
    <LabeledSelectInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      fields={field}
      meta={meta}
      options={options}
      {...props}
    />
  );
};

export default XGSFormSelect;
