export enum UserStatuses {
  ACTIVE = "ACTIVE",
  APPROVED = "APPROVED",
  DISABLED = "DISABLED",
  INVITED = "INVITED",
  SIGNED_UP = "SIGNED_UP",
  TRANSFERRED = "TRANSFERRED",
  USER = "User",
  XGS_USER = "XGS User",
  ADMINISTRATOR = "Administrator",
  XGS_ADMINISTRATOR = "XGS Administrator",
  AGENT = "Agent"
}
