import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

interface MarkerProps {
  lat: number | undefined;
  lng: number | undefined;
}

const Marker: React.FC<MarkerProps> = () => <div className="xgs-map__marker"><FontAwesomeIcon icon={Icons.faTruck} size="3x" color="#23356d" /></div>;

export default Marker;
