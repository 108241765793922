import { createSlice } from "@reduxjs/toolkit";
import { initialFreightAppointmentState } from "./freightAppointmentState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { ExactFreightService } from "../../app/data/exact-freight/exactFreightService";
import { UpdateFreightAppointmentRequest } from "../../app/data/exact-freight/models";
import CommonService from "../../app/data/common/commonService";

const exactFreightService = ExactFreightService.getInstance();

export const freightAppointmentSlice = createSlice({
  name: "freightAppointment",
  initialState: initialFreightAppointmentState,
  reducers: {
    resetFreightAppointmentState: (state) => initialFreightAppointmentState,
    setProbillSuggestions: (state, { payload }) => {
      let suggestions = [];
      state.searchingProbills = false;
      state.searchProbillsFailed = false;
      for (const item of payload) {
        suggestions.push({
          label: item.probill,
          value: item
        });
      }
      state.probillSuggestions = suggestions;
    },
    searchProbillsStarted: (state) => {
      state.searchingProbills = true;
      state.searchProbillsFailed = false;
    },
    searchProbillsFailed: (state, { payload }) => {
      state.searchingProbills = false;
      state.searchProbillsFailed = true;
      state.searchProbillsError = payload;
    },
    startUpdating: (state) => {
      state.updating = true;
      state.updated = false;
      state.updateError = "";
      state.updateFailed = false;
    },
    updated: (state) => {
      state.updating = false;
      state.updated = true;
    },
    updateFailed: (state, { payload }) => {
      state.updating = false;
      state.updateFailed = true;
      state.updateError = payload;
    }
  }
});

export const {
  startUpdating,
  updateFailed,
  updated,
  resetFreightAppointmentState,
  searchProbillsFailed,
  searchProbillsStarted,
  setProbillSuggestions
} = freightAppointmentSlice.actions;

export const freightAppointmentSelector = (state: IState) => state.freightAppointment;

export const updateFreightAppointment =
  (
    request: UpdateFreightAppointmentRequest, 
    onSuccess: () => void,
    onFailed: (errorMessage: string | undefined) => void
    ): AppThunk =>
  async (dispatch) => {
    dispatch(startUpdating());
    const response = await exactFreightService.updateFreightAppointment(request);
    if (response.ok()) {
      dispatch(updated());
      onSuccess();
    } else {
      dispatch(updateFailed(response.getError()));
      response.getError && onFailed(response.getError()?.toString())
    }
  };

export const getProbillDetails =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(searchProbillsStarted());
    const response = await CommonService.getInstance().getProbillDetails(search, "PROBILL");
    if (response.ok()) {
      dispatch(setProbillSuggestions(response.data));
    } else {
      dispatch(searchProbillsFailed(response.getError ? response.getError() : "Error"));
    }
  };

const freightAppointmentReducer = freightAppointmentSlice.reducer;
export default freightAppointmentReducer;
