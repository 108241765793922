import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import LabeledDateInput from "../../components/form/date/xgsFormDate";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import moment from "moment";
import XGSFormSelect from "../../components/form/select/xgsFormSelect";
import "./changeAppointment.scss";
import XGSFormInput from "../../components/form/input/xgsFormInput";
import Button, { ButtonThemes } from "../../components/button/button";
import {
  freightAppointmentSelector,
  updateFreightAppointment
} from "../../slices/exact-freight/freightAppointmentSlice";
import {
  UpdateFreightAppointmentRequest,
  UpdateFreightAppointmentSchema,
  getAppointmentChangeReasons
} from "../../app/data/exact-freight/models";
import InfoBlock from "../../templates/info-block/infoBlock";
import LabledValue from "../../components/molecules/labled-value/labledValue";
import InfoGrid from "../../templates/info-grid/infoGrid";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";
import { toast } from "react-toastify";
import { reloadFreightStatus, freightStatusSelector } from "../../slices/exact-freight/freightStatusSlice";
import { reloadShipmentDetails, shipmentDetailsSelector } from "../../slices/shipment-details/shipmentDetailsSlice";
import SlideOutSidebar from "../../components/slide-out-sidebar/slideOutSidebar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getAppointmentHistoryColumns } from "./getAppointmentHistoryColumns";
import Table from "../../components/table/table";

interface Props {
  invoiceNumber: number;
  show: boolean;
  onClose: () => void;
}

const timeOptions = () => {
  let options: XGSSelectOption[] = [];
  let startTime = moment().startOf("day").add(6, "hours");
  for (let i = 1; i < 30; i++) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: startTime.format("HH:mm")
    });
    startTime = startTime.add(30, "minutes");
  }
  return options;
};

export const ChangeAppointment: React.FC<Props> = ({ invoiceNumber, ...props }) => {
  const formRef = useRef<FormikProps<UpdateFreightAppointmentRequest>>(null);
  const freightDetailsState = useSelector(freightStatusSelector);
  const shipmentDetailsState = useSelector(shipmentDetailsSelector);
  const freightAppointmentState = useSelector(freightAppointmentSelector);
  const [earlyTime, setEarlyTime] = useState<XGSSelectOption | null>();
  const [lateTime, setLateTime] = useState<XGSSelectOption | null>();
  const [reason, setReason] = useState<XGSSelectOption | null>();
  const initialUpdateAppointmentState: UpdateFreightAppointmentRequest = {
    appointmentName: "",
    earlyTime: "",
    lateTime: "",
    appointmentDate: "",
    reason: "",
    eta: freightDetailsState.details?.expectedEta || freightDetailsState.details?.originalEta,
    probillNumber: invoiceNumber
  };
  const dispatch = useAppDispatch();

  const resetForm = () => {
    formRef.current?.resetForm();
    setEarlyTime(null);
    setLateTime(null);
    setReason(null);
  };

  useEffect(() => {
    if (!props.show) resetForm();
  }, [props.show])

  const onUpdate = (vals: UpdateFreightAppointmentRequest) => {
    dispatch(
      updateFreightAppointment(
        {
          probillNumber: invoiceNumber,
          lateTime: vals.lateTime,
          earlyTime: vals.earlyTime,
          reason: vals.reason,
          appointmentName: vals.appointmentName,
          appointmentDate: vals.appointmentDate.toApiDateFormat(),
          eta: freightDetailsState.details?.expectedEta
        },
        () => {
          toast.info("Updated appointment!");
          resetForm();
          dispatch(reloadFreightStatus());
          dispatch(reloadShipmentDetails());
          props.onClose && props.onClose();
        },
        (err: string | undefined | null) => {
          toast.error(err || "Something went wrong");
        }
      )
    );
  };

  return (
    <SlideOutSidebar spinner={freightDetailsState.loading || shipmentDetailsState.loading} onClose={props.onClose} show={!!props.show} header={"Change Appointment: " + invoiceNumber}>
      {freightDetailsState.details !== null && (
        <>
          <InfoGrid>
            <InfoBlock title="Details">
              <LabledValue
                label="Consignee:"
                labelFixWidth
                value={freightDetailsState.details?.appointment.consigneeName}
              />
              <LabledValue
                label="Shipper:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.shipperName}
              />
              <LabledValue
                label="Invoice Number:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.probillNumber}
              />
              <LabledValue
                label="PO Number:"
                labelFixWidth
                value={shipmentDetailsState.shipment?.invoice.poNumber}
              />
              <LabledValue
                label="Original ETA:"
                labelFixWidth
                value={freightDetailsState.details?.originalEta?.toUiDateFormat()}
              />
              <LabledValue
                label="Expected ETA:"
                labelFixWidth
                value={freightDetailsState.details?.expectedEta?.toUiDateFormat()}
              />
            </InfoBlock>
            <InfoBlock title="Current appointment">
              <LabledValue
                label="Confirmed Date:"
                labelFixWidth
                value={freightDetailsState.details?.appointment.confirmedDate?.toUiDateFormat()}
              />
              <LabledValue
                label="Confirmed Time:"
                labelFixWidth
                value={freightDetailsState.details?.appointment.confirmedTime?.toUiTimeFormat()}
              />
              <LabledValue
                label="Allow Early Delivery:"
                labelFixWidth
                value={freightDetailsState.details?.appointment.allowEarlyDelivery ? "Yes" : "No"}
              />
              <LabledValue
                label="Appointment Required:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.appointmentRequired ? "Yes" : "No"}
              />
              <LabledValue
                label="Confirmed By User:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.confirmedByUser}
              />
              <LabledValue
                label="Early time:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.earlyTime?.toUiTimeFormat()}
              />
              <LabledValue
                label="Late time:"
                labelFixWidth
                value={freightDetailsState?.details?.appointment.lateTime?.toUiTimeFormat()}
              />
            </InfoBlock>
          </InfoGrid>
          <Tabs onSelect={() => resetForm()}>
            <TabList>
              <Tab>Change</Tab>
              <Tab>History&nbsp;
                {freightDetailsState.details?.appointmentHistory.length ? `(${freightDetailsState.details?.appointmentHistory.length})`:"(0)"}
              </Tab>
            </TabList>
            <TabPanel>
              
              <Formik
                innerRef={formRef}
                validationSchema={UpdateFreightAppointmentSchema}
                onSubmit={onUpdate}
                initialValues={initialUpdateAppointmentState}
              >
                {(props: FormikProps<UpdateFreightAppointmentRequest>) => (
                  <Form>
                    <div className="change-appointment__form">
                      <div className="change-appointment__form__field">
                        <LabeledDateInput
                          name="appointmentDate"
                          label="Date:"
                          onDateChange={(v) => props.setFieldValue(`appointmentDate`, v)}
                          onChange={() => null}
                          minDate={new Date(moment().format("MM/DD/YYYY"))}
                          required={true}
                          maxDate={new Date(moment().add(14, "days").format("MM/DD/YYYY"))}
                          labelMode={LabelModes.column}
                          disableWeekends={false}
                          autoComplete="off"
                          placeholderText="Select..."
                        />
                      </div>
                      <XGSFormSelect
                        className="change-appointment__form__field"
                        label="Early Time"
                        required
                        labelMode={LabelModes.column}
                        name="earlyTime"
                        onValueChange={(v) => {
                          props.setFieldValue("earlyTime", v?.value);
                          setEarlyTime(v);
                        }}
                        value={earlyTime}
                        options={timeOptions()}
                      />
                      <XGSFormSelect
                        className="change-appointment__form__field"
                        required
                        labelMode={LabelModes.column}
                        label="Late Time"
                        name="lateTime"
                        onValueChange={(v) => {
                          props.setFieldValue("lateTime", v?.value);
                          setLateTime(v);
                        }}
                        value={lateTime}
                        options={timeOptions()}
                      />
                      <XGSFormSelect
                        className="change-appointment__form__field"
                        label="Reason"
                        labelMode={LabelModes.column}
                        name="reason"
                        onValueChange={(v) => {
                          props.setFieldValue("reason", v?.value);
                          setReason(v);
                        }}
                        value={reason}
                        options={getAppointmentChangeReasons()}
                      />
                      <XGSFormInput
                        maxLength={20}
                        required
                        className="change-appointment__form__field"
                        labelMode={LabelModes.column}
                        label="Name"
                        name="appointmentName"
                        placeholder="Appointment name"
                      />
                    </div>
                    <Button
                      className="change-appointment__submit"
                      spinner={freightAppointmentState.updating}
                      style={{ marginTop: 10 }}
                      type="submit"
                      theme={ButtonThemes.blue}
                    >
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </TabPanel>
            <TabPanel>
              <div className="change-appointment__history">
                <div className="change-appointment__history__table">
                  <Table
                    data={freightDetailsState.details?.appointmentHistory}
                    columns={getAppointmentHistoryColumns("small")}
                    cursorPointer={false}
                    infiniteScroll={true}
                    minTableHeight={280}
                    strictMinTableHeight
                  />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </>
      )}
      {/* div so that selects dont strech the window */}
      <div style={{ marginBottom: 300 }}></div>
    </SlideOutSidebar>
  );
};
