import React, { memo } from "react";
import Select, { Props as ReactSelectProps, FormatOptionLabelMeta, OnChangeValue } from "react-select";
import customXGSSelectStyles from "./customXGSSelectStyles";
import { DebouncedFunc } from "lodash";
import { FieldInputProps } from "formik";
import "./xgsSelect.scss";

export interface XGSSelectOption {
  value: string;
  label: string;
  subtitle?: string;
  valueForSearch?: string;
  isFixed?: boolean;
}

export interface GroupedOption {
  label: string;
  options: XGSSelectOption[];
}

export interface XGSSelectProps extends ReactSelectProps<XGSSelectOption> {
  onValueChange?: (option?: XGSSelectOption | null) => void;
  onMultiValuesChange?: (option?: XGSSelectOption[] | null) => void;
  async?: boolean;
  disabled?: boolean;
  loadOptions?: DebouncedFunc<any>;
  maxOptions?: number;
  formik?: boolean;
  fields?: FieldInputProps<any>;
}

const XGSSelect: React.FC<XGSSelectProps> = memo(({ async, ...props }) => {
  const formatOptionLabel = (option: XGSSelectOption, labelMeta: FormatOptionLabelMeta<XGSSelectOption>) => {
    const isMenu = labelMeta.context === "menu";
    return (
      <div style={{ display: "flex", flexDirection: isMenu ? "column" : "row" }}>
        <div>{option.label}</div>
        <div style={{ marginLeft: isMenu ? 0 : 10, color: "#ccc" }}>{option.subtitle}</div>
      </div>
    );
  };

  const commonOptions: XGSSelectProps = {
    ...props,
    formatOptionLabel,
    styles: props.styles ? { ...props.styles, ...customXGSSelectStyles } : customXGSSelectStyles,
    // styles: props.styles,
    classNamePrefix: "xgs-select",
    components: {
      IndicatorSeparator: () => null
    },
    onChange: (v: OnChangeValue<any, boolean>) => {
      if (!v) {
        props.onValueChange && props.onValueChange(null);
        props.onMultiValuesChange && props.onMultiValuesChange(null);
      } else {
        if (v && v.length > 0) {
          // multi-select case
          const options = v;
          props.onMultiValuesChange && props.onMultiValuesChange(options);
        } else {
          // single-select choosed
          const options = {
            label: v?.label,
            subtitle: v?.subtitle,
            value: v?.value
          };
          props.onValueChange && props.onValueChange(options);
          // or multi-select resetted
          props.onMultiValuesChange && props.onMultiValuesChange(null);
        }
      }
    }
  };

  return (
    <>
      {!async && <Select {...commonOptions} isDisabled={props.disabled} />}
      {/* {async && <XGSAsyncSelect {...commonOptions} />} */}
    </>
  );
});

export default XGSSelect;
