import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ShipmentDetailsState from "../../../../slices/shipment-details/ShipmentDetailsState";
import { shipmentDetailsSelector } from "../../../../slices/shipment-details/shipmentDetailsSlice";
import { ShipmentDetailsPath, PathParams } from "../route";
import { useParams } from "react-router-dom";
import ContentContainerHeader from "../../../../components/molecules/content-container-header/contentContainerHeader";
import HeaderStatus from "../../../../components/molecules/header-status/headerStatus";
import XGSIcons from "../../../../components/icon/xgsIcons";
import { ShipmentStatuses } from "../../../../app/data/common/shipmentStatuses";
import styles from "../../../../styles/variables.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { freightStatusSelector } from "../../../../slices/exact-freight/freightStatusSlice";

interface Props {
  onClickMenu?: (type: string) => void;
}

const ShipmentDetailsHeader: React.FC<Props> = (props) => {
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const freightDetailsState = useSelector(freightStatusSelector);
  const urlParams = useParams() as unknown as PathParams;
  const urlInvoiceNumber = useMemo(() => {
    return parseInt(urlParams[ShipmentDetailsPath.invoiceNumber], 10);
  }, [urlParams]);

  const getStatus = () => {
    return shipmentDetailsState?.shipment?.status?.description || "Unknown Status";
  };

  const getIcon = () => {
    switch (shipmentDetailsState?.shipment?.status?.description) {
      case ShipmentStatuses.delivered:
        return { icon: XGSIcons.faCheckCircle, iconColor: styles.green1 };
      default:
        return { icon: XGSIcons.faTruck, iconColor: styles.blue2 };
    }
  };

  return (
    <ContentContainerHeader
      title={`Shipment Details: ${urlInvoiceNumber}`}
      isLoading={shipmentDetailsState.loading || freightDetailsState.loading}
      className="xgs-shipment-details__header"
    >
      {freightDetailsState.details && shipmentDetailsState?.shipment && (
        <>
          <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column xgs-shipment-details__header__status">
            <HeaderStatus
              label={getStatus()}
              tooltip={shipmentDetailsState?.shipment?.status?.translate}
              showIcon={true}
              {...getIcon()}
            >
              <>
                {shipmentDetailsState?.shipment?.expectedEta && !shipmentDetailsState?.shipment?.pod?.date && (
                  <>ETA: </>
                )}
                {shipmentDetailsState?.shipment?.pod?.date
                  ? shipmentDetailsState.shipment.pod.date.toUiDateWeekFormat()
                  : shipmentDetailsState?.shipment?.expectedEta?.toUiDateWeekFormat()}
                {shipmentDetailsState?.shipment?.pod?.time && <>&nbsp;{shipmentDetailsState.shipment.pod.time}</>}
              </>
            </HeaderStatus>
          </div>
          {(shipmentDetailsState.shipment.status.title !== "DELIVERED") && <>
            <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column xgs-shipment-details__header__status clickable">
              <HeaderStatus
                label={
                  freightDetailsState.details?.freightExceptions.length > 0
                    ? `${freightDetailsState.details?.freightExceptions.length} exception${
                        freightDetailsState.details?.freightExceptions.length > 1 ? "s" : ""
                      }`
                    : `No exceptions`
                }
                onClick={() => props.onClickMenu && props.onClickMenu("EXCEPTION")}
                showIcon
                icon={XGSIcons.faExclamationTriangle}
                iconColor={freightDetailsState.details?.freightExceptions.length ? styles.red : styles.blue2}
              >
                Add exception
                <FontAwesomeIcon style={{ marginLeft: 5 }} size="2xs" icon={XGSIcons.faExternalLink} />
              </HeaderStatus>
            </div>
            <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column xgs-shipment-details__header__status clickable">
              <HeaderStatus
                label={
                  freightDetailsState.details?.appointment.confirmedDate ? "Confirmed Appointment" : 
                    freightDetailsState.details.appointment.appointmentRequired ? "Appointment Required" : "No Appointment"
                }
                onClick={() => props.onClickMenu && props.onClickMenu("APPOINTMENT")}
                showIcon
                icon={XGSIcons.faCalendar}
                iconColor={freightDetailsState.details?.appointment.confirmedDate ? styles.green1 : styles.blue2}
              >
                {"Change appointment"}
                <FontAwesomeIcon style={{ marginLeft: 5 }} size="2xs" icon={XGSIcons.faExternalLink} />
              </HeaderStatus>
            </div>
          </>}
        </>
      )}
    </ContentContainerHeader>
  );
};

export default ShipmentDetailsHeader;
