import { BaseState } from "..";
import { UpdateLoadManifestRequest } from "../../app/data/load-manifest/model";

export interface LoadManifestState extends BaseState {
  details: UpdateLoadManifestRequest | null;
  updating: boolean;
  updated: boolean;
  updateFailed: boolean;
  updateError?: string | null;
}

export const initialLoadManifestState: LoadManifestState = {
  details: null,
  updating: false,
  updated: false,
  updateFailed: false,
  updateError: null,
  loading: false,
  loadingFailed: false,
  loaded: false
};
