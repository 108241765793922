// @ts-nocheck
import React, { useState, useMemo, useEffect, memo } from "react";
import { useTable, useFlexLayout, useRowSelect, useMountedLayoutEffect } from "react-table";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import TableTotal, { BaseTableTotalProps } from "./table-total/tableTotal";
import Loading from "../loading/loading";
import useWindowSize from "../../hooks/useWindowsSize";
import { makeId, getHeightBeforeEndOfPage } from "../../hooks/utils";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";
import "./table.scss";

// eslint-disable-next-line
export interface TableProps<T> {
  data: any;
  columns: any;
  autoHeight?: boolean;
  responsive?: boolean;
  onRowClicked?: any; // () => void
  ignoreCellClick?: string;
  onSelectedRowsChange?: any;
  onCheckRow?: any;

  isLoading?: boolean;
  isBlocked?: boolean;
  keepTableOnLoading?: boolean;
  cursorPointer?: boolean;
  totals?: BaseTableTotalProps[];
  customHeaderRowClass?: string;
  rowHeight?: number;

  infiniteScroll?: boolean;
  infiniteScrollHasNext?: boolean;
  infiniteScrollLoading?: boolean;
  onInfiniteScroll?: any;

  noResultsText?: string;

  minTableHeight?: number;
  strictMinTableHeight?: boolean;

  sorting?: boolean;
  onSortByColumn?: (i: number, a: boolean) => void; // columnIndex, ascending
  clearSorting?: any;

  highlightRow?: number[] | number | null;
  highlightRowClass?: string;
}

const scrollbarWidth = () => {
  // https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const getTableHeight = (
  tableId: string,
  windowHeight: number,
  strictMinTableHeight: boolean,
  minTableHeight?: number
) => {
  let height = getHeightBeforeEndOfPage(`.xgs-table.${tableId}`, 300, windowHeight, 40);
  const minHeight = minTableHeight || 100;
  return !strictMinTableHeight && height > minHeight ? height : minHeight;
};

const Table: React.FC<TableProps<any>> = memo((props) => {
  const [sortableColumnIndex, setSortableColumnIndex] = useState<number | undefined>();
  const [ascSorting, setAscSorting] = useState<boolean>(true);

  const {
    isLoading,
    isBlocked,
    cursorPointer,
    totals,
    data,
    columns,
    autoHeight,
    responsive,
    onRowClicked,
    ignoreCellClick,
    onSelectedRowsChange,
    infiniteScroll,
    infiniteScrollHasNext,
    infiniteScrollLoading,
    onInfiniteScroll,
    noResultsText,
    rowHeight,
    sorting,
    onSortByColumn
  } = props;
  const windowHeight = useWindowSize()[1];
  const [tableId] = useState(`xgs-table-${makeId(5)}`);
  const scrollBarSize = useMemo(() => scrollbarWidth(), []);
  const allowSelectedRowsReset = React.useRef(true);

  const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
        autoResetSelectedRows: allowSelectedRowsReset.current
      },
      useFlexLayout,
      useRowSelect
    );

  useEffect(() => {
    allowSelectedRowsReset.current = true;
  }, [data]);

  useMountedLayoutEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange({
        selectedRowsData: selectedFlatRows.map((d) => d.original)
      });
    }
  }, [onSelectedRowsChange, selectedFlatRows]);

  const contentHasScrollbar = () => {
    const container = document.querySelector(`#${tableId}-content-area--js`);
    const area = document.querySelector(`#${tableId}-content-area--js > div > div`);
    if (container && area) {
      return area.scrollHeight > container.clientHeight;
    } else {
      return false;
    }
  };

  const resetSortingColumn = () => {
    setSortableColumnIndex(undefined);
  };

  props.clearSorting && props.clearSorting(resetSortingColumn);

  const itemCount = infiniteScrollHasNext ? data.length + 1 : data.length;

  const loadMoreItems = async (startIndex: number, stopIndex: number) => {
    // only load 1 portion of items at a time
    if (!infiniteScrollLoading) {
      allowSelectedRowsReset.current = false;
      onInfiniteScroll();
    }
  };

  const isItemLoaded = React.useCallback(
    (index: any) => {
      return !infiniteScrollHasNext || index < data.length;
    },
    [infiniteScrollHasNext, data]
  );

  const onClickSortableColumn = (columnIndex: number) => {
    const order = columnIndex === sortableColumnIndex ? !ascSorting : true;
    setAscSorting(order);
    setSortableColumnIndex(columnIndex);
    onSortByColumn && onSortByColumn(columnIndex, order);
  };

  const RenderRow = React.useCallback(
    ({ height, index, style }) => {
      const row = rows[index];
      if (!row) {
        return (
          <div style={style} className="tr xgs-table__spinner-row">
            <div className="td">Loading...</div>
          </div>
        );
      } else {
        if (!row.getRowProps) {
          prepareRow(row);
        }
        return (
          <div
            {...row.getRowProps({
              style: {
                ...style,
                width: responsive ? "100%" : `${totalColumnsWidth}px`,
                minWidth: "none"
              }
            })}
            className={
              "tr" +
              (cursorPointer && !ignoreCellClick ? " cursor-pointer" : "") +
              (index % 2 ? "" : " xgs-table__even-row") +
              (props.highlightRow !== null &&
              (typeof props.highlightRow === "object"
                ? props?.highlightRow.includes(index)
                : props.highlightRow === index)
                ? props.highlightRowClass
                  ? ` ${props.highlightRowClass}`
                  : " xgs-table__highlighted-row"
                : "")
            }
            onClick={() => (onRowClicked && !ignoreCellClick ? onRowClicked(row.original, row.index) : false)}
          >
            {row.cells.map((cell) => {
              return (
                <div
                  {...cell.getCellProps()}
                  className={
                    "td" +
                    (cursorPointer && ignoreCellClick && cell.column.id !== ignoreCellClick
                      ? " cursor-pointer"
                      : "")
                  }
                  onClick={() =>
                    onRowClicked && ignoreCellClick && cell.column.id !== ignoreCellClick
                      ? onRowClicked(row.original)
                      : false
                  }
                >
                  {cell.render("Cell")}
                </div>
              );
            })}
            {responsive && scrollBarSize === 0 && <div className="xgs-table__scroll-spacer"></div>}
          </div>
        );
      }
    },
    // we don't use selectedFlatRows in the RenderRow, but we need to re-render row if it changed
    // eslint-disable-next-line
    [prepareRow, onRowClicked, cursorPointer, totalColumnsWidth, rows, selectedFlatRows]
  );

  return (
    <div className={"xgs-table__wrapper" + (isLoading ? " xgs-table__wrapper--loading" : "")}>
      {isLoading && !props.keepTableOnLoading && (
        <div
          className={"xgs-table__loading" + props.keepTableOnLoading ? " xgs-table__loading--over" : ""}
          style={{
            maxWidth: "100%"
          }}
        >
          <Loading isLoading={true} />
        </div>
      )}
      {(!isLoading || isBlocked || props.keepTableOnLoading) && (
        <div {...getTableProps()} className={`xgs-table ${tableId}`}>
          {((isLoading && props.keepTableOnLoading) || isBlocked) && (
            <div className="xgs-table__loading-background">
              <div
                className="xgs-table__loading xgs-table__loading--over"
                style={{
                  left: responsive ? "calc(50% - 16px)" : totalColumnsWidth / 2 - 16
                }}
              >
                <Loading isLoading={isLoading} />
              </div>
            </div>
          )}
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                style: {
                  width: responsive ? "100%" : totalColumnsWidth + (!infiniteScroll ? scrollBarSize : 0),
                  minWidth: "none",
                  height: 50
                }
              })}
              className={
                "xgs-table__headers tr " +
                (props.customHeaderRowClass ? ` ${props.customHeaderRowClass} ` : "") +
                (sorting ? " xgs-table__headers--sorting" : "")
              }
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <div
                  {...column.getHeaderProps({
                    style: {
                      flexShrink: responsive ? 1 : 0
                    }
                  })}
                  className={
                    "th " +
                    (sorting && columnIndex === sortableColumnIndex ? "xgs-table__headers__sortable-item" : "")
                  }
                  onClick={() => (sorting ? onClickSortableColumn(columnIndex) : false)}
                  key={`th-${columnIndex}`}
                >
                  {column.render("Header")}
                  {sorting && columnIndex === sortableColumnIndex && (
                    <div className="xgs-table__headers__sortable-item__icon">
                      {ascSorting ? (
                        <XGSIcon icon={XGSIcons.faSortDown} size="lg" />
                      ) : (
                        <XGSIcon icon={XGSIcons.faSortUp} size="lg" />
                      )}
                    </div>
                  )}
                </div>
              ))}
              {responsive && (scrollBarSize === 0 || contentHasScrollbar()) && (
                <div className="xgs-table__scroll-spacer"></div>
              )}
            </div>
          ))}
          <div className="xgs-table__content" style={data?.length === 0 ? { width: "100%" } : {}}>
            {data?.length > 0 && (
              <div
                id={`${tableId}-content-area--js`}
                className={responsive ? "xgs-table__full-width" : ""}
                {...getTableBodyProps({
                  style: {
                    height: "100%",
                    width: "100%"
                  }
                })}
              >
                {infiniteScroll && data.length > 0 ? (
                  // with infinite scroll
                  <>
                    {autoHeight && (
                      // autoHeight true
                      <AutoSizer>
                        {({ height, width }) => (
                          <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={50000}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={50}
                          >
                            {({ onItemsRendered, ref }) => (
                              <FixedSizeList
                                height={height - 50}
                                itemCount={itemCount}
                                itemSize={rowHeight || 45}
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                                width={responsive ? "100%" : totalColumnsWidth + scrollBarSize}
                              >
                                {RenderRow}
                              </FixedSizeList>
                            )}
                          </InfiniteLoader>
                        )}
                      </AutoSizer>
                    )}
                    {!autoHeight && (
                      // autoHeight false
                      <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={50000}
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={50}
                      >
                        {({ onItemsRendered, ref }) => (
                          <FixedSizeList
                            height={getTableHeight(
                              tableId,
                              windowHeight,
                              props.strictMinTableHeight || false,
                              props.minTableHeight
                            )}
                            itemCount={itemCount}
                            itemSize={rowHeight || 45}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            width={responsive ? "100%" : totalColumnsWidth + scrollBarSize}
                          >
                            {RenderRow}
                          </FixedSizeList>
                        )}
                      </InfiniteLoader>
                    )}
                  </>
                ) : (
                  // without infinite scroll
                  rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps({
                          style: {
                            width: !responsive ? totalColumnsWidth + scrollBarSize : "auto",
                            minWidth: responsive ? totalColumnsWidth + scrollBarSize : "none"
                          }
                        })}
                        className={
                          "tr" +
                          (cursorPointer ? " cursor-pointer" : "") +
                          (props.highlightRow !== undefined && props.highlightRow === rowIndex
                            ? props.highlightRowClass
                              ? ` ${props.highlightRowClass}`
                              : " xgs-table__highlighted-row"
                            : "")
                        }
                        onClick={() => (onRowClicked ? onRowClicked(row.original) : false)}
                        key={`tr-${rowIndex}`}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <div {...cell.getCellProps()} className="td" key={`td-${cellIndex}`}>
                            {cell.render("Cell")}
                          </div>
                        ))}
                      </div>
                    );
                  })
                )}
              </div>
            )}
            {(!data || data?.length === 0) && (
              <div className="xgs-table__no-records" style={{ width: responsive ? "100%" : totalColumnsWidth }}>
                {!isLoading && <div>{noResultsText || "There are no records to display"}</div>}
              </div>
            )}
          </div>
          {totals && (
            <div style={{ width: totalColumnsWidth + scrollBarSize }}>
              <TableTotal totals={totals} data={data} />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Table;
