import { SelectFreightException } from "../../features/exceptions/selectFreightException";
import { AddFreightExceptions } from "../../features/exceptions/addFreightExceptions";
import { AddFreightExceptionPaths } from "../../features/exceptions/route";
import { EnterPODInformation } from "../../features/pod-information/enter-pod-information/enterPodInformation";
import PODInformationSummary from "../../features/pod-information/podInformationSummary";
import { ShipmentDetailsPath } from "../../features/shipments/shipmentDetails/route";
import { ShipmentDetails } from "../../features/shipments/shipmentDetails/shipmentDetails";
import { Tracking } from "../../features/tracking/tracking";
import AuthCallbackReceiver from "../../features/auth/callbackReceiver";
import UserProfile from "../data/user/UserProfile";
import { ExactFreightList } from "../../features/exact-freight/exactFreightList";
import { InboundFreight } from "../../features/inbound-freight/inboundFreight";
import { DeliveryReceipts } from "../../features/delivery-receipts/deliveryReceipts";
import RollDetails from "../../features/roll-details/rollDetails";
import { RollDetailsPath } from "../../features/roll-details/route";
import { UpdateLoadManifestPath } from "../../features/inbound-freight/route";
import { UpdateLoadManifest } from "../../features/inbound-freight/update-load-manifest/updateLoadManifest";
import { AppointmentSummary } from "../../features/appointment/appointmentsSummary";
import { EnterPodInformationDirect } from "../../features/pod-information/enter-pod-information/enterPodInformationDirect";

export const accountNumberParamName = "accountNumber";

export const xgsRoutes = {
  home: "/",
  shipments: {
    tracking: "/shipments",
    podInformation: "/pod",
    enterPodInformation: "/pod/new",
    enterPodInformationDirect: "/pod/new-direct",
    deliveryReceipts: "/delivery-receipts"
  },
  freightExceptions: {
    addFreightException: "/freight-exception",
    listing: "/freight-exceptions"
  },
  exactFreight: {
    listing: "/live-freight"
  },
  freightAppointments: {
    summary: "/appointments"
  },
  inboundFreight: {
    listing: "/inbound-freight",
    rollDetails: "/inbound-freight/roll-details",
    updateLoadManifest: "/inbound-freight/update-load-manifest"
  },
  authCallback: "/auth-callback"
};

export interface RoutesComponent {
  path: string;
  component: React.FC<any>;
  fixedPath?: string;
  isPrivate?: boolean;
}

export interface CorePathParams {
  [accountNumberParamName]: string;
}

export const getPathWithCoreParams = (route: string) => {
  return `/:${accountNumberParamName}${route === xgsRoutes.home ? "" : route}`;
};

export const getPathWithAccountNumber = (route: string) => {
  const paths = [getPathWithCoreParams(route), route];
  return paths;
};

export const publicRoutesComponents: RoutesComponent[] = [
  {
    path: xgsRoutes.authCallback,
    component: AuthCallbackReceiver
  }
];

const agentRoutesComponents: RoutesComponent[] = [
  {
    path: xgsRoutes.home,
    component: Tracking,
    isPrivate: true
  },
  {
    path: xgsRoutes.shipments.tracking,
    component: Tracking,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.shipments.tracking}/:${ShipmentDetailsPath.invoiceNumber}`,
    component: ShipmentDetails,
    isPrivate: true
  },
  {
    path: xgsRoutes.shipments.deliveryReceipts,
    component: DeliveryReceipts,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.shipments.podInformation}`,
    component: PODInformationSummary,
    isPrivate: true
  },
  {
    path: xgsRoutes.shipments.enterPodInformation,
    component: EnterPODInformation,
    isPrivate: true
  },
  {
    path: xgsRoutes.shipments.enterPodInformationDirect,
    component: EnterPodInformationDirect,
    isPrivate: true
  },
  {
    path: xgsRoutes.freightExceptions.addFreightException,
    component: SelectFreightException,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.freightExceptions.addFreightException}/:${AddFreightExceptionPaths.invoiceNumber}`,
    component: AddFreightExceptions,
    isPrivate: true
  },
  {
    path: xgsRoutes.exactFreight.listing,
    component: ExactFreightList,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.exactFreight.listing}/:${ShipmentDetailsPath.invoiceNumber}`,
    component: ShipmentDetails,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.freightAppointments.summary}`,
    component: AppointmentSummary,
    isPrivate: true
  },
  {
    path: xgsRoutes.inboundFreight.listing,
    component: InboundFreight,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.inboundFreight.rollDetails}/:${RollDetailsPath.manifestNumber}`,
    component: RollDetails,
    isPrivate: true
  },
  {
    path: `${xgsRoutes.inboundFreight.updateLoadManifest}/:${UpdateLoadManifestPath.manifestNumber}`,
    component: UpdateLoadManifest,
    isPrivate: true
  }
];

export const getRouteConfig = (user: UserProfile | null | undefined) => {
  if (!user) return [];
  return agentRoutesComponents;
};
