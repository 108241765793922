import ShipmentModel from "../../app/data/tracking/ShipmentModel";
import SearchShipmentRequestModel from "../../app/data/tracking/SearchShipmentRequestModel";
import { InfiniteScrollBaseState } from "..";

export default interface TrackShipmentState extends InfiniteScrollBaseState {
  count: number;
  shipments: ShipmentModel[];
  request: SearchShipmentRequestModel;
  teams: any;
  searchTeamStarted: boolean;
  searchTeamSuccess: boolean;
  searchTeamFailed: boolean;
  searchTeamError: string | null;
  loadingCSVStarted: boolean;
  loadingCSVSuccess: boolean;
  loadingCSVFailed: boolean;
  loadingCSVError: string | null;
  CSVLink: string | null;
}

export const initialTrackShipmentState: TrackShipmentState = {
  count: 0,
  shipments: [],
  loading: false,
  loadingPortion: false,
  loadingFailed: false,
  loaded: false,
  loadedAll: false,
  request: {},
  teams: [],
  searchTeamStarted: false,
  searchTeamSuccess: false,
  searchTeamFailed: false,
  searchTeamError: null,
  loadingCSVStarted: false,
  loadingCSVSuccess: false,
  loadingCSVFailed: false,
  loadingCSVError: null,
  CSVLink: null
};
