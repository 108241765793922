import { Link } from "react-router-dom";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import React from "react";

export const getRecentExceptionsColumns = () => {
  return [
    {
      Header: "Probill #",
      accessor: "probillNumber",
      width: 100,
      Cell: (cellProps: any) => {
        return (
          <Link target="_blank" to={`${xgsRoutes.exactFreight.listing}/${cellProps.value}`}>
            {cellProps.value}
          </Link>
        );
      }
    },
    {
      Header: "Started",
      accessor: "startedDate",
      width: 100,
      Cell: (cellProps: any) => <>{cellProps.value ? cellProps.value?.toUiDateFormat() : ""}</>
    },
    {
      Header: "Resolved",
      accessor: "resolvedDate",
      width: 100,
      Cell: (cellProps: any) => <>{cellProps.value ? cellProps.value?.toUiDateFormat() : ""}</>
    },
    {
      Header: "Type",
      accessor: "type",
      width: 200
    },
    {
      Header: "Description (limited to 60 characters)",
      accessor: "description",
      width: 300
    },
    {
      Header: "Date added",
      accessor: "addedDate",
      Cell: (cellProps: any) => <>{cellProps.value ? cellProps.value?.toUiDateFormat() : ""}</>
    }
  ];
};
