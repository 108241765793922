import { createSlice } from "@reduxjs/toolkit";
import { RequestCreators, initialFreightExceptionState } from "./freightExceptionState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { FreightExceptionService } from "../../app/data/freight-exception/freightExceptionService";
import CommonService from "../../app/data/common/commonService";

const freightExceptionService = FreightExceptionService.getInstance();

export const freightExceptionSlice = createSlice({
  name: "freightException",
  initialState: initialFreightExceptionState,
  reducers: {
    resetFreightExceptionState: (state) => initialFreightExceptionState,
    setProbillSuggestions: (state, { payload }) => {
      let suggestions = [];
      for (const item of payload) {
        suggestions.push({
          label: item.probill,
          value: item
        });
      }
      state.probillSuggestions = suggestions;
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    }
  }
});

export const { resetFreightExceptionState, requestStarted, requestFailed, requestSucceed, setProbillSuggestions } =
  freightExceptionSlice.actions;

export const freightExceptionSelector = (state: IState) => state.freightException;

export const addNewFreightException =
  (data: any, onSuccess: () => void, onFailed: (errorMessage: string | undefined) => void): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted(RequestCreators.addException));
    const response = await freightExceptionService.addFreightException(data);
    if (response.ok()) {
      dispatch(requestSucceed());
      onSuccess();
    } else {
      response.getError && dispatch(requestFailed(response.getError()));
      response.getError && onFailed(response.getError()?.toString())
    }
  };

export const getProbillDetails =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted(RequestCreators.probillSuggestions));
    const response = await CommonService.getInstance().getProbillDetails(search, "PROBILL");
    if (response.ok()) {
      dispatch(requestSucceed());
      dispatch(setProbillSuggestions(response.data));
    } else {
      dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    }
  };

const freightExceptionReducer = freightExceptionSlice.reducer;
export default freightExceptionReducer;
