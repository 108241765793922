export enum AddFreightExceptionPaths {
  invoiceNumber = "invoiceNumber"
}
export enum FreightExpectionListPaths {
  invoiceNumber = "invoiceNumber"
}
export interface PathParams {
  [AddFreightExceptionPaths.invoiceNumber]: string;
  [FreightExpectionListPaths.invoiceNumber]: string;
}
