export enum UpdateLoadManifestPath {
  manifestNumber = "manifestNumber",
  mode = "mode"
}

export interface PathParams {
  [UpdateLoadManifestPath.manifestNumber]: string;
}

export interface SearchParams {
  [UpdateLoadManifestPath.mode]: string;
}

export enum UpdateLoadManifestModes {
  inbound = "INBOUND",
  arrived = "ARRIVED"
}
