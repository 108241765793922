import React from "react";
import NumberFormat from "react-number-format";

export interface AmountProps {
  amount: number | undefined;
}

const Amount: React.FC<AmountProps> = (props) => {
  return (
    <NumberFormat
      value={props.amount}
      decimalScale={2}
      displayType={"text"}
      fixedDecimalScale={true}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};

export default Amount;
