import { BaseState } from "..";
import { RollDetailsResponseItem } from "../../app/data/linehaul-manifests/model";

export default interface RollDetailsState extends BaseState {
  details: RollDetailsResponseItem[];
  loadingCSVStarted: boolean;
  loadingCSVSuccess: boolean;
  loadingCSVFailed: boolean;
  loadingCSVError: string | null;
  CSVLink: string | null;
}

export const initialRollDetailsState: RollDetailsState = {
  details: [],
  loading: false,
  loaded: false,
  loadingFailed: false,
  loadingCSVStarted: false,
  loadingCSVSuccess: false,
  loadingCSVFailed: false,
  loadingCSVError: null,
  CSVLink: null
};
