import { batch } from "react-redux";
import LogRocket from "logrocket";
import { AppThunk } from "../../app/store";
import UserService from "../../app/data/user/userService";
import { TokenRequestModel } from "../../app/data/user/requestModels";
import { IState } from "..";
import {
  userSlice,
  getCurrentUser_has_Started,
  getCurrentUser_has_Succeed,
  loginSucceed,
  loginStarted,
  loginFailed,
  logoutStarted,
  getCurrentUser_has_Failed,
} from "./userCreateSlice";

const userService = UserService.getInstance();

export const userSelector = (state: IState) => {
  return state.user;
};

export const getAuthToken = (
  request: TokenRequestModel,
  onSuccess?: () => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(loginStarted());
  const response = await userService.getAuthToken(request);
  if (response?.status === 200) {
    localStorage.setItem("xgs-agent-access-token", response.data.access_token);
    localStorage.setItem("xgs-agent-refresh-token", response.data.refresh_token);
    localStorage.setItem("xgs-agent-id-token", response.data.id_token);
    dispatch(loginSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(loginFailed());
    onFailed && onFailed();
  }
};

export const getCurrentUser = (
  processName: string,
  onSuccess?: (response: any) => void,
  onFailed?: (statusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(getCurrentUser_has_Started());
  const response = await userService.getCurrent();
  if (response.ok()) {
    batch(() => {
      dispatch(loginSucceed());
      dispatch(getCurrentUser_has_Succeed(response.data));
    });
    onSuccess && onSuccess(response.data);
    if (process.env.REACT_APP_XGS_ENV === "production") {
      LogRocket.identify(response.data.id, {
        name: `${response.data.firstName || ""}${response.data.lastName ? " " + response.data.lastName : ""}`,
        username: response.data.username || ""
      });
    }
  } else {
    dispatch(getCurrentUser_has_Failed());
    onFailed && onFailed(response.status);
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(logoutStarted());
  const idToken = localStorage.getItem("xgs-agent-id-token");
  const logoutUrl = process.env.REACT_APP_LOGOUT_ENDPOINT + `?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_DOMAIN}`;
  localStorage.removeItem("xgs-agent-access-token");
  localStorage.removeItem("xgs-agent-refresh-token");
  localStorage.removeItem("xgs-agent-id-token");
  window.location.assign((idToken && idToken !== "undefined") ? logoutUrl : (process.env.REACT_APP_DOMAIN || ""));
};

const usersReducer = userSlice.reducer;
export default usersReducer;
