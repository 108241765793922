import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import "./xgsPopup.scss";

export interface XGSPopupProps {
  title?: string | JSX.Element;
  opened: boolean;
  triggerElement?: JSX.Element;
  onClose: () => void;
  className?: string;
  children?: any;
}

const XGSPopup: React.FC<XGSPopupProps> = (props) => {
  return (
    <Popup
      className={props.className}
      open={props.opened}
      closeOnDocumentClick
      closeOnEscape
      onOpen={() => (document.body.style.overflowY = "hidden")}
      onClose={() => {
        document.body.style.overflowY = "visible";
        props.onClose();
      }}
      arrow={false}
      offsetY={10}
      contentStyle={{
        width: "auto",
        borderRadius: 4,
        border: "1px solid #E0E0E0",
        boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)",
        padding: "16px 24px"
      }}
    >
      {
        <div className="xgs-popup">
          <div className="xgs-popup__header">
            <div className="xgs-popup__title">{props.title}</div>
            <div className="xgs-popup__close" onClick={props.onClose}>
              <FontAwesomeIcon icon={Icons.faTimesCircle} />
            </div>
          </div>
          <div className="xgs-popup__content">{props.children}</div>
          <div className="xgs-popup__bottom"></div>
        </div>
      }
    </Popup>
  );
};

export default XGSPopup;
