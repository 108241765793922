import { AppThunk } from "../../app/store";

export default interface FreightExceptionState {
  selectedItems: string;
  description: string;
  incidentType: string;
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestError?: string | null;
  requestCreator?: string;
  probillSuggestions: any[];
  needsToReload?: boolean;
}

export const initialFreightExceptionState: FreightExceptionState = {
  selectedItems: "",
  probillSuggestions: [],
  description: "",
  incidentType: "",
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  needsToReload: false
};

export enum RequestCreators {
  items = "ITEMS",
  addException = "ADD_EXCEPTION",
  probillSuggestions = "GET_PROBILL_SUGGESTIONS"
}
export const getItemsForFreightException = (): AppThunk => async (probillNumber) => {};
