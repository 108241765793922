import React, { useEffect, useState, memo } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";
import XGSDateInput from "./xgsDateInput";
import { XGSInputBaseProps } from "../../xgs-input/xgsInput";
import dateFormats from "../../../app/data/common/dateFormats";
import "./../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import "./xgsDate.scss";

export interface XGSDateProps extends XGSInputBaseProps, ReactDatePickerProps {
  onDateChange: (date: string) => void;
  disableWeekends?: boolean;
  value?: any;
  placeholderText?: string;
}

const XGSDate: React.FC<XGSDateProps> = memo(({
  fields,
  isFailed,
  onDateChange,
  value,
  ...props
}) => {
  const [datePickerValue, setDatePickerValue] = useState<Date>(value ? moment(value).toDate() : new Date());

  const onChange = (d: Date) => {
    if (!d) {
      setDatePickerValue(d);
      onDateChange(d);
    } else {
      const momentDate = moment(d);
      setDatePickerValue(momentDate.toDate());
      onDateChange(momentDate.format(dateFormats.uiDate));
    }
  };

  const disableWeekends = (date: Date) => {
    if (!props.disableWeekends) return true;
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    setDatePickerValue(value ? moment(value).toDate() : new Date());
  }, [value]);

  return (
    <DatePicker
      {...props}
      onChange={onChange}
      selected={value ? datePickerValue : undefined}
      dateFormat="MM/dd/yyyy"
      filterDate={disableWeekends}
      calendarStartDay={1}
      customInput={
        <XGSDateInput          
          placeholderText={props.placeholderText}
        />
      }
    />
  );
});

export default XGSDate;
