import React, { useEffect, useMemo, useRef } from "react";
import ContentContainer from "../../../templates/content-container/contentContainer";
import { Form, Formik, FormikProps } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PathParams, UpdateLoadManifestModes, UpdateLoadManifestPath } from "../route";
import "./updateLoadManifest.scss";
import {
  getLoadManifestDetails,
  loadManifestSelector,
  resetLoadManifestState,
  updateLoadManifestDetails
} from "../../../slices/load-manifest/loadManifestSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/storeHooks";
import LabeledDateInput from "../../../components/form/date/xgsFormDate";
import XGSFormSelect from "../../../components/form/select/xgsFormSelect";
import { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import moment from "moment";
import Button, { ButtonThemes } from "../../../components/button/button";
import {
  UpdateLoadManifestRequest,
  UpdateLoadManifestArrivedSchema,
  UpdateLoadManifestInboundSchema,
  getIntactOptions
} from "../../../app/data/load-manifest/model";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import { XGSSelectOption } from "../../../components/xgs-select/xgsSelect";
import InfoBlock from "../../../templates/info-block/infoBlock";
import LabledValue from "../../../components/molecules/labled-value/labledValue";
import { toast } from "react-toastify";
import { xgsRoutes } from "../../../app/route/RoutesConfig";

const initialFormValues: UpdateLoadManifestRequest = {
  arrivalDate: moment().format("MM/DD/YY"),
  arrivalTime: "",
  intact: "",
  checkedBy: "",
  carrierName: "",
  seal: "",
  departureDate: moment().format("MM/DD/YYYY"),
  departureTime: ""
};
const getTimeOptions = () => {
  let options: XGSSelectOption[] = [];
  let startTime = moment().startOf("day").add(6, "hours");
  for (let i = 1; i < 30; i++) {
    let time = startTime.format("h:mm a");
    options.push({
      label: time,
      value: startTime.format("HH:mm")
    });
    startTime = startTime.add(30, "minutes");
  }
  return options;
};

export const UpdateLoadManifest: React.FC<{}> = () => {
  const params = useParams() as unknown as PathParams;
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const loadManifestState = useSelector(loadManifestSelector);
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<UpdateLoadManifestRequest>>(null);
  const manifestNumber = useMemo(() => {
    return parseInt(params[UpdateLoadManifestPath.manifestNumber], 10);
  }, [params]);
  const mode = useMemo(() => {
    return search.get(UpdateLoadManifestPath.mode) as UpdateLoadManifestModes;
  }, [search]);
  useEffect(() => {
    dispatch(getLoadManifestDetails(manifestNumber));
    return () => {
      dispatch(resetLoadManifestState());
    };
  }, [dispatch, manifestNumber]);

  const onSuccess = () => {
    toast.info("Updated load manifest");
    navigate(xgsRoutes.inboundFreight.listing);
  }
  const onFailed = (message?: any) => {
    toast.error(message ? message : "Something went wrong");
  }

  const onSubmit = (request: UpdateLoadManifestRequest) => {
    if (mode === UpdateLoadManifestModes.arrived) {
      dispatch(
        updateLoadManifestDetails({
            manifestNumber,
            departureDate: request.departureDate?.toApiDateFormat(),
            departureTime: request.departureTime,
            mode
          },
          onSuccess,
          onFailed
        ),
      );
    } else {
      dispatch(
        updateLoadManifestDetails({
          manifestNumber,
          arrivalDate: request.arrivalDate?.toApiDateFormat(),
          arrivalTime: request.arrivalTime,
          seal: request.seal,
          checkedBy: request.checkedBy,
          intact: request.intact,
          mode
        },
        onSuccess,
        onFailed
        )
      );
    }
  };

  return (
    <ContentContainer isLoading={loadManifestState.loading} title="Update load manifest">
      <div className="update-load-manifest">
        <Formik
          validationSchema={
            mode === UpdateLoadManifestModes.inbound
              ? UpdateLoadManifestInboundSchema
              : UpdateLoadManifestArrivedSchema
          }
          innerRef={formRef}
          onSubmit={onSubmit}
          initialValues={initialFormValues}
        >
          {(props) => (
            <Form className="update-load-manifest__from">
              <InfoBlock className="update-load-manifest__form__info" title="">
                <LabledValue labelFixWidth value={manifestNumber} label="Manifest number:" />
                <LabledValue
                  labelFixWidth
                  value={loadManifestState.details?.trailer}
                  label={"Trailer number:"}
                />
                <LabledValue
                  labelFixWidth
                  value={loadManifestState.details?.carrierName}
                  label={"Carrier name:"}
                />
              </InfoBlock>
              {mode === UpdateLoadManifestModes.inbound && (
                <>
                  <div className="update-load-manifest__form">
                    <LabeledDateInput
                      name={`arrivalDate`}
                      label="Arrival Date:"
                      className="update-load-manifest__form__field"
                      onDateChange={(v) => props.setFieldValue(`arrivalDate`, v)}
                      onChange={() => null}
                      minDate={new Date(moment().subtract(3, "d").format("MM/DD/YYYY"))}
                      maxDate={new Date()}
                      required={true}
                      labelMode={LabelModes.column}
                      disableWeekends={true}
                    />
                    <XGSFormSelect
                      name="arrivalTime"
                      label="Arrival Time(EST):"
                      onValueChange={(v) => props.setFieldValue("arrivalTime", v?.value)}
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      options={getTimeOptions()}
                      required={true}
                      className="update-load-manifest__form__field"
                    />
                    <XGSFormInput
                      labelMode={LabelModes.column}
                      className="update-load-manifest__form__field"
                      label="Seal"
                      name="seal"
                      maxLength={9}
                      required
                    />
                    <XGSFormInput
                      labelMode={LabelModes.column}
                      label="Checked By"
                      className="update-load-manifest__form__field"
                      name="checkedBy"
                      maxLength={20}
                      required
                    />
                    <XGSFormSelect
                      label="Seal intact?"
                      onValueChange={(v) => props.setFieldValue("intact", v?.value)}
                      options={getIntactOptions()}
                      className="update-load-manifest__form__field"
                      labelMode={LabelModes.column}
                      name="intact"
                      formik={true}
                      required
                    />
                  </div>
                </>
              )}
              {mode === UpdateLoadManifestModes.arrived && (
                <>
                  <div className="update-load-manifest__form">
                    <LabeledDateInput
                      name={`departureDate`}
                      label="Departure Date:"
                      className="update-load-manifest__form__field"
                      onDateChange={(v) => props.setFieldValue(`departureDate`, v)}
                      onChange={() => null}
                      minDate={new Date(moment().subtract(3, "d").format("MM/DD/YYYY"))}
                      maxDate={new Date(moment().format("MM/DD/YYYY"))}
                      required={true}
                      labelMode={LabelModes.column}
                      disableWeekends={true}
                    />
                    <XGSFormSelect
                      name="departureTime"
                      label="Departure Time(EST):"
                      onValueChange={(v) => props.setFieldValue("departureTime", v?.value)}
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      options={getTimeOptions()}
                      required={true}
                      className="update-load-manifest__form__field"
                    />
                  </div>
                </>
              )}
              <Button spinner={loadManifestState.updating} className="update-load-manifest__form__submit" type="submit" theme={ButtonThemes.blue}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </ContentContainer>
  );
};
