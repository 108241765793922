import { InvoiceDocument } from "../../app/data/invoice/models";

export interface DeliveryReceiptsState {
  probillSuggestions: any[];
  bolSuggestions: any[];
  manifestSuggestions: any[];
  documents?: InvoiceDocument[] | null;
  link?: string;
  requestStarted: boolean;
  requestFailed: boolean;
  requestSucceed: boolean;
  requestError: null | string;
  requestCreator: null | string;
}
export const initialDeliveryReceiptsState: DeliveryReceiptsState = {
  probillSuggestions: [],
  bolSuggestions: [],
  manifestSuggestions: [],
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  requestError: null,
  requestCreator: null
};
