import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/storeHooks";
import { getAuthToken } from "../../slices/user/userSlice";
import PageLoading from "../page-loading/pageLoading";

const getQueryParam = (name: string) => {
  // not using URLSearchParams because IE11 doesn't support it
  let results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
  return results ? (decodeURI(results[1]) || 0) : null;
};

const AuthCallbackReceiver: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const code = getQueryParam("code");
    const originUrl = getQueryParam("originUrl") || "";
    const callbackUrl = process.env.REACT_APP_DOMAIN + `/auth-callback?originUrl=${originUrl}`;
    const request = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_SSO_CLIENT_ID || "",
      scope: "openid",
      redirect_uri: callbackUrl,
      code: `${code}`
    };
    dispatch(getAuthToken(request, () => {
      const storedOriginHref = localStorage.getItem("xgs-agent-origin-href");
      if (storedOriginHref) localStorage.removeItem("xgs-agent-origin-href");
      window.location.assign(storedOriginHref || originUrl);
    }, () => {
      const authUrl = process.env.REACT_APP_AUTH_ENDPOINT + `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${callbackUrl}`;
      window.location.assign(authUrl);
    }));
  }, [dispatch]);

  return <PageLoading isLoading={true} />;
};

export default AuthCallbackReceiver;
