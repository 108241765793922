import React from "react";
import { useField, Field } from "formik";
import "./xgsFormTextarea.scss";

export interface XGSFormTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  counter?: number;
  requiredAsteriskDisabled?: boolean;
  error?: string;
}

const XGSFormTextarea: React.FC<XGSFormTextareaProps> = ({ name, label, className, counter, ...props }) => {
  const meta = useField(name as string)[1];
  const { requiredAsteriskDisabled, ...restProps } = props;

  return (
    <div className="xgs-textarea">
      <div className="xgs-textarea__label">
        {label} {props.required && !requiredAsteriskDisabled && <span>*</span>}
      </div>
      {counter && meta.value && (
        <div
          className={`xgs-textarea__counter ${meta.value.length > counter ? "xgs-textarea__counter--error" : ""}`}
        >
          {meta.value.length} / {counter}
        </div>
      )}
      {name && (
        <>
          <Field
            as="textarea"
            name={name}
            className={`${className || ""} ${((meta.touched && meta.error) || props.error) ? "xgs-textarea__invalid" : ""}`}
            {...restProps}
          />
          {((meta.touched && meta.error) || props.error) && <div className="xgs-labeled-input__validation-error">{props.error || meta.error}</div>}
        </>
      )}
    </div>
  );
};

export default XGSFormTextarea;
