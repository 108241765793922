import React, { useEffect, useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import Button, { ButtonThemes } from "../../components/button/button";
import "./deliveryReceipts.scss";
import {
  deliveryReceiptsSelector,
  getDeliveryReceipts,
  getManifestDetails,
  getMasterbillDetails,
  getProbillDetails,
  resetDeliveryReceiptsState
} from "../../slices/delivery-receipts/deliveryReceiptsSlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { debounce } from "lodash";
import LabeledSelectInput from "../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useSelector } from "react-redux";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";
import { toast } from "react-toastify";

export const DeliveryReceipts = () => {
  const [manifestNumber, setManifestNumber] = useState<XGSSelectOption | null>();
  const [masterbillNumber, setMasterbillNumber] = useState<XGSSelectOption | null>();
  const [probill, setProbill] = useState<XGSSelectOption | null>();
  const deliveryReceiptsState = useSelector(deliveryReceiptsSelector);
  const dispatch = useAppDispatch();

  let probillDataFetch = (type: string, query: string) => {
    if (query?.length < 3) return;
    if (type === "PROBILL" && !/^\d{1,48}$/.test(query)) return;
    dispatch(getProbillDetails(query));
  };

  let masterbillDataFetch = (query: string) => {
    if (query.length < 3) return;
    dispatch(getMasterbillDetails(query));
  };
  let manifestDataFetch = (query: string) => {
    if (query?.length < 3) return;
    dispatch(getManifestDetails(query));
  };

  probillDataFetch = debounce(probillDataFetch, 700);
  manifestDataFetch = debounce(manifestDataFetch, 700);
  masterbillDataFetch = debounce(masterbillDataFetch, 700);

  const onStartOver = () => {
    dispatch(resetDeliveryReceiptsState());
    setManifestNumber(null);
    setProbill(null);
    setMasterbillNumber(null);
  };

  const onDownloadAgain = () => {
    if (!deliveryReceiptsState.link) return;
    onExportSuccess(deliveryReceiptsState.link);
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return;
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onExportFailed = () => {
    toast.error("Failed to export and download PDF");
  };

  const onSubmit = () => {
    dispatch(
      getDeliveryReceipts(
        { manifestNumber: manifestNumber?.value, probillNumber: probill?.label, bolNumber: masterbillNumber?.value },
        onExportSuccess,
        onExportFailed
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetDeliveryReceiptsState());
    }
  }, [dispatch])

  return (
    <ContentContainer title="Generate Delivery Receipts">
      {!(deliveryReceiptsState.requestSucceed && deliveryReceiptsState.requestCreator === "SEARCH_RECEIPTS") && (
        <div className="delivery-receipts">
          <LabeledSelectInput
            disabled={!!(masterbillNumber || manifestNumber)}
            isLoading={deliveryReceiptsState.requestStarted && deliveryReceiptsState.requestCreator === "PROBILL"}
            label="Probill Number"
            labelMode={LabelModes.column}
            autoFocus
            name="probillNumber"
            onInputChange={(query) => probillDataFetch("PROBILL", query)}
            onValueChange={(v) => setProbill(v)}
            openMenuOnClick={deliveryReceiptsState.probillSuggestions?.length > 0}
            options={deliveryReceiptsState.probillSuggestions}
            placeholder="Start typing..."
            value={probill}
            isClearable
          />
          <LabeledSelectInput
            disabled={!!(probill || manifestNumber)}
            isLoading={deliveryReceiptsState.requestStarted && deliveryReceiptsState.requestCreator === "BILL"}
            label="Masterbill Number"
            labelMode={LabelModes.column}
            autoFocus
            name="masterbillNumber"
            onInputChange={(query) => masterbillDataFetch(query)}
            onValueChange={(v) => setMasterbillNumber(v)}
            openMenuOnClick={deliveryReceiptsState.bolSuggestions?.length > 0}
            options={deliveryReceiptsState.bolSuggestions}
            placeholder="Start typing..."
            value={masterbillNumber}
            isClearable
          />
          <LabeledSelectInput
            disabled={!!(probill || masterbillNumber)}
            isLoading={deliveryReceiptsState.requestStarted && deliveryReceiptsState.requestCreator === "MANIFEST"}
            label="Manifest Number"
            labelMode={LabelModes.column}
            autoFocus
            name="manifestNumber"
            onInputChange={(query) => manifestDataFetch(query)}
            onValueChange={(v) => setManifestNumber(v)}
            openMenuOnClick={deliveryReceiptsState.manifestSuggestions?.length > 0}
            options={deliveryReceiptsState.manifestSuggestions}
            placeholder="Start typing..."
            value={manifestNumber}
            isClearable
          />
          <Button
            onClick={onSubmit}
            className="delivery-receipts__button"
            disabled={!probill && !manifestNumber && !masterbillNumber}
            theme={ButtonThemes.blue}
            type="submit"
            spinner={
              deliveryReceiptsState.requestStarted && deliveryReceiptsState.requestCreator === "SEARCH_RECEIPTS"
            }
          >
            Submit
          </Button>
        </div>
      )}
      {deliveryReceiptsState.requestSucceed && deliveryReceiptsState.requestCreator === "SEARCH_RECEIPTS" && (
        <div className="delivery-receipts__success">
          <div>
            Your download will start shortly!
            <br />
            If not, try this{" "}
            <span className="cursor-pointer blue-link" onClick={onDownloadAgain}>
              direct download link
            </span>
            .
          </div>
          <Button
            className="delivery-receipts__success__start-over-button"
            theme={ButtonThemes.blue}
            onClick={onStartOver}
          >
            Start Over
          </Button>
        </div>
      )}
    </ContentContainer>
  );
};
