import dayjs from "dayjs";
import dateFormats from "../app/data/common/dateFormats";

export {};

declare global {
  interface Date {
    toUiFormat: () => string;
    toApiFormat: () => string;
  }
}

export function initDateExtensions() {
  // eslint-disable-next-line no-extend-native
  Date.prototype.toUiFormat = function() {
    return dayjs(this).format(dateFormats.uiDate);
  };
  // eslint-disable-next-line no-extend-native
  Date.prototype.toApiFormat = function () {
    return dayjs(this).format(dateFormats.apiDate);
  };
}
