import React from "react";
import { RollDetailsResponseItem } from "../../app/data/linehaul-manifests/model";

export const RollDetailsShipmentText: React.FC<{ shipment: RollDetailsResponseItem; searchText?: string }> = ({
  shipment,
  searchText
}) => {
  if (searchText) {
    const index = shipment.probillNumber.indexOf(searchText);
    if (index >= 0) {
      const beforeMatch = shipment.probillNumber.substring(0, index);
      const match = shipment.probillNumber.substring(index, index + searchText.length);
      const afterMatch = shipment.probillNumber.substring(index + searchText.length);

      return (
        <>
          {beforeMatch}
          <strong>{match}</strong>
          {afterMatch}, {shipment.consigneeName} {shipment.consigneeAddress} {shipment.consigneeCityState}
        </>
      );
    }
  }
  return (
    <>
      {shipment.probillNumber}, {shipment.consigneeName} {shipment.consigneeAddress}{" "}
      {shipment.consigneeCityState}
    </>
  );
};
