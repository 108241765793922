import React from "react";

// import "./linkCell.scss";

export interface CheckboxProps {
  checked: boolean
}

const CheckboxCell: React.FC<CheckboxProps> = (props) => {
  return (
    <>
      {props.checked && (
        <input type='checkbox' checked={props.checked}/>
      )}
    </>
  );
};

export default CheckboxCell;
