import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { DocumentRequestModel } from "../../app/data/invoice/models";
import InvoiceService from "../../app/data/invoice/invoiceService";
import ExportService from "../../app/data/export/exportService";
import { initialInvoiceDocumentsState } from "./InvoiceDocumentsState";

const invoiceDocumentsService = InvoiceService.getInstance();
const exportService = ExportService.getInstance();

export const invoiceDocumentsSlice = createSlice({
  name: "invoiceDocuments",
  initialState: initialInvoiceDocumentsState,
  reducers: {
    clearDownloadedFile: (state) => {
      state.link = undefined;
    },
    resetInvoiceDocuments: (state) => initialInvoiceDocumentsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    storeInvoiceDocuments: (state, { payload }) => {
      state.documents = payload;
    },
    storeDownloadedFileLink: (state, { payload }) => {
      state.link = payload;
    }
  }
});

export const {
  clearDownloadedFile,
  resetInvoiceDocuments,
  requestStarted,
  requestFailed,
  requestSucceed,
  storeInvoiceDocuments,
  storeDownloadedFileLink
} = invoiceDocumentsSlice.actions;

export const invoiceDocumentsSelector = (state: IState) => {
  return state.invoiceDocuments;
};

export const getInvoiceDocuments = (
  probill: number,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("list"));
  const response = await invoiceDocumentsService.getInvoiceDocuments(probill);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeInvoiceDocuments(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadDocument = (
  documentId: number,
  type: string,
  onSuccess?: (arg: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("download"));
  const response = await invoiceDocumentsService.downloadInvoiceDocument(documentId, type);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeDownloadedFileLink(response.data));
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadAllDocuments = (
  probill: number,
  onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("download"));
  const response = await invoiceDocumentsService.downloadAllInvoiceDocuments(probill);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: "application/pdf"});
    onSuccess(blob);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadGroupDocuments = (
  request: DocumentRequestModel[],
  onSuccess: (exportLink: string) => void,
  onFailed: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GROUP_DOWNLOAD"));
  const response = await invoiceDocumentsService.downloadGroupDocuments(request);
  let exportId;
  if (response.ok()) {
    exportId = response.data.exportId;
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
  if (exportId) {
    // get link of exported file
    let attempt = 0;
    while (attempt < 20) {
      const response = await exportService.getExportLink(exportId);
      if (response.ok()) {
        if (response.data.status === "DONE") {
          dispatch(requestSucceed());
          dispatch(storeDownloadedFileLink(response.data.fileUrl));          
          onSuccess && onSuccess(response.data.fileUrl);
          break;
        }
        if (response.data.status === "FAILED") {
          dispatch(requestFailed(response.getError ? response.getError() : "Error"));
          onFailed();
          break;
        }
      } else {
        dispatch(requestFailed(response.getError ? response.getError() : "Error"));
        onFailed();
        break;
      }
      await new Promise((d) => setTimeout(d, 1000));
      attempt++;
    }
  } else {
    dispatch(requestFailed("Error"));
  }
};

const invoiceDocumentsReducer = invoiceDocumentsSlice.reducer;
export default invoiceDocumentsReducer;
