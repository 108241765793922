import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XGSIcons from "../../components/icon/xgsIcons";
import styles from "./../../styles/variables.scss";
import { xgsRoutes } from "../../app/route/RoutesConfig";

export const getFreightListColumns = () => [
  {
    Header: "Probill",
    width: 134,
    accessor: "probillNumber",
    Cell: (cellProps: any) => {
      return (
        <Link to={`${xgsRoutes.exactFreight.listing}/${cellProps.value}`}>
          {cellProps.value}
          {cellProps.row.original.exceptionCount > 0 && (
            <FontAwesomeIcon
              style={{ marginLeft: 5 }}
              title="Needs attention"
              color={styles.red}
              icon={XGSIcons.faExclamationTriangle}
              size="lg"
            />
          )}
        </Link>
      );
    }
  },
  {
    Header: "Bill date",
    accessor: "billDate",

    Cell: (cellProps: any) => (
      <>
        {cellProps.value?.toUiDateFormat()}
        {(cellProps.row.original.billDateColor === "red" || cellProps.row.original.billDateColor === "orchid") && (
          <FontAwesomeIcon
            style={{ marginLeft: 5 }}
            title="Needs attention"
            color={styles.red}
            icon={XGSIcons.faExclamationTriangle}
            size="lg"
          />
        )}
      </>
    ),
    width: 134
  },
  {
    Header: "Consignee name",
    accessor: "consigneeName",
    width: 200
  },
  {
    Header: "Shipper name",
    accessor: "shipperName",
    width: 200
  },
  {
    Header: <div className="xgs-tracking__value-center-align">ETA</div>,
    accessor: "eta",
    width: 134,
    Cell: (cellProps: any) => <>{cellProps.value?.toUiDateFormat()}</>
  },
  {
    Header: "Status",
    accessor: "status.translate"
  },
  {
    Header: "Master bill",
    accessor: "masterBillNumber",
    width: 134
  },
];

export default getFreightListColumns;
