import { InfiniteScrollBaseState } from "..";
import { ExactFreightModel, FreightSearchRequest } from "../../app/data/exact-freight/models";

export default interface ExactFreightState extends InfiniteScrollBaseState {
  count: number;
  freights: ExactFreightModel[];
  lastIds?: string;
  request: FreightSearchRequest;
}

export const initialExactFreightState: ExactFreightState = {
  loading: false,
  loadingFailed: false,
  loadingError: null,
  loadingPortion: false,
  loadedAll: false,
  loaded: false,
  request: {},
  freights: [],
  count: 0
};
