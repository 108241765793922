import { createSlice } from "@reduxjs/toolkit";
import { initialPodState } from "./PodState";
import { IState } from "..";
import { AppThunk } from "../../app/store";
import { PodService } from "../../app/data/pod/podService";

const podService = PodService.getInstance();

export const podSlice = createSlice({
  name: "pod",
  initialState: initialPodState,
  reducers: {
    resetPodState: (state) => initialPodState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    }
  }
});

export const { resetPodState, requestStarted, requestFailed, requestSucceed } = podSlice.actions;

export const podSelector = (state: IState) => state.pod;

export const addPods =
  (data: any, onSuccess: () => void, onFailed: (message?: any) => void): AppThunk =>
  async (dispatch) => {
    dispatch(requestStarted("ADD_PODS"));
    const response = await podService.addPods(data);
    if (response.ok()) {
      dispatch(requestSucceed());
      onSuccess();
    } else {
      dispatch(requestFailed(response.getError()));
      onFailed(response.getError());
    }
  };

const podReducer = podSlice.reducer;
export default podReducer;
