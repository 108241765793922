import React from "react";
import { Link } from "react-router-dom";
import CustomCell, {
  CustomCellTypes,
} from "../../components/table/custom-cells/customCell";

const getTrackShipmentColumns = (
  accountNumber: number | null | undefined,
  employee: boolean,
  showRates: boolean
) => [
  {
    Header: "Probill",
    accessor: "invoice.invoiceNumber",
    width: 90,
    Cell: (cellProps: any) => (
      <Link
        className="xgs-table-link-cell"
        to={`${accountNumber ? "/" + accountNumber : ""}/shipments/${cellProps.value}`}
      >
        {cellProps.value}
      </Link>
    )
  },
  {
    Header: (
      <div className="xgs-tracking__value-center-align">
        ETA
      </div>
    ),
    accessor: "estimatedDeliveryDate",
    width: 114,
    Cell: (cellProps: any) => (
      <div className="xgs-tracking__value-center-align">
        {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
      </div>
    )
  },
  {
    Header: "Bill date",
    accessor: "invoice.dateInvoice",
    Cell: (cellProps: any) => (
      <CustomCell
        cellType={CustomCellTypes.date}
        data={cellProps.row.original}
        value={cellProps.value}
      />
    ),
    width: 94
  },
  {
    Header: "PO / BOL",
    Cell: (cellProps: any) => (
      <>
       {cellProps.row.original.invoice.poNumber}
       <div className="xgs-tracking__values-delimiter"></div>
       {cellProps.row.original.invoice.bolNumber}
      </>
    ),
    width: 160
  },
  {
    Header: employee ? "Shipper name" : "Consignee name",
    accessor: employee ? "shipper.info" : "consignee.info"
  },
  {
    Header: (
      <div className="xgs-tracking__value-right-align">
        {showRates && (<>Rate</>)}
      </div>
    ),
    accessor: "freightCharges",
    Cell: (cellProps: any) => (
      <div className="xgs-tracking__value-right-align">
        {showRates && (
          <CustomCell
            cellType={CustomCellTypes.amount}
            selector="freightCharges"
            data={cellProps.row.original}
            value={cellProps.value}
          />
        )}
      </div>
    ),
    width: showRates ? 100 : 5
  },
  {
    Header: "Origin / Destination",
    Cell: (cellProps: any) => (
      <>
       {cellProps.row.original.origin.name}
       <div className="xgs-tracking__values-delimiter"></div>
       {cellProps.row.original.destination.name}
      </>
    ),
    width: 165
  },
  {
    Header: "Status",
    accessor: "status.translate",
    width: 110
  },
  {
    Header: "POD",
    accessor: "pod",
    Cell: (cellProps: any) => (
      <CustomCell
        cellType={CustomCellTypes.combined}
        values={[
          cellProps.row.original.pod?.date?.toUiDateFormat(),
          cellProps.row.original.pod?.time,
          cellProps.row.original.pod?.signature,
        ]}
        data={cellProps.row.original}
      />
    ),
    width: 120
  }
];

export default getTrackShipmentColumns;
