import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FiltersToggleState from "../../slices/filters-toggle/FiltersToggleState";
import {
  filtersToggleSelector,
  toggleFilters
} from "../../slices/filters-toggle/filtersToggleSlice";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";
import "./filtersToggle.scss";

const FiltersToggle: React.FC<{}> = (props) => {
  const filtersToggleState: FiltersToggleState = useSelector(
    filtersToggleSelector
  );
  const dispatch = useDispatch();

  const onClickToggleFilters = () => {
    dispatch(toggleFilters());
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1800);
  };

  return (
    <div className="xgs-filters-toggle">
      <XGSIcon
        icon={XGSIcons.faFilter}
        className="xgs-filters-toggle__icon"
        size="lg"
        title="Toggle filters"
        onClick={onClickToggleFilters}
      />
      <XGSIcon
        icon={filtersToggleState.showFilters ? XGSIcons.faSortUp : XGSIcons.faSortDown}
        className="xgs-filters-toggle__icon"
        size="lg"
        title="Toggle filters"
        onClick={onClickToggleFilters}
      />
    </div>
  );
};

export default FiltersToggle;
