export const FreightExceptionIncidentOptions = [
  { label: "Damaged freight", value: "3" },
  { label: "Misloaded freight", value: "M" },
  // todo: Why is this incident not working?
  { label: "Overaged freight", value: "O" },
  { label: "Refused-72 hour", value: "Z" },
  { label: "Refused-on hand", value: "R" },
  { label: "Shortage freight", value: "S" },
  { label: "Undeliverable as is", value: "U" }
];
