import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import { openMobileSideMenu, closeMobileSideMenu } from "../../slices/user/userCreateSlice";
import { UserState } from "../../slices";
import logo from "./../../images/logo.svg";
import menu from "./../../images/menu.svg";
import close from "./../../images/close.svg";
import { publicRoutesComponents } from "../../app/route/RoutesConfig";
import UserMenu from "./user-menu/userMenu";
import "./header.scss";

const Header: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const location = useLocation();

  const isPublicPage = () => {
    return publicRoutesComponents.find((route) => {
      return route.fixedPath && location.pathname.startsWith(route.fixedPath + '/');
    });
  };

  useEffect(() => {
    const bodyEl = document.body;
    const menuEl = document.querySelectorAll(".xgs-site__menu-wrapper")[0];
    if (!bodyEl || !menuEl) return;
    if (userState.showMobileSideMenu) {
      bodyEl.classList.add("xgs-site--menu-opened");
      menuEl.classList.add("xgs-site__menu-wrapper--opened");
    } else {
      bodyEl.classList.remove("xgs-site--menu-opened");
      menuEl.classList.remove("xgs-site__menu-wrapper--opened");
    }
  }, [dispatch, userState.showMobileSideMenu]);  

  return (
    <div className="xgs-header">
      <div
        className={`xgs-header__start ${
          (!userState?.loggedIn && !userState?.loginProcess) || isPublicPage()
            ? "xgs-header__signin"
            : ""
        } ${
          userState?.loggedIn &&
          !isPublicPage()
            ? "right-border"
            : ""
        }`}
      >
        {userState?.loggedIn && !isPublicPage() && (
          <div className="xgs-header__menu-icon__wrapper">
            {!userState.showMobileSideMenu && (
              <img className="xgs-header__menu-icon__image" src={menu} alt="Open menu" onClick={() => dispatch(openMobileSideMenu())} />
            )}
            {userState.showMobileSideMenu && (
              <img className="xgs-header__menu-icon__image" src={close} alt="Close menu" onClick={() => dispatch(closeMobileSideMenu())} />
            )}
          </div>
        )}
        <div className="xgs-header__logo">
          <NavLink to="/" onClick={() => dispatch(closeMobileSideMenu())}>
            <img className="xgs-logo" src={logo} alt="xgsi.com logo"></img>
          </NavLink>
        </div>
      </div>
      <div className="xgs-header__middle">
        <div className="xgs-header__mobile-logo">
          <NavLink to="/" onClick={() => dispatch(closeMobileSideMenu())}>
            <img className="xgs-logo" src={logo} alt="xgsi.com logo"></img>
          </NavLink>
        </div>
      </div>
      <div className="xgs-header__end">
        {userState?.loggedIn && !isPublicPage() && (
          <>
            <UserMenu />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
