import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialTrackShipmentState } from "./TrackShipmentState";
import TrackingService from "../../app/data/tracking/trackingService";
import ExportService from "../../app/data/export/exportService";
import SearchShipmentRequestModel from "../../app/data/tracking/SearchShipmentRequestModel";

const trackingService = TrackingService.getInstance();
const exportService = ExportService.getInstance();

export const trackShipmentSlice = createSlice({
  name: "trackShipment",
  initialState: initialTrackShipmentState,
  reducers: {
    resetTrackShipment: (state) => initialTrackShipmentState,
    startSearching: (state) => {
      state.loading = true;
      state.loaded = false;
      state.loadingFailed = false;
      state.loadedAll = false;
    },
    startInfiniteSearching: (state) => {
      state.loadingPortion = true;
      state.loaded = false;
      state.loadingFailed = false;
      state.loadedAll = false;
    },
    setShipments: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.loadingFailed = false;
      state.loadingPortion = false;
      state.shipments = payload.content;
      state.count = payload.count;
      state.request.lastIds = payload.scroll?.lastIds;
      state.loadedAll = !!(payload.content.length < 50);
    },
    addShipments: (state, { payload }) => {
      state.loadingPortion = false;
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.shipments = [...state.shipments, ...payload.content];
      state.count = payload.count;
      state.request.lastIds = payload.scroll.lastIds;
      state.loadedAll = !!(payload.content.length < 50);
    },
    searchFailed: (state, { payload }) => {
      state.loading = false;
      state.loadingPortion = false;
      state.loaded = false;
      state.loadingFailed = true;
      state.loadingError = payload;
    },
    setRequestFilters: (state, { payload }) => {
      state.request = {
        accountNumber: payload.accountNumber,
        consignee: payload.consignee,
        from: payload.from,
        to: payload.to,
        trackingNumber: payload.trackingNumber,
        originId: payload.originId,
        destinationId: payload.destinationId,
        lastIds: null,
        shipmentGroup: payload.shipmentGroup,
        status: payload.status,
        teamId: payload.teamId,
        masterbill: payload.masterbill
      };
    },
    searchTeamStarted: (state) => {
      state.searchTeamStarted = true;
      state.searchTeamSuccess = false;
      state.searchTeamFailed = false;
    },
    searchTeamSucceed: (state, { payload }) => {
      state.searchTeamStarted = false;
      state.searchTeamSuccess = true;
      state.searchTeamFailed = false;
      state.teams = [];
      state.teams.push(
        ...payload.map((team: any) => {
          return {
            label: team.name,
            value: team.id,
            valueForSearch: team.name
          };
        })
      );
    },
    searchTeamFailed: (state, { payload }) => {
      state.searchTeamStarted = false;
      state.searchTeamSuccess = false;
      state.searchTeamFailed = true;
      state.searchTeamError = payload;
    },
    loadingCSVStarted: (state) => {
      state.loadingCSVStarted = true;
      state.loadingCSVSuccess = false;
      state.loadingCSVFailed = false;
    },
    loadingCSVSucceed: (state) => {
      state.loadingCSVStarted = false;
      state.loadingCSVSuccess = true;
    },
    loadingCSVFailed: (state, { payload }) => {
      state.loadingCSVStarted = false;
      state.loadingCSVSuccess = false;
      state.loadingCSVFailed = true;
      state.loadingCSVError = payload;
    },
    resetCSVErrors: (state) => {
      state.loadingCSVFailed = false;
      state.loadingCSVError = null;
    },
    storeCSVLink: (state, { payload }) => {
      state.CSVLink = payload;
    }
  }
});

export const {
  resetTrackShipment,
  startSearching,
  startInfiniteSearching,
  setShipments,
  addShipments,
  searchFailed,
  setRequestFilters,
  searchTeamStarted,
  searchTeamSucceed,
  searchTeamFailed,
  loadingCSVStarted,
  loadingCSVSucceed,
  loadingCSVFailed,
  resetCSVErrors,
  storeCSVLink
} = trackShipmentSlice.actions;

export const trackShipmentSelector = (state: IState) => state.trackShipment;

export const searchShipments =
  (requestModel: SearchShipmentRequestModel, infiniteScroll?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(storeCSVLink(null));
    if (!infiniteScroll) dispatch(setRequestFilters(requestModel));
    dispatch(infiniteScroll ? startInfiniteSearching() : startSearching());
    const response = await trackingService.searchShipments(requestModel);
    if (response.ok()) {
      dispatch(infiniteScroll ? addShipments(response.data) : setShipments(response.data));
    } else {
      response.getError && dispatch(searchFailed(response.getError()));
    }
  };

export const searchTeam =
  (value: string): AppThunk =>
  async (dispatch) => {
    dispatch(searchTeamStarted());
    const response = await trackingService.searchTeams(value);
    if (response.ok()) {
      dispatch(searchTeamSucceed(response.data));
    } else {
      response.getError && dispatch(searchTeamFailed(response.getError()));
    }
  };

export const getTrackShipmentsCSV =
  (requestModel: SearchShipmentRequestModel, onSuccess: (response: any) => void): AppThunk =>
  async (dispatch) => {
    dispatch(loadingCSVStarted());
    const response = await trackingService.downloadCSV(requestModel);
    let exportId;
    if (response.ok()) {
      exportId = response.data.exportId;
    } else {
      dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
    }
    if (exportId) {
      // get link of exported file
      let attempt = 0;
      while (attempt < 200) {
        const response = await exportService.getExportLink(exportId);
        if (response.ok()) {
          if (response.data.status === "DONE") {
            dispatch(loadingCSVSucceed());
            dispatch(storeCSVLink(response.data.fileUrl));
            onSuccess && onSuccess(response.data.fileUrl);
            break;
          }
          if (response.data.status === "FAILED") {
            dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
            break;
          }
        } else {
          dispatch(loadingCSVFailed(response.getError ? response.getError() : "Error"));
          break;
        }
        await new Promise((d) => setTimeout(d, 2000));
        attempt++;
      }
    }
  };

const trackShipmentReducer = trackShipmentSlice.reducer;
export default trackShipmentReducer;
