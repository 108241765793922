export enum ChangeFreightAppointmentPath {
  invoiceNumber = "invoiceNumber"
}

export enum FreightAppointmentHistoryPath {
  invoiceNumber = "invoiceNumber"
}
export enum FreightStatusPath {
  invoiceNumber = "invoiceNumber"
}
export enum LiveFreightPath {
  invoiceNumber = "invoiceNumber"
}
export interface PathParams {
  [ChangeFreightAppointmentPath.invoiceNumber]: string;
  [FreightAppointmentHistoryPath.invoiceNumber]: string;
  [FreightStatusPath.invoiceNumber]: string;
  [LiveFreightPath.invoiceNumber]: string;
}
