import React, { useEffect, useState } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import LabeledSelectInput from "../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { useSelector } from "react-redux";
import { freightAppointmentSelector, getProbillDetails } from "../../slices/exact-freight/freightAppointmentSlice";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import { useAppDispatch } from "../../hooks/storeHooks";
import { debounce } from "lodash";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";
import { ChangeAppointment } from "./changeAppointment";
import {
  freightStatusSelector,
  getFreightStatusDetails,
  resetFreightStatusState
} from "../../slices/exact-freight/freightStatusSlice";
import { getShipmentDetails, resetShipmentDetails } from "../../slices/shipment-details/shipmentDetailsSlice";

interface XGSSelectOptionExt extends Omit<XGSSelectOption, "value"> {
  value: any;
}

export const AppointmentSummary = () => {
  const appointmentState = useSelector(freightAppointmentSelector);
  const freightDetailsState = useSelector(freightStatusSelector);
  const [probillNumber, setProbillNumber] = useState<XGSSelectOptionExt | null>();
  const dispatch = useAppDispatch();

  let probillDataFetch = (type: string, query: string) => {
    if (query?.length < 3) return;
    if (type === "PROBILL" && !/^\d{1,48}$/.test(query)) return;
    dispatch(getProbillDetails(query));
  };
  probillDataFetch = debounce(probillDataFetch, 700);

  const onChangeProbill = (v?: XGSSelectOptionExt | null) => {
    setProbillNumber(v);
  };
  useEffect(() => {
    if (probillNumber?.value) {
      dispatch(getFreightStatusDetails(probillNumber.value.probill));
      dispatch(getShipmentDetails(probillNumber.value.probill))
      return () => {
        dispatch(resetFreightStatusState());
        dispatch(resetShipmentDetails())
      };
    }
  }, [probillNumber, dispatch, freightDetailsState.needsToReload]);

  return (
    <ContentContainer title="Appointments">
      <div className="change-appointment__probill-select">
        <LabeledSelectInput
          isDisabled={false}
          isLoading={appointmentState.searchingProbills}
          label="Probill #:"
          labelMode={LabelModes.column}
          autoFocus
          name="probillNumber"
          onInputChange={(query) => probillDataFetch("PROBILL", query)}
          onValueChange={(v) => onChangeProbill(v)}
          openMenuOnClick={appointmentState.probillSuggestions?.length > 0}
          options={appointmentState.probillSuggestions}
          placeholder="Start typing..."
          required={true}
          requiredAsteriskDisabled={false}
          value={probillNumber}
        />
      </div>
      <ChangeAppointment show={!!probillNumber} onClose={() => setProbillNumber(null)} invoiceNumber={Number(probillNumber?.label)} />
    </ContentContainer>
  );
};
