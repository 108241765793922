import React from "react";
import { Link } from "react-router-dom";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import Button, { ButtonThemes } from "../../components/button/button";
import { ContentItem } from "../../templates/content-item/contentItem";

interface Props {
  manifestNumber: number;
  status: string;
}

export const InboundFreightToolbar: React.FC<Props> = ({ manifestNumber, status }) => {
  return (
    <div className="inbound-freight__toolbar">
      <ContentItem className="inbound-freight__toolbar__heading">
        Manifest: {manifestNumber !== null ? manifestNumber : ""}
      </ContentItem>

      <Link to={manifestNumber !== null ? `${xgsRoutes.shipments.tracking}?mn=${manifestNumber}` : ""}>
        <Button className="inbound-freight__toolbar__button" theme={ButtonThemes.blue}>
          Trace Manifest
        </Button>
      </Link>

      <Link to={`${xgsRoutes.inboundFreight.rollDetails}/${manifestNumber}`}>
        <Button className="inbound-freight__toolbar__button" theme={ButtonThemes.blue}>
          Roll Details
        </Button>
      </Link>
      <Link to={`${xgsRoutes.inboundFreight.updateLoadManifest}/${manifestNumber}?mode=${status.toUpperCase()}`}>
        <Button className="inbound-freight__toolbar__button" theme={ButtonThemes.blue}>
          Change Status
        </Button>
      </Link>
    </div>
  );
};
