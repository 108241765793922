import React, { useCallback, useEffect } from "react";
import ContentContainer from "../../templates/content-container/contentContainer";
import Table from "../../components/table/table";
import getFreightListColumns from "./getFreightListColumns";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import "./exactFreightList.scss";
import {
  exactFreightSelector,
  getExactFreight,
  resetExactFreight
} from "../../slices/exact-freight/exactFreightSlice";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import { FreightSearchRequest } from "../../app/data/exact-freight/models";
import TrackingFilter, { FilterConfig, FilterTypes } from "../../components/filter/filter";
import { shipmentDropdownStatuses } from "../../app/data/common/shipmentStatuses";
import { XGSSelectOption } from "../../components/xgs-select/xgsSelect";

const getStatusesOptions = () => {
  return (
    shipmentDropdownStatuses.map(
      (status): XGSSelectOption => ({
        label: status.label,
        value: status.value
      })
    ) || []
  );
};
const filterConfig: FilterConfig[] = [
  {
    label: "Probill Number",
    type: FilterTypes.number,
    key: "probillNumber",
    placeholderText: "Probill Number"
  },
  {
    label: "Masterbill Number",
    type: FilterTypes.number,
    key: "masterbill",
    placeholderText: "Masterbill Number"
  },
  {
    label: "Bill Date",
    type: FilterTypes.dateRange,
    key: "billDate",
    triggerSearch: true
  },
  {
    label: "ETA Date",
    type: FilterTypes.date,
    key: "etaTo",
    triggerSearch: true,
    placeholderText: "To"
  },
  {
    label: "Status",
    type: FilterTypes.multi_select,
    key: "statuses",
    options: getStatusesOptions(),
    triggerSearch: true
  }
];

export const ExactFreightList = () => {
  const dispatch = useAppDispatch();
  const exactFreightState = useSelector(exactFreightSelector);
  const onInfiniteScroll = () => {
    dispatch(getExactFreight(exactFreightState.request, exactFreightState.lastIds, true));
  };

  useEffect(() => {
    return () => {
      dispatch(resetExactFreight());
    };
  }, [dispatch]);

  const onSearchMemo = useCallback(
    (search: any) => {
      let from = search.billDate?.split(":")[0];
      let to = search.billDate?.split(":")[1];
      from = from ? from.toApiDateFormat() : null;
      to = to ? to.toApiDateFormat() : null;
      const searchRequest: FreightSearchRequest = {
        probillNumber: search.probillNumber,
        from,
        to,
        etaTo: search.etaTo? search.etaTo.toApiDateFormat() : null,
        statuses: search.statuses?.split(","),
        masterbill: search.masterbill
      };
      dispatch(getExactFreight(searchRequest));
    },
    [dispatch]
  );
  return (
    <ContentContainer
      header={<TrackingFilter filtersConfig={filterConfig} onClear={() => onSearchMemo({})} onSearch={onSearchMemo} />}
      title="Live Freight Tracking"
    >
      <div className="exact-freight-list">
        {!exactFreightState.loadingFailed && (
          <>
            <Table
              isLoading={exactFreightState.loading}
              data={exactFreightState.freights}
              columns={getFreightListColumns()}
              infiniteScroll={true}
              infiniteScrollLoading={exactFreightState.loadingPortion}
              infiniteScrollHasNext={!exactFreightState.loadedAll}
              onInfiniteScroll={onInfiniteScroll}
              rowHeight={82}
              minTableHeight={420}
            />
          </>
        )}
        {exactFreightState.loadingFailed && <XGSErrorMessage>{exactFreightState.loadingError}</XGSErrorMessage>}
      </div>
    </ContentContainer>
  );
};
