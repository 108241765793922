import api, { ApiResponse } from "../api";

export class PodService {
  private static instance: PodService;
  private constructor() {}
  static getInstance(): PodService {
    if (!PodService.instance) {
      PodService.instance = new PodService();
    }
    return PodService.instance;
  }

  public addPods = async (data: any): Promise<ApiResponse<any>> => {
    return await api.post(`/pod`, data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };
}
