import axios from "axios";
import api, { ApiResponse } from "./../api";

class RecordsService {
  private source: any;
  private static instance: RecordsService;
  private constructor() {}
  static getInstance(): RecordsService {
    if (!RecordsService.instance) {
      RecordsService.instance = new RecordsService();
    }
    return RecordsService.instance;
  }

  public getRecords = async (
    payload: any,
    lastIds?: string
  ): Promise<ApiResponse<any>> => {
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    return await api.post(`/delivery-records${lastIds ? "?lastIds=" + lastIds : ""}`, payload, {
      cancelToken: this.source.token
    });
  };

  public submitRecord = async (
    data: any
  ): Promise<ApiResponse<any>> => {
    return await api.post("/delivery-records", data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };

  public searchProbills = async (
    query: string
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/invoice-search/invoices?search=${encodeURIComponent(query)}`);
  };

  public removeRecord = async (
    recordId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/delivery-records/${recordId}`);
  };

  public updateRecord = async (
    recordId: string,
    request: any
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/delivery-records/${recordId}`, request);
  };

  public removePhoto = async (
    photoId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/delivery-records/photos/${photoId}`);
  };

  public updatePhoto = async (
    photoId: string,
    request: any
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/delivery-records/photos/${photoId}`, request);
  };
};

export default RecordsService;
