import React from "react";
import { useSelector } from "react-redux";
import Table from "../../../../components/table/table";
import { CustomCellTypes } from "../../../../components/table/custom-cells/customCell";
import { InvoiceDetailsItem } from "../../../../app/data/invoice/models";
import { ContentItem, ContentItemTitle } from "../../../../templates/content-item/contentItem";
import { userSelector } from "../../../../slices/user/userSlice";
import { UserState } from "../../../../slices";
import { UserUtils } from "../../../../app/data/user/userUtils";
import "../invoiceDetails.scss";

export interface InvoiceDetailsTableProps {
  items?: InvoiceDetailsItem[];
  invoiceAmount?: number;
  select?: boolean;
  selectedItems?: Set<string>;
  onClickSelect?: any;
  title?: string;
}

const parseProbills = (descriptionItem: string) => {
  if (!descriptionItem) return null;
  const probillRegex = /(\b\d{6,8}\b(\b|$))/gi;
  let descriptionItemBlocks = descriptionItem.split(probillRegex);
  let result = descriptionItemBlocks.map((block) => {
    if (block.match(probillRegex)) {
      return (
        <a
          href={"/shipments/" + block}
          target="_blank"
          className="xgs-invoice-details__table__link"
          rel="noopener noreferrer"
        >
          {block}
        </a>
      );
    } else {
      return block;
    }
  });
  return result;
};

const InvoiceDetailsTable: React.FC<InvoiceDetailsTableProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const selectColumn = {
    Header: "Select",
    accessor: "checked",
    width: 80,
    Cell: (cellProps: any) => {
      return (
        <input
          checked={props.selectedItems?.has(cellProps.row.original.serialNumber)}
          onChange={() => props.onClickSelect(cellProps.row.original.serialNumber)}
          className="xgs-invoice-details__table__checkbox"
          type="checkbox"
        />
      );
    }
  };
  const columns = [
    ...(props.select
      ? [
          {
            Header: "Serial Number",
            accessor: "serialNumber"
          }
        ]
      : []),
    {
      Header: <div style={{ textAlign: "center" }}>CC</div>,
      accessor: "commodityCode",
      width: 50,
      Cell: (cellProps: any) => <div style={{ textAlign: "center" }}>{cellProps.value}</div>
    },
    {
      width: 288,
      Header: "Description",
      accessor: "description",
      Cell: (cellProps: any) => parseProbills(cellProps.value)
    },
    ...(!props.select
      ? [
          {
            Header: <div style={{ textAlign: "right" }}>Pieces</div>,
            accessor: "pieces",
            width: 60,
            Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>
          },
          {
            Header: "Units",
            accessor: "units",
            width: 60
          },
          {
            grow: 0,
            Header: (
              <div style={{ textAlign: "right" }}>
                <span title="Quantity">Qty</span>
              </div>
            ),
            accessor: "quantity",
            Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>,
            width: 100
          },
          {
            Header: <div style={{ textAlign: "right" }}>Weight</div>,
            accessor: "weight",
            Cell: (cellProps: any) => <div style={{ textAlign: "right" }}>{cellProps.value}</div>,
            width: 100
          },
          {
            Header: (
              <div style={{ textAlign: "right" }}>
                {UserUtils.hasPermission(userState.profile || null, null, "PAYMENT") && <>Rate</>}
              </div>
            ),
            accessor: "rate",
            Cell: (cellProps: any) => (
              <div style={{ textAlign: "right" }}>
                <>{cellProps.value}</>
              </div>
            ),
            width: 100
          }
        ]
      : [])
  ];

  return (
    <ContentItem className="xgs-invoice-details__table">
      <ContentItemTitle className="xgs-invoice-details__table__title">{props?.title ? props.title : "Probill Invoice Details"}</ContentItemTitle>
      {props.items && (
        <Table
          columns={props.select ? [selectColumn, ...columns] : columns}
          data={props.items}
          totals={
            UserUtils.hasPermission(userState.profile || null, null, "PAYMENT")
              ? [
                  {
                    title: "Total Charges",
                    type: CustomCellTypes.amount,
                    value: props.invoiceAmount || "0"
                  }
                ]
              : undefined
          }
        />
      )}
    </ContentItem>
  );
};

export default InvoiceDetailsTable;
