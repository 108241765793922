import React, { useRef, useEffect, ReactNode, memo } from "react";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import Loading from "../../components/loading/loading";
import "./slideOutSidebar.scss";

export interface SlideOutSidebarProps {
  className?: string;
  header: string;
  onClose?: () => void;
  onClickOutside?: () => void;
  show: boolean;
  spinner?: boolean;
  children?: ReactNode;
}

const SlideOutSidebar: React.FC<SlideOutSidebarProps> = memo(
  ({ header, onClose, onClickOutside, show, ...props }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          // if (onClose) {
          //   onClose && onClose();
          // }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    return (
      <div
        ref={ref}
        className={`
        xgs-slide-out-sidebar
        ${show ? " xgs-slide-out-sidebar--opened" : " xgs-slide-out-sidebar--closed"}
        ${props.className ? ` ${props.className}` : ""}`}
      >
        <div className="xgs-slide-out-sidebar__header">
          <div>
            {header}
            {props.spinner && (
              <div className="xgs-slide-out-sidebar__spinner">
                <Loading isLoading={true} />
              </div>
            )}
          </div>
          <div className="xgs-slide-out-sidebar__close">
            <XGSIcon icon={XGSIcons.faTimes} size="lg" onClick={onClose && onClose} />
          </div>
        </div>
        {show && <div className="xgs-slide-out-sidebar__content">{props.children}</div>}
      </div>
    );
  }
);

export default SlideOutSidebar;
