import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { BolRequestModel } from "../../app/data/bol/models";
import BolService from "../../app/data/bol/bolService";
import { initialBolState } from "./BolState";

const bolService = BolService.getInstance();

export const bolSlice = createSlice({
  name: "bol",
  initialState: initialBolState,
  reducers: {
    resetBolState: (state) => initialBolState,
    setBolFromRequestRates: (state, { payload }) => {
      Object.assign(state, initialBolState);
      state.fromRequestRates = true;
      state.quoteNumber = payload.quoteNumber;
      for (const item of payload.items) {
        state.items.push({
          units: item.pieces,
          ratingClass: item.freightClass,
          description: item.description,
          notes: "",
          yards: item.yards,
          weight: item.weight,
          deleted: false
        });
      }
    },
    removeBolFromRequestRatesFlag: (state) => {
      state.fromRequestRates = false;
    },
    setBolShipper: (state, { payload }) => {
      state.shipper = Number(payload.shipper);
      state.bolNumber = payload.bolNumber;
      state.poNumber = payload.poNumber;
      state.payType = payload.payType;
      state.specialInstructions = payload.specialInstructions;
      state.pickupRequest = payload.pickupRequest;
      state.willCall = payload.willCall;
      if (payload.pickupRequest) {
        state.pickupRequestDate = payload.pickupRequestDate;
        state.pickupRequestTime = payload.pickupRequestTime;
      } else {
        state.pickupRequestDate = "";
        state.pickupRequestTime = "";
      }
    },
    setBolConsignee: (state, { payload }) => {
      state.consignee.name = payload.name;
      state.consignee.address.address1 = payload.address?.address1;
      state.consignee.address.city = payload.address?.city;
      state.consignee.address.postalCode = payload.address?.postalCode;
      state.consignee.address.state = payload.address?.state;
      state.consignee.phone = payload.phone;
    },
    setBolPayor: (state, { payload }) => {
      if (!state.payor) {
        state.payor = {
          name: payload.name,
          address: {
            address1: payload.address?.address1,
            city: payload.address?.city,
            postalCode: payload.address?.postalCode,
            state: payload.address?.state
          },
          phone: payload.phone
        };
      } else {
        state.payor.name = payload.name;
        state.payor.address.address1 = payload.address?.address1;
        state.payor.address.city = payload.address?.city;
        state.payor.address.postalCode = payload.address?.postalCode;
        state.payor.address.state = payload.address?.state;
        state.payor.phone = payload.phone;
      }
    },    
    addItem: (state, { payload }) => {
      state.items.push({
        units: parseFloat(payload.units),
        ratingClass: payload.ratingClass,
        description: payload.description,
        notes: payload.notes,
        yards: payload.yards,
        weight: parseFloat(payload.weight),
        deleted: false
      });
    },
    updateItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].units = payload.data.units;
      state.items[i].ratingClass = payload.data.ratingClass;
      state.items[i].description = payload.data.description;
      state.items[i].notes = payload.data.notes;
      state.items[i].yards = payload.data.yards;
      state.items[i].weight = payload.data.weight;
    },
    deleteItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    clearDeletedItems: (state) => {
      state.items = state.items.filter(item => !item.deleted);
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    fetchStarted: (state, { payload }) => {
      if (payload) {
        state.fetchPortionStarted = true;
      } else {
        state.fetchStarted = true;
      }
      state.fetchSucceed = false;
      state.fetchFailed = false;
      state.fetchError = "";
      state.bolsFetchedAll = false;
    },
    fetchSucceed: (state) => {
      state.fetchSucceed = true;
      state.fetchStarted = false;
      state.fetchPortionStarted = false;
      state.fetchFailed = false;
    },
    fetchFailed: (state, { payload }) => {
      state.fetchFailed = true;
      state.fetchSucceed = false;
      state.fetchStarted = false;
      state.fetchError = payload;
    },
    setBols: (state, { payload }) => {
      state.bols = payload.content;
      state.bolsRequest = payload.scroll;
      state.bolsFetchedAll = !!(payload.content.length < 50);
    },
    addBols: (state, { payload }) => {
      state.bols = [...state.bols, ...payload.content];
      state.bolsRequest = payload.scroll;
      state.bolsFetchedAll = !!(payload.content.length < 50);
    },
    setShippers: (state, { payload }) => {
      state.shippers = payload;
    },
    setConsignees: (state, { payload }) => {
      state.consignees = payload;
    },
    setPickupAvailability: (state, { payload }) => {
      state.pickupAvailable = payload;
      if (!payload) {
        state.pickupRequest = false;
      }      
    }
  }
});

export const {
  resetBolState,
  setBolFromRequestRates,
  removeBolFromRequestRatesFlag,
  setBolShipper,
  setBolConsignee,
  setBolPayor,
  addItem,
  updateItem,
  deleteItem,
  clearDeletedItems,
  requestStarted,
  requestSucceed,
  requestFailed,
  fetchStarted,
  fetchSucceed,
  fetchFailed,
  setBols,
  addBols,
  setShippers,
  setConsignees,
  setPickupAvailability
} = bolSlice.actions;

export const bolSelector = (state: IState) => {
  return state.bol;
};

export const getBols = (
  types: string[],
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(fetchStarted(!!lastIds));
  // const response = await bolService.getBols(types, (lastIds && lastIds));
  const response = await bolService.getBols((lastIds && lastIds) );
  if (response.ok()) {
    dispatch(fetchSucceed());
    dispatch(lastIds ? addBols(response.data) : setBols(response.data));
  } else {
    dispatch(fetchFailed(response.getError && response.getError()));
  }
};

export const getShippers = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("shippers"));
  const response = await bolService.getShippers();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShippers(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const getConsignees = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("consignees"));
  const response = await bolService.getConsignees();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setConsignees(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const checkPickup = (
  zip: string,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(setPickupAvailability(false));
  dispatch(requestStarted("CHECK_PICKUP"));
  const response = await bolService.checkPickup(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickupAvailability(true));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
    dispatch(setPickupAvailability(false));
    onFailed && onFailed();
  }
};

export const createBol = (
  request: BolRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("save"));
  const response = await bolService.createBol(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const downloadDocument = (
  probill: string,
  type: string,
  onSuccess: (arg: Blob) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(`download-${type}`));
  const response = await bolService.downloadDocument(probill, type);
  if (response.ok()) {
    dispatch(requestSucceed());
    let blob: Blob = new Blob([response.data], {type: "application/pdf"});
    onSuccess(blob);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const bolReducer = bolSlice.reducer;
export default bolReducer;
