import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import { useParams } from "react-router-dom";
import ContentContainer from "../../templates/content-container/contentContainer";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import Loading from "../../components/loading/loading";
import { getRollDetails, getRollDetailsCSV, resetRollDetails, rollDetailsSelector } from "../../slices/roll-details/rollDetailsSlice";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import "./rollDetails.scss";
import "../../components/accordion/accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { xgsRoutes } from "../../app/route/RoutesConfig";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import { RollDetailsShipmentText } from "./getShipmentText";
import XGSIcons from "../../components/icon/xgsIcons";
import LabeledMaskInput from "../../components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import XGSIcon from "../../components/icon/xgsIcon";

interface RollDetailsSearch {
  probillNumber?: string;
}

const initialRollDetailsSearch: RollDetailsSearch = { probillNumber: "" };

export const RollDetails = () => {
  const rollDetailsState = useSelector(rollDetailsSelector);
  const params = useParams();
  const manifestNumber = useMemo(() => parseInt(params.manifestNumber as string, 10), [params]);
  const dispatch = useAppDispatch();

  const fetchCSV = () => {
    if (rollDetailsState.CSVLink) {
      onExportSuccess(rollDetailsState.CSVLink);
    } else {
      dispatch(getRollDetailsCSV(manifestNumber, onExportSuccess));
    }
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return;
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(getRollDetails(manifestNumber));
    return () => {
      dispatch(resetRollDetails());
    };
  }, [manifestNumber, dispatch]);

  const [search, setSearch] = useState(initialRollDetailsSearch);

  let filteredFreights = rollDetailsState.details.filter((shipment) => {
    if (search.probillNumber) return shipment.probillNumber.includes(search.probillNumber);
    return true;
  })

  const accordionItemIds = useMemo(() => rollDetailsState.details.map((_, idx) => `shipment-${idx}`), [rollDetailsState.details])

  return (
    <ContentContainer isLoading={rollDetailsState.loading} title={"Roll Details: " + manifestNumber}>
      {!rollDetailsState.loadingFailed && <div
        className="roll-details__download-csv xgs-site__content-container__toolbar__buttons__item"
        onClick={() => {
          !rollDetailsState.loadingCSVStarted && fetchCSV();
        }}
      >
        {!rollDetailsState.loadingCSVStarted && (
          <XGSIcon
            icon={XGSIcons.faFileDownload}
            size="lg"
            className="xgs-site__content-container__toolbar__buttons__item__icon"
          />
        )}
        {rollDetailsState.loadingCSVStarted && (
          <Loading
            isLoading={rollDetailsState.loadingCSVStarted}
            className="xgs-site__content-container__toolbar__buttons__item__icon roll-details__download-csv__spinner"
          />
        )}
        <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">
          Download CSV
        </span>
      </div>}
      {rollDetailsState.loadingError && <XGSErrorMessage>{rollDetailsState.loadingError}</XGSErrorMessage>}
      {!rollDetailsState.loadingFailed && <>
        <LabeledMaskInput
          clearable
          onClear={() => setSearch(initialRollDetailsSearch)}
          allowNegative={false}
          format="########"
          label="Probill number:"
          value={search.probillNumber}
          className="roll-details__search__probill"
          labelMode={LabelModes.column}
          placeholder="Probill number"
          onValueChange={(value: any) => {
            setSearch((search) => ({ ...search, probillNumber: value }));
          }}
        />
        <div className={rollDetailsState.loading ? "" : "roll-details__table-wrapper"}>
          <>
            {rollDetailsState.loading && (
              <div className="xgs-table__loading" style={{ maxWidth: "100%" }}>
                <Loading isLoading={true} />
              </div>
            )}
            {!rollDetailsState.loading && !filteredFreights.length && <div className="roll-details__no-item-message">No probills found</div>}
            {!rollDetailsState.loading && !!filteredFreights.length && (
              <div className="roll-details__accordion-wrapper">
                <Accordion preExpanded={accordionItemIds}  allowZeroExpanded allowMultipleExpanded>
                  {filteredFreights
                    .map((shipment, index) => (
                      <AccordionItem uuid={`shipment-${index}`} key={`shipment-${index}`}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <RollDetailsShipmentText shipment={shipment} searchText={search.probillNumber} />
                            <a
                              href={`${xgsRoutes.shipments.tracking}/${shipment.probillNumber}`}
                              target="_blank"
                              className="roll-details__icon-link"
                              title="Go to link"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon
                                icon={XGSIcons.faExternalLink}
                                className="roll-details__link-icon"
                              />
                            </a>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <table className="roll-details__table">
                            <thead>
                              <tr style={{ height: 50, paddingLeft: 4 }} className="roll-details__table__headers">
                                <th className="roll-details__th roll-details__th__roll">Roll</th>
                                <th className="roll-details__th roll-details__th__desc">Sidemark</th>
                                <th className="roll-details__th">PO</th>
                                <th className="roll-details__th">BOL</th>
                                <th className="roll-details__th roll-details__th__style">Style</th>
                                <th className="roll-details__th roll-details__th__color">Color</th>
                                <th className="roll-details__th roll-details__th__dyelot">Dyelot</th>
                                <th className="roll-details__th roll-details__th__quantity">Qty</th>
                                <th className="roll-details__th roll-details__th__dimensions">Wid. x len.</th>
                                <th className="roll-details__th roll-details__th__yards">Sq. yards</th>
                                <th className="roll-details__th roll-details__th__quantity">Weight</th>
                              </tr>
                            </thead>
                            <tbody>
                              {shipment.items.map((item, itemIndex) => (
                                <tr
                                  key={`item-${itemIndex}`}
                                  style={{ height: 50, paddingLeft: 4 }}
                                  className="roll-details__item-row"
                                >
                                  <td className="roll-details__td roll-details__td__roll">{item.rollNumber}</td>
                                  <td className="roll-details__td roll-details__td__desc">{item.description}</td>
                                  <td className="roll-details__td">{item.poNumber}</td>
                                  <td className="roll-details__td">{item.bolNumber}</td>
                                  <td className="roll-details__td roll-details__td__style">{item.style}</td>
                                  <td className="roll-details__td roll-details__td__color">{item.color}</td>
                                  <td className="roll-details__td roll-details__td__dyelot">{item.dyelot}</td>
                                  <td className="roll-details__td roll-details__td__quantity">{item.quantity}</td>
                                  <td className="roll-details__td roll-details__td__dimensions">{item.width + "x" + item.length}</td>
                                  <td className="roll-details__td roll-details__td__yards">{item.squareYards}</td>
                                  <td className="roll-details__td roll-details__td__weight">{item.weight}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                </Accordion>
              </div>
            )}
          </>
        </div>
      </>}
    </ContentContainer>
  );
};

export default RollDetails;
