import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialServiceCentersState } from "./ServiceCentersState";
import TrackingService from "../../app/data/tracking/trackingService";

const trackingService = TrackingService.getInstance();

export const serviceCentersSlice = createSlice({
  name: "serviceCenters",
  initialState: initialServiceCentersState,
  reducers: {
    resetServiceCenters: (state) => initialServiceCentersState,
    serviceCentersLoadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
    },
    serviceCentersLoadingSucceed: (state, { payload }) => {
      state.loading = false;
      state.loaded = true;
      state.origins = payload.origins;
      state.destinations = payload.destinations;
    },
    serviceCentersLoadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.origins = [];
      state.destinations = [];
    },
  },
});

export const {
  resetServiceCenters,
  serviceCentersLoadingStarted,
  serviceCentersLoadingSucceed,
  serviceCentersLoadingFailed,
} = serviceCentersSlice.actions;

export const serviceCentersSelector = (state: IState) => state.serviceCenters;

export const getServiceCenters = (
): AppThunk => async (dispatch) => {
  dispatch(serviceCentersLoadingStarted());

  const response = await trackingService.getServiceCenters();

  if (response.ok()) {
    dispatch(serviceCentersLoadingSucceed(response.data));
  } else {
    dispatch(serviceCentersLoadingFailed());
  }
};

const serviceCentersReducer = serviceCentersSlice.reducer;
export default serviceCentersReducer;
