import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InvoiceDocumentsState from "../../../../slices/invoice-documents/InvoiceDocumentsState";
import {
  downloadAllDocuments,
  downloadDocument,
  getInvoiceDocuments,
  invoiceDocumentsSelector,
  resetInvoiceDocuments,
  clearDownloadedFile
} from "../../../../slices/invoice-documents/invoiceDocumentsSlice";
import { ContentItem, ContentItemTitle } from "../../../../templates/content-item/contentItem";
import InfoBlock from "../../../../templates/info-block/infoBlock";
import Loading from "../../../../components/loading/loading";
import "./invoiceDetailsDocuments.scss";
import papersImage from "../../../../images/papers.svg";
import { useAppDispatch } from "../../../../hooks/storeHooks";

export interface InvoiceDetailsDocumentsProps {
  invoiceNumber: number;
}

const documentTypes = [
  {
    name: "BOL",
    title: "BOL"
  },
  {
    name: "INV",
    title: "Invoice"
  },
  {
    name: "ATH",
    title: "ATH"
  },
  {
    name: "POD",
    title: "POD"
  }
];

const InvoiceDetailsDocuments: React.FC<InvoiceDetailsDocumentsProps> = (props) => {
  const invoiceDocumentsState: InvoiceDocumentsState = useSelector(invoiceDocumentsSelector);
  const dispatch = useAppDispatch();
  const [currentDownloadId, setCurrentDownloadId] = useState<number | null>(null);

  const onClickDownload = (id: number, documentType: string, fileType: string) => {
    setCurrentDownloadId(id);
    dispatch(
      downloadDocument(id, fileType, (link: string) => {
        let documentWindow = window.open(link, "_blank");
        if (documentWindow === null || typeof documentWindow === "undefined") {
          // if new window was blocked by a system
          toast.error("To view the document, you must turn off the pop-up blocking in your browser.", {
            autoClose: 7000
          });
        }
        setCurrentDownloadId(null);
        dispatch(clearDownloadedFile());
      })
    );
  };

  const onClickDownloadAll = () => {
    dispatch(
      downloadAllDocuments(props.invoiceNumber, (file: Blob) => {
        let documentfileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = documentfileURL;
        link.download = `${props.invoiceNumber}_documents.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // mixpanel.track("All invoice's documents were downloaded (PDF)");
      })
    );
  };

  useEffect(() => {
    if (props.invoiceNumber) dispatch(getInvoiceDocuments(props.invoiceNumber));
  }, [dispatch, props.invoiceNumber]);

  useEffect(() => {
    if (invoiceDocumentsState.requestStarted) return;
    invoiceDocumentsState.requestFailed &&
      toast.error(invoiceDocumentsState.requestError || "Error. Please try later or contact support.");
  }, [
    invoiceDocumentsState.requestStarted,
    invoiceDocumentsState.requestFailed,
    invoiceDocumentsState.requestError
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceDocuments());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ContentItem className="xgs-invoice-documents">
      <ContentItemTitle>Probill Invoice Documents</ContentItemTitle>
      <InfoBlock title="">
        {invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "list" && (
          <div className="xgs-invoice-documents__loading">
            <Loading isLoading={true} />
          </div>
        )}
        {(invoiceDocumentsState.requestSucceed || invoiceDocumentsState.documents) && (
          <>
            {(!invoiceDocumentsState.documents || invoiceDocumentsState.documents.length === 0) && (
              <div className="xgs-invoice-documents__no-items">There are no imaged documents.</div>
            )}
            <div className="xgs-invoice-documents__items">
              {invoiceDocumentsState.documents && invoiceDocumentsState.documents.length > 1 && (
                <div className="xgs-invoice-documents__item xgs-invoice-documents__item--separated">
                  <div className="xgs-invoice-documents__item__header">All Documents</div>
                  <div className="xgs-invoice-documents__item__image xgs-invoice-documents__item__image--fixed-width">
                    <img
                        className="xgs-invoice-documents__item__all__icon"
                        src={papersImage}
                        alt=""
                      />
                    {invoiceDocumentsState.requestStarted &&
                      invoiceDocumentsState.requestCreator === "download" &&
                      !currentDownloadId && (
                        <div className="xgs-invoice-documents__item__loading xgs-invoice-documents__item__loading--offset">
                          <Loading isLoading={true} />
                        </div>
                      )}
                  </div>
                  <div
                    className={`xgs-invoice-documents__item__links ${
                      invoiceDocumentsState.requestStarted && invoiceDocumentsState.requestCreator === "download"
                        ? "xgs-invoice-documents__item__links--disabled"
                        : ""
                    }`}
                  >
                    <span
                      className="xgs-invoice-documents__item__link"
                      onClick={() => {
                        !invoiceDocumentsState.requestStarted && onClickDownloadAll();
                      }}
                    >
                      PDF
                    </span>
                  </div>
                </div>
              )}
              {invoiceDocumentsState.documents &&
                invoiceDocumentsState.documents.length > 0 &&
                invoiceDocumentsState.documents.map((doc) => (
                  <div className="xgs-invoice-documents__item" key={doc.id}>
                    {doc.type !== "INV" && (
                      <>
                        <div className="xgs-invoice-documents__item__header">
                          {documentTypes.find((type) => type.name === doc.type)?.title}
                        </div>
                        <div className="xgs-invoice-documents__item__image">
                          {doc.thumbnail && (
                            <img
                              src={`data:image/jpeg;base64,${doc.thumbnail}`}
                              alt={documentTypes.find((type) => type.name === doc.type)?.title}
                            />
                          )}
                          {!doc.thumbnail && (
                            <div className="xgs-invoice-documents__item__image__placeholder">
                              <div>No Preview Available</div>
                            </div>
                          )}
                          {invoiceDocumentsState.requestStarted &&
                            invoiceDocumentsState.requestCreator === "download" &&
                            doc.id === currentDownloadId && (
                              <div className="xgs-invoice-documents__item__loading">
                                <Loading isLoading={true} />
                              </div>
                            )}
                        </div>
                        <div
                          className={`xgs-invoice-documents__item__links ${
                            invoiceDocumentsState.requestStarted &&
                            invoiceDocumentsState.requestCreator === "download"
                              ? "xgs-invoice-documents__item__links--disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="xgs-invoice-documents__item__link"
                            onClick={() => {
                              !invoiceDocumentsState.requestStarted && onClickDownload(doc.id, doc.type, "pdf");
                            }}
                          >
                            PDF
                          </span>{" "}
                          |{" "}
                          <span
                            className="xgs-invoice-documents__item__link"
                            onClick={() => {
                              !invoiceDocumentsState.requestStarted && onClickDownload(doc.id, doc.type, "jpg");
                            }}
                          >
                            JPG
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
        {invoiceDocumentsState.requestFailed && invoiceDocumentsState.requestCreator === "list" && (
          <div className="xgs-invoice-documents__error">
            {invoiceDocumentsState.requestError || "Error while loading documents."}
          </div>
        )}
      </InfoBlock>
    </ContentItem>
  );
};

export default InvoiceDetailsDocuments;
