import api, { ApiResponse } from "../api";
import { FreightException, FreightExceptionSearchRequest } from "./models";

export class FreightExceptionService {
  private static instance: FreightExceptionService;
  private constructor() {}
  static getInstance(): FreightExceptionService {
    if (!FreightExceptionService.instance) {
      FreightExceptionService.instance = new FreightExceptionService();
    }
    return FreightExceptionService.instance;
  }

  public addFreightException = async (data: any): Promise<ApiResponse<any>> => {
    return await api.post(`/freight-exceptions`, data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };

  public search = async (request: FreightExceptionSearchRequest): Promise<ApiResponse<FreightException[]>> => {
    return await api.post(`/freight-exceptions/search`, request);
  };
}
