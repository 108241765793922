import api, { ApiResponse } from "../api";
import { DeliveryReceiptsSearchRequest } from "./models";

class DeliveryReceiptsService {
  private static instance: DeliveryReceiptsService;

  private constructor() {}

  static getInstance(): DeliveryReceiptsService {
    if (!DeliveryReceiptsService.instance) {
      DeliveryReceiptsService.instance = new DeliveryReceiptsService();
    }

    return DeliveryReceiptsService.instance;
  }

  public getDeliveryReceipts = async (request: DeliveryReceiptsSearchRequest): Promise<ApiResponse<any>> => {
    return await api.post("/delivery-receipts", request);
  };
}

export default DeliveryReceiptsService;
