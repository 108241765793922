import React from "react";
import { Link } from "react-router-dom";
import CustomCell, { CustomCellTypes } from "../../components/table/custom-cells/customCell";

export const MaxPodRowsSelectable = 20
const getPODInformationColumns = (onClickSelect: any, onClickSelectAll: any, selectedRows: Set<number>) => {
  return [
    {
      Header: <div className="xgs-pod-information__select-all xgs-form__checkbox">
        <input
          className="xgs-form__checkbox"
          // disabled={! && selectedRows?.size >= MaxPodRowsSelectable}
          checked={!!selectedRows.size}
          onChange={() => onClickSelectAll()}
          style={{ height: 30 }}
          type="checkbox"
        />

      </div>,
      accessor: "checked",
      width: 80,
      Cell: (cellProps: any) => {
        let checked = selectedRows?.has(cellProps.row.original.invoice.invoiceNumber);
        return (
          <div style={{textAlign: "center"}} className="xgs-form__checkbox">
            <input
              disabled={!checked && selectedRows?.size >= MaxPodRowsSelectable}
              checked={checked}
              onChange={() => onClickSelect(cellProps.row.original.invoice.invoiceNumber)}
              style={{ height: 30 }}
              type="checkbox"
            />
          </div>
        );
      }
    },
    {
      Header: "Probill",
      accessor: "invoice.invoiceNumber",
      width: 90,
      Cell: (cellProps: any) => (
        <Link className="xgs-table-link-cell" to={`/shipments/${cellProps.value}`}>
          {cellProps.value}
        </Link>
      )
    },
    {
      Header: "Masterbill",
      accessor: "masterbillNumber",
      width: 90,
      Cell: (cellProps: any) => (
        <>{cellProps.value}</>
      )
    },

    {
      Header: "Expected Delivery Date",
      accessor: "estimatedDeliveryDate",
      width: 170,
      Cell: (cellProps: any) => (
        <div className="xgs-tracking__value-center-align">
          {cellProps.value ? cellProps.value.toUiDateFormat() : ""}
        </div>
      )
    },
    {
      Header: "Consignee name",
      accessor: "consignee.info",
      Cell: (cellProps: any) => <>{cellProps.value}</>,
      width: 200
    },
    {
      Header: "Origin / Destination",
      Cell: (cellProps: any) => (
        <>
          {cellProps.row.original.origin.name}
          <div className="xgs-tracking__values-delimiter"></div>
          {cellProps.row.original.destination.name}
        </>
      ),
      width: 165
    },
    {
      Header: "Status",
      accessor: "status.translate",
      width: 110
    },
    {
      Header: <div style={{ textAlign: "left" }}>POD Document</div>,
      accessor: "pod",
      Cell: (cellProps: any) => {
        return (
          <CustomCell
            cellType={CustomCellTypes.combined}
            data={cellProps.row.original}
            values={[cellProps.value.documentAddedDate?.toUiDateFormat(), cellProps.value.documentAddedTime?.toUiTimeFormat()]}
          />
        );
      }
    }
  ];
};

export default getPODInformationColumns;
