import axios, { AxiosResponse, AxiosInstance } from "axios";

export interface ApiError {
  errors: { message: string }[];
  formErrors: object;
  id: string;
  type: string;
  message?: string;
};

export interface ApiResponse<T> extends AxiosResponse<T> {
  ok: () => boolean;
  getError: () => string | object | null;
  getFullError: () => object | null;
};

class Api {
  private static instance: AxiosInstance;
  private constructor() {};

  private static refreshToken = () => {
    const request = {
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_SSO_CLIENT_ID || "",
      scope: "openid",
      refresh_token: localStorage.getItem("xgs-agent-refresh-token") || ""
    };
    const params = new URLSearchParams(request).toString();
    return Api.instance.post(process.env.REACT_APP_TOKEN_ENDPOINT || "", params, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
    });
  };

  public static getInstance = () => {
    if (!Api.instance) {
      Api.instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        withCredentials: true
      });

      Api.instance.interceptors.request.use(
        config => {
          config.headers.Authorization = localStorage.getItem("xgs-agent-access-token") ? "Bearer " + localStorage.getItem("xgs-agent-access-token") : "none";
          return config;
        },
        error => Promise.reject(error)
      )

      Api.instance.interceptors.response.use(
        (response) => {
          const apiResponse = response as ApiResponse<typeof response.data>;

          apiResponse.ok = () => {
            return apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.status === 204;
          };

          return apiResponse;
        },
        async (error) => {
          const apiResponse = error.response as ApiResponse<ApiError>;
          const originalConfig = error.config;
          if (!apiResponse) {
            return { ok: () => false };
          } else {
            if (apiResponse.status === 401 && !originalConfig._retry && localStorage.getItem("xgs-agent-refresh-token") && localStorage.getItem("xgs-agent-refresh-token") !== "undefined") {
              originalConfig._retry = true;
              try {
                const rs = await Api.refreshToken();
                const { access_token, id_token, refresh_token } = rs.data;
                window.localStorage.setItem("xgs-agent-access-token", access_token);
                window.localStorage.setItem("xgs-agent-id-token", id_token);
                window.localStorage.setItem("xgs-agent-refresh-token", refresh_token);
                return Api.instance(originalConfig);
              } catch (_error: any) {
                if (_error.response && _error.response.data) {
                  return Promise.reject(_error.response.data);
                }
                return Promise.reject(_error);
              }
            }
            apiResponse.ok = () => false;
            apiResponse.getError = () => {
              const apiError = apiResponse.data;
              return (apiError && apiError.errors && apiError.errors[0]) ? apiError.errors[0].message : (apiError.message || "Error! Try again later or contact customer support.");
            };
            apiResponse.getFullError = () => {
              const apiError = apiResponse.data;
              return (apiError && apiError.errors)
                ? {
                  status: apiResponse.status,
                  formErrors: apiError.formErrors,
                  errors: apiError.errors[0] ? apiError.errors[0].message : null
                }
                : null;
            };
          }

          console.error(apiResponse);
          return apiResponse;
        }
      );
    }

    return Api.instance;
  };
}

export default Api.getInstance();
